import React from "react";
import classNames from "classnames";

import { Flex, Heading, Img } from "components/atoms";

const Banner = (props) => {
  const {
    className = [],
    titleColor = "green",
    title,
    caption,
    captionColor = "white",
    src,
    rounded = true,
  } = props;
  const bannerClass = classNames(
    "tw-overflow-hidden",
    { "tw-rounded-3xl": rounded },
    ...className
  );

  return (
    <Flex className={[bannerClass]}>
      <Img src={src} className={["tw-absolute", "tw-w-full", "tw-h-full"]} />
      <Flex
        className={[
          "tw-w-full",
          "tw-h-full",
          "tw-overflow-hidden",
          "tw-py-4",
          "tw-px-6",
        ]}
      >
        <Heading color={titleColor} marginBottom={2}>
          {title}
        </Heading>
        <Heading color={captionColor} marginBottom={0} size="sm">
          {caption}
        </Heading>
      </Flex>
    </Flex>
  );
};

export default Banner;

export const SET_CITIES = "SET_CITIES";
export const SET_MATCH_LOCATIONS = "SET_MATCH_LOCATIONS";

export function setCities(data) {
  return {
    type: SET_CITIES,
    payload: data,
  };
}

export function setMatchLocations(data) {
  return {
    type: SET_MATCH_LOCATIONS,
    payload: data,
  };
}

import React from "react";
import classNames from "classnames";

const RadioButton = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    onBlur,
    onChange,
    label,
    className = [],
    value = "",
    defaultChecked = false,
  } = props;

  const radioButtonClass = classNames("tw-hidden");

  const labelClass = classNames(
    "tw-relative",
    "tw-text-white",
    "tw-text-center",
    "tw-cursor-pointer",
    "tw-m-0",
    "tw-h-12 tw-w-full",
    "tw-pt-3",
    ...className
  );

  const divClass = classNames("tw-w-1/2");

  return (
    <div className={divClass}>
      <input
        ref={ref}
        type="radio"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        id={id}
        className={radioButtonClass}
        value={value}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
    </div>
  );
});

export default RadioButton;

import React from "react";
import { texts } from "../../../Text/index";
import { Span, Button, Flex, Img } from "components/atoms";

const TeamPlayerCard = (props) => {
  const { player, onClick, teamMembers } = props;
  const isInTeam = teamMembers?.includes(player?.id) || false;
  return (
    <Flex
      direction="row"
      className={[
        "tw-bg-white tw-pr-1 tw-m-1 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center",
      ]}
    >
      <Img
        src={player?.profilePictureUrl || "/img/unselected-player.png"}
        alt="leet logo"
        className={["tw-w-12 tw-h-12 tw-rounded-l-2xl"]}
      ></Img>
      <Flex className={["tw-p-1 tw-flex-1 tw-justify-center"]}>
        <Span size="xs" color="black">
          {player?.firstName} {player?.lastName}
        </Span>
        <Span size={"xs"} className={["tw-text-gray-300"]} muted={true}>
          {player?.playerType}
        </Span>
      </Flex>
      <Button
        size="xs"
        secondary={isInTeam}
        className={["tw-h-7 tw-w-16 tw-text-xxs"]}
        onClick={onClick}
        rounded="lg"
      >
        {isInTeam ? texts.remove : texts.add}
      </Button>
    </Flex>
  );
};

export default TeamPlayerCard;

import React from "react";
export default function Spinner() {
  return (
    <div className="loaderouter">
      <div className="loderinner">
        <img src={require("./img/spinner.gif")} alt="loader" />
      </div>
    </div>
  );
}

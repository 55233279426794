import React from "react";
import { texts } from "../../../Text/index";
import RadioButtons from "../../molecules/general/radioButtons";
import { playerLevels } from "containers/constants";

const PlayerLevelRadio = (props) => {
  const { onChange, currentSelected } = props;

  const playerLevelRadioButtons = [
    { id: playerLevels.beginner, name: texts.beginner, defaultChecked: currentSelected === playerLevels.beginner },
    { id: playerLevels.intermediate, name: texts.intermediate, defaultChecked: !currentSelected || currentSelected === playerLevels.intermediate },
    { id: playerLevels.advanced, name: texts.advanced, defaultChecked: currentSelected === playerLevels.advanced },
    { id: playerLevels.pro, name: texts.pro, defaultChecked: currentSelected === playerLevels.pro }
  ]

  return (
    <RadioButtons 
      className={["tw-text-s"]}
      onChange={onChange}
      name={"PlayerLevelRadio"}
      buttons={playerLevelRadioButtons} />
  );
};

export default PlayerLevelRadio;

import React from "react";
import { Flex } from "components/atoms";
import TeamColumn from "../eventTeams/teamColumn";

const TeamMembersComponent = (props) => {
  const { players, onDoubleClick } = props;

  const playersColumnOne = players?.filter((player, idx) => idx % 2 === 0);
  const playersColumnTwo = players?.filter((player, idx) => idx % 2 === 1);

  const numRows = Math.ceil(players?.length/2);

  return (
    <Flex direction="row" className={["tw-mt-4"]}>
      <Flex className={["tw-w-1/2"]}>
        {TeamColumn(playersColumnOne, numRows, 1, onDoubleClick)}
      </Flex>
      <Flex className={["tw-w-1/2 tw-pl-4"]}>
        {TeamColumn(playersColumnTwo, numRows, 1, onDoubleClick)}
      </Flex>
    </Flex>
  );
};

export default TeamMembersComponent;

import React from "react";
import classNames from "classnames";

const BasePage = (props) => {
  const { className = [], children } = props;
  const pageClass = classNames(
    "tw-flex",
    "tw-flex-col",
    "tw-w-full",
    "tw-h-full",
    "tw-px-6",
    "tw-py-2",
    "tw-overflow-auto",
    ...className
  );

  return <div className={pageClass}>{children}</div>;
};

export default BasePage;

import React from "react";
import { texts } from "../../../Text/index";
import { Heading, Button, Flex } from "components/atoms";
import Modal from "deprecated/modal";

const ConfirmComponent = (props) => {
  const { text, fnYes, fnNo, fnCancel } = props;
  return (
    <Modal open={true} fullscreen={true}>
    <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
    <Flex
      className={[
        "tw-w-full tw-h-screen tw-p-8 tw-pt-24 tw-bg-black tw-items-center md:tw-w-mmd md:tw-m-auto",
      ]}
    >
      <Heading className={["tw-items-center tw-pr-8"]}>{text}</Heading>
      <Flex
        direction="row"
        className={["tw-w-full tw-justify-between tw-mb-6"]}
      >
        <Button onClick={fnYes} className={["tw-w-45"]}>
          {texts.yes}
        </Button>
        <Button secondary onClick={fnNo} className={["tw-w-45"]} size="base">
          {texts.no}
        </Button>
      </Flex>
      <Flex className={["tw-w-full tw-justify-center"]}>
        <Button tertiary onClick={fnCancel} className={["tw-w-full tw-p-4"]}>
          {texts.cancel}
        </Button>
      </Flex>
    </Flex>
    </Flex>
    </Modal>
  );
};

export default ConfirmComponent;

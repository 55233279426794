import React from "react";
import classNames from "classnames";

const Separator = (props) => {
  const { className = [], width, isOverrideMargin, color, height } = props;
  const separatorClass = classNames(
    { "tw-w-full": !width },
    { "tw-bg-grey": !color },
    { "tw-h-0.1": !height },
    { "tw-my-6": !isOverrideMargin },
    ...className,
    width,
    color,
    height
  );

  return <hr className={separatorClass} />;
};

export default Separator;

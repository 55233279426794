import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import {
  myProfileApi,
  uploadPictureApi,
  updateProfileApi,
} from "services/profile";
import { authGameEvents, myEvents } from "services/events";
import { citiesApi } from "services/locations";
import { toast } from "react-toastify";
import { Flex } from "components/atoms";
import MyProfilePage from "components/pages/myProfile";
import Header from "containers/header/index";
import { headerVersions } from "../header/constants";
import { texts } from "../../Text/index";
import { actionButtons } from "../../components/pages/myProfile/constants";
import { USER_EVENT_DEFAULT_PAGE_SIZE } from "services/constants";
import { getTeams } from "services/teams";
import moment from "moment/moment";

const MyProfile = (props) => {
  const history = useHistory();
  const {
    citiesState,
    onLoadCities,
    fetchProfile,
    onAuthGameEvents,
    userEvents,
    myTeams,
    onGetMyTeams,
  } = props;
  const data = props?.myProfile?.profile;

  const [user, setUser] = useState(null);
  const [active, setActive] = useState(1);
  const [editProfile, setEditProfile] = useState(false);
  const [events, setEvents] = useState(null);
  const [name, setName] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [imagePreview, setImagePreview] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [playerType, setPlayerType] = useState("");
  const [playerLevel, setPlayerLevel] = useState("");
  const [error, setError] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const validatePlayerData = () => {
    if (!name) {
      setError(texts.nameIsRequired);
      return false;
    }
    return true;
  };

  const submit = () => {
    const dateOfBirth = moment
      .utc(`${selectedMonth}-${selectedDay}-${selectedYear}`, "MM-DD-YYYY HH:mm:ss")
      .local()
      .format();

    let playerData = {
      firstName: name?.trim().split(" ")[0] || user?.firstName,
      lastName: name?.trim().substring(name.indexOf(" ") + 1) || user?.lastName,
      gender: user?.gender,
      localityId: selectedLocation || user?.localityId,
      playerType: playerType || null,
      playerLevel: playerLevel || null,
      dateOfBirth: dateOfBirth || user?.dateOfBirth,
      phoneNumber: user?.phoneNumber,
    };

    if (!validatePlayerData()) {
      return;
    }

    if (file) {
      props.onUploadPicture(file).then((response) => {
        if (response.success) {
          playerData.profilePictureUrl = response?.data;
          props.onUpdateProfile(playerData);
          resetImg();
          setEditProfile(false);
          toast.success(texts.profileIsUpdated);
        } else {
          toast.error(texts.editProfileFailed);
        }
      });
    } else {
      playerData.profilePictureUrl = user?.profilePictureUrl;
      props.onUpdateProfile(playerData);
      setEditProfile(false);
      toast.success(texts.profileIsUpdated);
    }
  };

  const resetImg = () => {
    setFile(null);
  };

  useEffect(() => {
    fetchProfile();
    onGetMyTeams();
    if (!userEvents || userEvents?.length < 1) {
      onAuthGameEvents({
        pageSize: USER_EVENT_DEFAULT_PAGE_SIZE,
        pageNum: 1,
        includePastEvents: true,
      });
    }
  }, []);

  useEffect(() => {
    setCities(citiesState);
    if (!data) {
      fetchProfile();
    }
    setUser(data);
    setName(`${data?.firstName} ${data?.lastName}`);
    setPlayerType(data?.playerType);
    setPlayerLevel(data?.playerLevel?.toLowerCase());
    setSelectedLocation(data?.localityId);

    var dateTimeDOB = new Date(data?.dateOfBirth);
    setSelectedDay(dateTimeDOB.getDate());
    setSelectedMonth(dateTimeDOB.getMonth()+1);
    setSelectedYear(dateTimeDOB.getFullYear());
  }, [data, citiesState]);

  useEffect(() => {
    if (!cities || cities.length < 1) {
      onLoadCities();
    }
    if (active === actionButtons.UPCOMING) {
      props.myEvents({ isJoined: "joined" });
    }
    if (active === actionButtons.PAST) {
      props.myEvents({ isJoined: "past" });
    }
    if (active === actionButtons.ORGANISED_MATCHES) {
      props.myEvents({ isJoined: "organized" });
    }
  }, [active]);

  useEffect(() => {
    setEvents(userEvents);
  }, [userEvents]);

  const showEvent = (event) => {
    history.push({
      pathname: `/event/${event.id}`,
      state: { data: event },
    });
  };

  const displayedEvents = () => {
    if (active === actionButtons.UPCOMING) {
      return events;
    }
    if (active === actionButtons.PAST) {
      return events?.filter((event) => {
        return (
          new Date(event?.eventResponse?.dateTime).getTime() <
          new Date().getTime()
        );
      });
    }
    if (active === actionButtons.ORGANISED_MATCHES) {
      return events?.filter((event) => event?.isOrganizer === true);
    }
  };
  
  const showEventById = (id) => {
    if (id) {
      history.push(`/event/${id}`);
    }
  };

  return (
    <Flex className={[]}>
      <Header
        headerText={texts.yourProfile}
        headerVersion={headerVersions.version2}
      />
      <Flex className={["tw-h-full tw-bg-white tw-pt-5 md:tw-w-mmd md:tw-m-auto"]}>
        <MyProfilePage
          user={user}
          rating={3}
          active={active}
          setActive={setActive}
          events={displayedEvents()}
          cities={cities}
          setSelectedLocation={setSelectedLocation}
          setSelectedDay={setSelectedDay}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          showEvent={showEvent}
          setName={setName}
          setFile={setFile}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          submit={submit}
          setPlayerType={setPlayerType}
          setPlayerLevel={setPlayerLevel}
          error={error}
          showEventById={showEventById}
          myTeams={myTeams}
        />
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    myProfile: state.MyProfileReducer,
    userEvents: state.AuthEventsReducer.myEventList,
    citiesState: state.LocationsReducer.cities,
    myTeams: state.MyTeamsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: () => dispatch(myProfileApi()),
  myEvents: (data) => dispatch(myEvents(data)),
  onAuthGameEvents: (data) => dispatch(authGameEvents(data)),
  onLoadCities: () => dispatch(citiesApi()),
  onUploadPicture: (data) => dispatch(uploadPictureApi(data)),
  onUpdateProfile: (data) => dispatch(updateProfileApi(data)),
  onGetMyTeams: () => dispatch(getTeams()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);

import React from "react";
export default function MessageModel(props) {
  const { title = "Confirmed", text, toggle, onConfirm, onExit, isError } = props;
  return (
    <div
      className="modal fade message-modal m-auto show"
      aria-labelledby={"message"}
      aria-hidden="true"
      style={{ display: "block", padding: "0 1rem" }}
      onClick={() => {
        if (onExit) {
          onExit();
        }
        toggle();
      }}
    >
      <div className="modal-dialog modal-dialog-centered m-auto ">
        <div
          className={`modal-content p-0 alert `}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <h5 className="modal-title">{isError ? "Error" : title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                if (onExit) {
                  onExit();
                }
                toggle();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">{text}</div>
          <div
            className="modal-footer"
            style={{ backgroundColor: "transparent" }}
          >
            <button
              className="confirmedBtn light-blue-btn btn-lg btn-block"
              onClick={() => {
                if (onConfirm) {
                  onConfirm();
                }
                toggle();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

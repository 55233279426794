import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTeams } from "services/teams";
import { Flex } from "components/atoms";
import TeamMembersPage from "../../components/pages/teams/teamMembers";
import { teamUserStatus } from "../constants";

const TeamMembers = (props) => {
  const { myTeams, allTeams, onDoubleClick } = props;
  const { teamId } = useParams();
  const dispatch = useDispatch();

  const [team, setTeam] = useState(null);
  const [players, setPlayers] = useState(null);
  const [creatorName, setCreatorName] = useState(false);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  useEffect(() => {
    const selectedTeam = myTeams?.find((team) => team.id === teamId) || allTeams?.find((team) => team.id === teamId);
    setTeam(selectedTeam);
    const teamCreator = selectedTeam?.teamUsers?.find(
      (user) => user?.isAdmin === true
    )?.userDetails;
    setCreatorName(`${teamCreator?.firstName} ${teamCreator?.lastName}`);
    const players = selectedTeam?.teamUsers?.filter((user) =>
      user.status == teamUserStatus.joined
    ).map((user) => {
      return user?.userDetails;
    });
    setPlayers(players);
  }, [teamId, myTeams]);

  const orderbyNames = (playerA, playerB) => {
    if (playerA?.firstName < playerB?.firstName) {
      return -1;
    }
    if (playerA?.firstName > playerB?.firstName) {
      return 1;
    }
    // names must be equal
    return 0;
  };

  

  return (
    <Flex className={["tw-h-full tw-w-full md:tw-w-mmd md:tw-m-auto", "tw-bg-white"]}>
      <TeamMembersPage
        teamName={team?.name || ""}
        creatorName={creatorName}
        players={players?.sort(orderbyNames)}
        onDoubleClick={onDoubleClick}
      />
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    myTeams: state.MyTeamsReducer,
    allTeams: state.AllTeamsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);

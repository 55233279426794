import React, { useState, useEffect } from "react";
import { Flex } from "components/atoms";
import Modal from "deprecated/modal";
import GiveYourGameAName from "../../components/pages/matches/giveYourGameAname";
import WhereAndWhen from "../../components/pages/matches/whereAndWhen";
import AmountOfPlayers from "../../components/pages/matches/amountOfPlayers";
import WhichTeamAreYouPlayingWith from "../../components/pages/matches/whichTeamAreYouPlayingWith";
import InviteOtherPlayers from "../../components/pages/matches/inviteOtherPlayers";
import MatchesFinally from "../../components/pages/matches/finally";
import DoYouHaveYourOwnTeam from "../../components/pages/matches/doYouHaveYourOwnTeam";
import AddOtherTeams from "../../components/pages/matches/addOtherTeams";
import { flowSteps as createMatchSteps, matchTypeOptions} from "../constants";
import { getTeams, getAllTeams } from "services/teams";
import { connect } from "react-redux";
import { myProfileApi } from "services/profile";

const OrganiseMatchSteps = (props) => {
  const {
    createMatchStep,
    getStepString,
    cancelOrganiseMatch,
    history,
    setCreateMatchStep,
    gameName,
    setGameName,
    gameDetails,
    setGameDetails,
    validateStep,
    date,
    setDate,
    setLocation,
    setMaxAmountOfPlayers,
    addOrRemoveTeamMember,
    profileData,
    selected,
    setSelected,
    onSubmit,
    playersNotInTeam,
    searchOnChange,
    urlToCopy,
    copyToClipboard,
    locations,
    selectedLocation,
    privateMatch,
    setPrivateMatch,
    setEmail,
    allTeams,
    myTeams,
    isUpdateMatch,
    selectedAmount,
    isOrganiserPlaying,
    setIsOrganiserPlaying,
    createTeam,
    searchTeam,
    inviteTeam,
    validateMySelectedTeam,
    addTeam,
    opponentTeams,
    deleteEvent,
    isTeamevent,
    searchWord,
    setSearchWord,
    selectedMatchType,
    onGetMyTeams,
    onGetAllTeams,
    error,
    teamMembers,
    goToPreviousPage,
    goToNextPage,
    myProfile,
    fetchProfile,
  } = props;

  useEffect(() => {
    onGetMyTeams();
    onGetAllTeams();
    if (!myProfile) {
      fetchProfile();
    }
  }, []);

  const stepAfter3rd = () => {
    if (selectedMatchType === matchTypeOptions.findAPlayer){
      onSubmit();
    }
    else if (isUpdateMatch) {
      goToNextPage(createMatchSteps.step5);
    }
    else {
      goToNextPage(createMatchSteps.step4);
    }
  };

  
  return (
    <Flex
        className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
      >
    <Modal open={true}>
      <Flex className={["tw-h-full tw-w-full"]}>
        {createMatchStep === createMatchSteps.step1 && (
          <GiveYourGameAName
            stepNumber={getStepString()}
            gameName={gameName}
            setGameName={setGameName}
            setGameDetails={setGameDetails}
            gameDetails={gameDetails}
            error={error}
            fnCancel={cancelOrganiseMatch}
            next={() => validateStep()}
            isUpdateMatch={isUpdateMatch}
            deleteEvent={deleteEvent}
          ></GiveYourGameAName>
        )}
        {createMatchStep === createMatchSteps.step2 && (
          <WhereAndWhen
            stepNumber={getStepString()}
            fnCancel={cancelOrganiseMatch}
            next={() => validateStep()}
            goBack={() => goToPreviousPage(createMatchSteps.step1)}
            date={date}
            setDate={setDate}
            selectedLocation={selectedLocation}
            locations={locations}
            setLocation={setLocation}
            error={error}
            privateMatch={privateMatch}
            setPrivateMatch={setPrivateMatch}
          />
        )}
        {createMatchStep === createMatchSteps.step3 && (
          <AmountOfPlayers
            selectedMatchType={selectedMatchType}
            stepNumber={getStepString()}
            fnCancel={cancelOrganiseMatch}
            next={stepAfter3rd}
            goBack={() => goToPreviousPage(createMatchSteps.step2)}
            setMaxAmountOfPlayers={setMaxAmountOfPlayers}
            selectedAmount={selectedAmount}
            isOrganiserPlaying={isOrganiserPlaying}
            setIsOrganiserPlaying={setIsOrganiserPlaying}
          />
        )}
        {!isUpdateMatch && createMatchStep === createMatchSteps.step4 && (
          <WhichTeamAreYouPlayingWith
            selectedMatchType={selectedMatchType}
            stepNumber={getStepString()}
            fnCancel={cancelOrganiseMatch}
            next={validateMySelectedTeam}
            goBack={() => goToPreviousPage(createMatchSteps.step3)}
            teams={myTeams}
            selected={selected}
            setSelected={setSelected}
            createTeam={createTeam}
            error={error}
            userId={myProfile.id}
          />
        )}
        {createMatchStep === createMatchSteps.step5 && (
          <InviteOtherPlayers
          stepNumber={getStepString()}
          fnCancel={cancelOrganiseMatch}
          submit={onSubmit}
            goBack={() =>
              isUpdateMatch
                ? goToPreviousPage(createMatchSteps.step3)
                : goToPreviousPage(createMatchSteps.step4)
            }
            players={playersNotInTeam}
            addOrRemoveTeamMember={addOrRemoveTeamMember}
            teamMembers={teamMembers}
            searchOnChange={searchOnChange}
            invitePlayer={() => setCreateMatchStep(createMatchStep + 1)}/>
        )}
        {createMatchStep === createMatchSteps.step6 && (
          <AddOtherTeams
            stepNumber={getStepString()}
            fnCancel={cancelOrganiseMatch}
            next={() => goToNextPage(createMatchSteps.step7)}
            goBack={() => goToPreviousPage(createMatchSteps.step4)}
            teams={allTeams}
            submit={onSubmit}
            searchOnChange={searchTeam}
            inviteTeam={inviteTeam}
            addTeam={addTeam}
            opponentTeams={opponentTeams}
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            selectedTeamId ={selected}
          />
        )}
        {createMatchStep === createMatchSteps.step7 && (
          <MatchesFinally
            stepNumber={getStepString()}
            fnCancel={cancelOrganiseMatch}
            urlToCopy={urlToCopy}
            copyToClipboard={() => copyToClipboard(urlToCopy)}
          />
        )}
      </Flex>
    </Modal>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    myTeams: state.MyTeamsReducer,
    allTeams: state.AllTeamsReducer,
    myProfile: state.MyProfileReducer?.profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetMyTeams: () => dispatch(getTeams()),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
  fetchProfile: () => dispatch(myProfileApi()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganiseMatchSteps);

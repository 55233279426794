export const registrationSteps = {
  stepZero: 0,
  stepOne: 1,
  stepTwo: 2,
  stepThree: 3,
  stepFour: 4,
  stepFive: 5,
  stepSix: 6,
  stepSeven: 7,
  stepEight: 8,
};

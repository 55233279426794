import NewStoreState from "../newStoreState";
import * as Action from "./actions";
import { KEY_ID } from "../constants";

export function UserReducer(state = NewStoreState.users, action) {
  let users = { ...state }; //Create new object for updating components.
  switch (action.type) {
    case Action.SUGGESTED_USER_LIST:
      const newUsresList = [...users.usersList, ...action.payload.data];
      users.usersList = [
        ...new Map(
          newUsresList.map((user) => [user?.user[KEY_ID], user])
        ).values(),
      ];
      return users;

    case Action.REPLACE_USER_LIST:
      users.usersList = action.payload.data;
      return users;

    case Action.USER_PROFILE:
      users.selectedUser = action.payload.data;
      return users;

    case Action.STOP_LOAD_FRIEND_LIST:
      users.stopLoadFriends = true;
      return users;

    case Action.UPDATE_USER_IN_LIST:
      const userIndex = users.usersList?.findIndex(
        (user) => user.user.id === action.payload?.data?.user?.id
      );
      users.usersList[userIndex] = action.payload?.data;
      users.usersList = [...users.usersList]; // Create new object for shallow comparison.
      return users;

    default:
      return state;
  }
}

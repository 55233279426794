import React from "react";
import { Flex, Img, Paragraph } from "../../atoms";

//This header was created for every other pages.
const ClosedHeaderV3 = (props) => {
  const { goBackClick, toggleMenu, headerText } = props;

  return (
    <Flex direction="row" className={["tw-px-2 tw-py-4", "wrapper"]}>
      <Img
        src={"/img/btn_Back.png"}
        title="mainHeaderLogo"
        alt="Logo"
        className={["tw-h-4 tw-w-4 tw-m-2", "tw-cursor-pointer", "back-button"]}
        onClick={goBackClick}
      />
        {/* <Img
          src={"/img/btn_Hamburger.png"}
          title="mainHeaderLogo"
          alt="Logo"
          className={["tw-h-4 tw-m-4", "tw-cursor-pointer", "menu-button"]}
          onClick={toggleMenu}
        /> */}
      <Paragraph size="xl" color="black" className={["tw-my-1"]}>
        {headerText}
      </Paragraph>
    </Flex>
  );
};

export default ClosedHeaderV3;

import React from "react";
import { Flex, Paragraph, Button } from "components/atoms";
import GeneralPlayerCard from "components/molecules/general/playerCard";
import { texts } from "../../../Text";

const FindPlayerPage = (props) => {
  const {
    usersList,
    goToProfile,
    goToChat,
    handleScroll,
  } = props;

  return (
    <Flex className={["tw-h-96 md:tw-w-mmd md:tw-m-auto"]}>
      <Flex className={["tw-p-4"]}>
        <Paragraph color="black">{texts.palyersFound}</Paragraph>
      </Flex>
      <Flex
        className={[
          "tw-h-full tw-overflow-auto tw-bottom-4 tw-bg-white tw-rounded-xl",
        ]}
        onScroll={handleScroll}
      >
        {usersList && usersList.length > 0 ? (
          <Flex className={["tw-h-full"]}>
            {usersList.map((user) => {
              return (
                <GeneralPlayerCard
                  goToProfile={goToProfile}
                  player={user?.user}
                  goToChat={goToChat}
                ></GeneralPlayerCard>
              );
            })}
          </Flex>
        ) : (
          <Flex className={["tw-p-4 tw-mt-2 tw-text-xs tw-justify-between"]}>
            {texts.friendNotFoundV2}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default FindPlayerPage;

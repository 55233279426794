import {
  setCookie,
  getCookie,
  deleteAllCookies,
  deleteCookie,
} from "./cookies";
import { refreshToken } from "./auth/index";

export const getHeaders = (contentType = "application/json") => {
  let headers = {
    Authorization: "Bearer " + getCookie("leetUserToken"),
  };
  if (contentType) {
    headers["content-type"] = contentType;
  }
  return headers;
};

export const fetchGet = (endpoint, { data } = {}) => {
  const url = `${process.env.REACT_APP_APIURL}${endpoint}${
    (data && "?" + new URLSearchParams(data).toString()) || ""
  }`;
  return fetch(url, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      if (response.status === 401) {
        let tokenData = {
          accessToken: getCookie("leetUserToken"),
          refreshToken: getCookie("refreshToken"),
        };

        refreshToken(tokenData)
          .then((response) => {
            setCookie(
              "leetUserToken",
              response.accessToken,
              response.expiresInMinutes
            );
            setCookie("refreshToken", response.refreshToken);
            return fetchGet(endpoint, { data });
          })
          .catch(() => {
            deleteAllCookies();
            window.location.href = "/";
            return;
          });
      } else {
        return response
          .json()
          .then((data) => ({ response, data }))
          .catch(() => ({ response }));
      }
    })
    .then(({ response, data = {} }) => {
      if (!response.ok) {
        response.message = data.detail;
        throw response;
      }
      return data;
    });
};

export const fetchGet2 = (endpoint, { data } = {}) => {
  const url = `${process.env.REACT_APP_APIURL}${endpoint}${
    (data && "?" + new URLSearchParams(data).toString()) || ""
  }`;
  return fetch(url, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => {
      return response
          .json()
          .then((data) => ({ response, data }))
          .catch(() => ({ response }));
    })
    .then(({ response, data = {} }) => {
      if (!response.ok) {
        response.message = data.detail;
        throw response;
      }
      return data;
    })
    .catch(() => {
      let tokenData = {
        accessToken: getCookie("leetUserToken"),
        refreshToken: getCookie("refreshToken"),
      };

      return refreshToken(tokenData)
        .then((response) => {
          setCookie(
            "leetUserToken",
            response.accessToken,
            response.expiresInMinutes
          );
          setCookie("refreshToken", response.refreshToken);
          return fetchGet(endpoint, { data });
        })
        .catch(() => {
          deleteAllCookies();
          window.location.href = "/";
          return;
        });
    });
};

export const fetchPost = (endpoint, { data, contentType, raw = false } = {}) =>
  fetch(`${process.env.REACT_APP_APIURL}${endpoint}`, {
    method: "POST",
    headers: getHeaders(contentType),
    body: raw ? data : JSON.stringify(data),
  })
    .then((response) => {
      if (response.status === 401) {
        let tokenData = {
          accessToken: getCookie("leetUserToken"),
          refreshToken: getCookie("refreshToken"),
        };

        refreshToken(tokenData)
          .then((response) => {
            setCookie(
              "leetUserToken",
              response.accessToken,
              response.expiresInMinutes
            );
            setCookie("refreshToken", response.refreshToken);
            return fetchPost(
              endpoint,
              ({ data, contentType, raw = false } = {})
            );
          })
          .catch(() => {
            deleteAllCookies();
            window.location.href = "/";
            return;
          });
      } else {
        return response
          .json()
          .then((data) => {
            return { response, data };
          })
          .catch(() => {
            return { response };
          });
      }
    })
    .then(({ response, data = {} }) => {
      if (!response.ok) {
        response.message = data.errors?.length ? data.errors[0] : data.detail;
        response.hasVerifiedEmail = data.hasVerifiedEmail;
        throw response;
      }
      return data;
    });

export const fetchPut = (endpoint, { data, contentType, raw = false } = {}) =>
  fetch(`${process.env.REACT_APP_APIURL}${endpoint}`, {
    method: "PUT",
    headers: getHeaders(contentType),
    body: raw ? data : JSON.stringify(data),
  })
    .then((response) => {
      if (response.status === 401) {
        let tokenData = {
          accessToken: getCookie("leetUserToken"),
          refreshToken: getCookie("refreshToken"),
        };

        refreshToken(tokenData)
          .then((response) => {
            setCookie(
              "leetUserToken",
              response.accessToken,
              response.expiresInMinutes
            );
            setCookie("refreshToken", response.refreshToken);
            return fetchPut(
              endpoint,
              ({ data, contentType, raw = false } = {})
            );
          })
          .catch(() => {
            deleteAllCookies();
            window.location.href = "/";
            return;
          });
      } else {
        return response
          .json()
          .then((data) => ({ response, data }))
          .catch(() => ({ response }));
      }
    })
    .then(({ response, data = {} }) => {
      if (!response.ok) {
        response.message = data.errors?.length ? data.errors[0] : data.detail;
        throw response;
      }
      return data;
    });

export const fetchDelete = (endpoint, { data, raw = false } = {}) =>
  fetch(
    `${process.env.REACT_APP_APIURL}${endpoint}${
      (data && "?" + new URLSearchParams(data).toString()) || ""
    }`,
    {
      method: "DELETE",
      headers: getHeaders(),
      body: raw ? data : JSON.stringify(data),
    }
  )
    .then((response) => {
      if (response.status === 401) {
        let tokenData = {
          accessToken: getCookie("leetUserToken"),
          refreshToken: getCookie("refreshToken"),
        };

        refreshToken(tokenData)
          .then((response) => {
            setCookie(
              "leetUserToken",
              response.accessToken,
              response.expiresInMinutes
            );
            setCookie("refreshToken", response.refreshToken);
            return fetchDelete(endpoint, ({ data, raw = false } = {}));
          })
          .catch(() => {
            if (response.status === 400) {
              return;
            }
            deleteAllCookies();
            window.location.href = "/";
            return;
          });
      } else {
        return response
          .json()
          .then((data) => ({ response, data }))
          .catch(() => ({ response }));
      }
    })
    .then(({ response, data = {} }) => {
      if (!response.ok) {
        response.message = data.detail;
        throw response;
      }
      return data;
    });

export const fetchRefresh = (
  endpoint,
  { data, contentType, raw = false } = {}
) => {
  return fetch(`${process.env.REACT_APP_APIURL}${endpoint}`, {
    method: "POST",
    headers: getHeaders(contentType),
    body: raw ? data : JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        deleteAllCookies();
        deleteCookie("refreshToken");
        window.location.href = "/";
        return;
      }
      return response
        .json()
        .then((data) => {
          return { response, data };
        })
        .catch(() => {
          return { response };
        });
    })
    .then(({ data = {} }) => {
      return data;
    });
};

import React from "react";
import { texts } from "../../../Text/index";
import { Span, Button, Flex, Img } from "components/atoms";
import { userEventStatus } from "../../../containers/constants";

const ManageUsersCard = (props) => {
  const {
    player,
    cancelMatchClick,
    acceptRequest,
    joinMatchClick,
    userStatus,
  } = props;

  return (
    <Flex
      direction="row"
      className={[
        "tw-bg-white tw-pr-1 tw-m-1 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center",
      ]}
    >
      <Img
        src={player?.profilePictureUrl || "/img/unselected-player.png"}
        alt="leet logo"
        className={["tw-w-14 tw-h-14 tw-rounded-l-2xl"]}
      ></Img>
      <Flex className={["tw-p-1 tw-flex-1 tw-justify-center"]}>
        <Span size="xs" color="black">
          {`${player?.firstName.slice(0, 10)} ${player?.lastName.slice(0, 10)}`}
        </Span>
        {userStatus === userEventStatus.JOINED ? (
          <Span size={"xs"} className={["tw-text-gray-300"]} muted={true}>
            {player?.playerType} | +{player?.phoneNumber}
          </Span>
          ) : (
          <Span size={"xs"} className={["tw-text-gray-300"]} muted={true}>
            {player?.playerType} 
          </Span>
          )
        }
      </Flex>

      {(userStatus === userEventStatus.INVITED ||
        userStatus === userEventStatus.JOINED) && (
        <Button
          size="xs"
          secondary={true}
          onClick={() => cancelMatchClick(player?.id)}
          className={["tw-h-7 tw-w-32 tw-text-xxs"]}
          rounded="lg"
        >
          {texts.cancelInvite}
        </Button>
      )}
      {userStatus === userEventStatus.REQUESTED_TO_JOIN && (
        <Flex direction="row" className={["tw-justify-between"]}>
          <Button
            onClick={() => acceptRequest(player?.id)}
            className={["tw-mr-4 tw-h-7 tw-w-14 tw-text-xxs tw-py-0"]}
            rounded="lg"
            size="xs"
          >
            {texts.accept}
          </Button>
          <Button
            secondary
            onClick={() => cancelMatchClick(player?.id)}
            size="xs"
            className={["tw-h-7 tw-w-14 tw-text-xxs"]}
            rounded="lg"
          >
            {texts.cancel}
          </Button>
        </Flex>
      )}
      {userStatus === userEventStatus.NOT_PLAYING && (
        <Button
          rounded="lg"
          onClick={joinMatchClick}
          className={["tw-h-7 tw-w-32 tw-text-xxs tw-py-0"]}
        >
          {texts.invite}
        </Button>
      )}
    </Flex>
  );
};

export default ManageUsersCard;

import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { connect, useDispatch } from "react-redux";
import { HeaderContext } from "contexts/headerContext";
import { friendListApi } from "services/users";
import { getCookie, deleteAllCookies } from "services/cookies";
import { setLoginModalState } from "combineReducer/modal/actions";
import OpenHeaderMenu from "../../components/pages/header/openHeaderMenu";
import Login from "containers/login";
import { Flex } from "../../components/atoms";
import ClosedHeaderV3 from "../../components/pages/header/closedHeaderV3";
import ClosedHeader from "../../components/pages/header/closedHeader";
import { headerVersions, headerGameTypes } from "./constants";
import { getAllTeams, getTeams } from "services/teams";
import { texts } from "../../Text/index";

function HeaderComponent(props) {
  const {
    tokenReducer,
    setLoginModalOpen,
    profile,
    UnauthGamesEvent,
    headerVersion,
    placeholder,
    headerText,
    onSearch,
    onTypeChange
  } = props;

  const headerContext = useContext(HeaderContext);
  const history = useHistory();
  const location = useLocation();
  const { userChat } = props;
  const { unreadMessages } = userChat;
  const amountOfGames = UnauthGamesEvent?.data?.length;
  const firstName = profile?.data?.firstName;
  const profilePictureUrl = profile?.profile?.profilePictureUrl;

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [playerType, setPlayerType] = React.useState("");

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(friendListApi());
    }
    return () => {
      headerContext.setHeaderPlayerType("");
      headerContext.setHeaderSearchTerm("");
    };
  }, []);

  React.useEffect(() => {
    //We can check if we are logged in with checking if we either have the token from FbAuth
    //or if we have leetUserToken cookie from email auth.
    const cookie = getCookie("leetUserToken") || tokenReducer?.token;
    if (!cookie) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
  }, [toggle]);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  const navigate = (e, route) => {
    if (isLoggedIn) {
      e.preventDefault();
      const location = window.location.pathname;
      if (route === location) {
        history.go(route);
      } else {
        history.push(route);
      }
    } else {
      setLoginModalOpen(true);
    }
  };

  const dispatch = useDispatch();

  // searchPlayers and searchPlayerType are a combined search for players. Used in headerV4.
  const searchPlayers = (value) => {
    dispatch(friendListApi({ name: value, type: playerType }, true)).then(() =>
      setIsLoader(false)
    );

    setSearchTerm(value);
    headerContext.setHeaderSearchTerm(value);
  };

  const searchPlayerType = (type) => {
    dispatch(
      friendListApi({ ...(searchTerm && { name: searchTerm }), type }, true)
    ).then(() => setIsLoader(false));
    setPlayerType(type);
    headerContext.setHeaderPlayerType(type);
  };

  const fnLogInOut = () => {
    if (isLoggedIn) {
      deleteAllCookies();
      const location = window.location.pathname;
      if (location === "/") {
        history.go(location);
      } else {
        history.push("/");
      }
    } else {
      setLoginModalOpen(true);
    }
  };

  const goBackClick = () => {
    if (location.pathname.includes("/post-match/")) {
      history.go(-2);
    } else if (!location.key || location.key === "default") {
      history.push("/");
    } else {
      history.goBack();
    }
  };

  const selectOpenOrTeam = (event) => {
    if (event === headerGameTypes.TEAMGAME) {
      headerContext.setHeaderGameType(headerGameTypes.TEAMGAME);
      return;
    }
    headerContext.setHeaderGameType(headerGameTypes.OPENGAME);
  };

  const playersRadioButtons = [
    { id: "goalkeeper", name: texts.goaler, defaultChecked: false },
    { id: "player", name: texts.futballer, defaultChecked: true },
    { id: "both", name: texts.playerAndGoaler, defaultChecked: false }
  ]

  const teamsRadioButtons = [
    { id: "myteams", name: texts.myTeams, defaultChecked: true },
    { id: "allteams", name: texts.joinATeam, defaultChecked: false }
  ]

  const matchTypeRadioButtons = [
    { id: "openGame", name: texts.openMatch, defaultChecked: true },
    { id: "teamGame", name: texts.teamMatch, defaultChecked: false },
    { id: "myGames", name: texts.myGames, defaultChecked: false }
  ]

  const renderHeader = () => {
    let CurrentHeader;
    switch (headerVersion) {
      case headerVersions.version1:
        CurrentHeader = (
          <ClosedHeader
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            goToHome={() => history.push("/")}
            unreadMessages={unreadMessages}
            navigate={navigate}
            radioButtonsName={"OpenTeamSelector"}
            radioButtons={matchTypeRadioButtons}
            onRadioButtonChange={onTypeChange}
          />
        );
        break;
      case headerVersions.version2:
        CurrentHeader = (
          <ClosedHeader
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            goToHome={() => history.push("/")}
            unreadMessages={unreadMessages}
            navigate={navigate}
          />
        );
        break;
      case headerVersions.version3:
        CurrentHeader = (
          <ClosedHeaderV3
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            headerText={headerText}
            goBackClick={goBackClick}
          />
        );
        break;
      case headerVersions.version4:
        CurrentHeader = (
          <ClosedHeader
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            goToHome={() => history.push("/")}
            navigate={navigate}
            unreadMessages={unreadMessages}
            onSearchChange={searchPlayers}
            placeholder={placeholder}
            radioButtonsName={"PlayerTypeRadio"}
            radioButtons={playersRadioButtons}
            onRadioButtonChange={searchPlayerType}
          />
        );
        break;
      case headerVersions.version5:
        CurrentHeader = (
          <ClosedHeader
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            goToHome={() => history.push("/")}
            navigate={navigate}
            unreadMessages={unreadMessages}
            onSearchChange={onSearch}
            placeholder={placeholder}
            radioButtonsName={"TeamsRadio"}
            radioButtons={teamsRadioButtons}
            onRadioButtonChange={onTypeChange}
          />
        );
        break;
      default:
      // code block
    }
    return CurrentHeader;
  };

  return (
    <Flex>
      {renderHeader() || <Flex />}
      <OpenHeaderMenu
        toggle={toggle}
        toggleMenu={toggleMenu}
        isLoggedIn={isLoggedIn}
        navigate={navigate}
        goToHome={() => history.push("/")}
        fnLogInOut={fnLogInOut}
        fnSignupSettings={(e) => navigate(e, "/settings")}
        {...props}
      />
      <Login />
    </Flex>
  );
}
const mapStateToProps = (state) => ({
  UnauthGamesEvent: state.UnauthGamesEvent,
  tokenReducer: state.TokenReducer,
  userChat: state.ChatReducer,
  profile: state.MyProfileReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setLoginModalOpen: (data) => dispatch(setLoginModalState(data)),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
  onGetMyTeams: (data) => dispatch(getTeams(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);

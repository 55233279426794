import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

const Form = (props) => {
  const { children, onSubmit = () => {}, className = [] } = props;

  const formClass = classNames("tw-flex", "tw-flex-col", "tw-flex-shrink-0", ...className);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data))}
      className={formClass}
    >
      {children({ register, control, watch, setValue, errors })}
    </form>
  );
};

export default Form;

var NewStoreState = {
  users: {
    selectedUser: {},
    usersList: [],
    stopLoadFriends: false,
  },
  myProfile: {
    profile: {},
    profileImage: "",
  },
  teams: {
    myTeams: [],
    allTeams: [],
  },
  friends: {
    friend: {},
  },
  authEvents: {
    event: {},
    myEventList: [],
    eventList: [],
    stopLoadEvents: false,
    feedbacks: [],
  },
  unAuthEvents: {
    eventList: [],
    stopLoadEvents: false,
  },
  token: {},
  locations: {
    matchLocations: [],
    cities: [],
  },
  modal: {
    isLoginModalOpened: false,
    isSignUpModalOpened: false,
    isloginModalErrorOpened: false,
  },
  chat: {
    token: null,
    unreadMessages: null,
  },
};
export default NewStoreState;

import React from "react";
import Header from "containers/header/index";
import { headerVersions } from "../header/constants";
import { texts } from "../../Text/index";
import { faqTexts } from "../../Text/faq";
import { Flex, Heading } from "components/atoms";
import FaqComponent from "components/molecules/faq";

const Faq = () => {
  const textArray = Object.values(faqTexts);

  return (
    <Flex className={["tw-h-full tw-p-4 tw-overflow-auto tw-bg-white md:tw-w-mmd md:tw-m-auto"]}>
      <Header
        headerText={texts.faqHeader}
        headerVersion={headerVersions.version3}
      />
      <Flex>
        <Heading color="black" className={["tw-pl-4"]}>
          {texts.faqQuickStart}
        </Heading>
        {textArray.map((text, idx) => {
          //Since the faqTexts object contains the values in the order of: One title, one description
          //this is the best and simplest approach to render all of them correctly.
          if (idx % 2 === 0) {
            return (
              <FaqComponent
                key={idx} //Only for react key constraint.
                tile={text}
                description={textArray[idx + 1]}
              ></FaqComponent>
            );
          }
        })}
      </Flex>
    </Flex>
  );
};

export default Faq;

import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar } from "../../molecules";
import TeamMemberCard from "../../molecules/teams/teamMemberCard";
import SteppButtons from "../../molecules/general/steppButtons";

const PlayersInMyTeam = (props) => {
  const {
    players, // List of user objects to didplay user props
    teamMembers,
    addOrAcceptTeamMember,
    removeTeamMember,
    submit,
    backToTeamPage,
    deleteTeam,
    fnBack,
    goToAddAPlayer,
    stepNumber,
    displayDeleteButton = true,
  } = props;

  return (
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black md:tw-w-mmd md:tw-m-auto"]}>
          <BlackModalTopBar onClick={backToTeamPage} stepNumber={stepNumber} />
          <Flex className={["tw-px-12 tw-py-8"]}>
            <Heading className={[" tw-items-center"]}>
              {texts.playersInMyTeam}
            </Heading>
            {players && players.length > 0 ? (
              <Flex className={["tw-h-48 tw-overflow-auto"]}>
                {players.map((player) => {
                  return (
                    <TeamMemberCard
                      onAddOrAccept={() => addOrAcceptTeamMember(player)}
                      onRemove={() => removeTeamMember(player)}
                      player={player}
                      teamMembers={teamMembers}
                    ></TeamMemberCard>
                  );
                })}
              </Flex>
            ) : (
                <Flex className={["tw-bg-black tw-rounded-2xl"]}>
                  <Paragraph
                    size="xxs"
                    muted={true}
                    className={["tw-text-gray tw-pr-2"]}
                  >
                    {texts.noPlayerInYourTeam}
                  </Paragraph>
                </Flex>
              )}

            <Button
              quinternary={true}
              className={["tw-my-8 tw-text-sm tw-border-2 tw-border-white"]}
              onClick={goToAddAPlayer}
            >
              {texts.addAPlayer}
            </Button>
            {displayDeleteButton && (
              <Button
                quinternary={true}
                className={["tw-mb-2 tw-text-sm tw-colors-red"]}
                size="sm"
                textColor={"red"}
                onClick={deleteTeam}
              >
                {texts.deleteTeam}
              </Button>
            )}
            <SteppButtons
              fwdText={texts.saveTeam}
              fnBack={fnBack}
              fnForward={submit}
            ></SteppButtons>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default PlayersInMyTeam;

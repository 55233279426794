import * as Actions from "combineReducer/myProfile/actions";
import { fetchGet, fetchGet2, fetchPost, fetchPut } from "services";
import { toUploadProfileImage } from "./adapter";

export const myProfileApi = () => (dispatch) =>
  fetchGet2("users/me")
    .then((response) => {
      dispatch(
        Actions.myProfile({
          success: true,
          data: response,
          message: "Successfully get user profile",
        })
      );
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
      } else {
        dispatch(
          Actions.myProfile({
            success: false,
            data: "",
            message: error.message || "Error in signup API",
          })
        );
      }
    });

  //   export const userProfileApi = (id) => (dispatch) =>
  // fetchGet(`users/${id}`)
  //   .then((response) => {
  //     dispatch(
  //       Actions.myProfile({
  //         success: true,
  //         data: response,
  //         message: "Successfully get user profile",
  //       })
  //     );
  //     return response;
  //   })
  //   .catch((error) => {
  //     if (error.status === 401) {
  //     } else {
  //       dispatch(
  //         Actions.myProfile({
  //           success: false,
  //           data: "",
  //           message: error.message || "Error in signup API",
  //         })
  //       );
  //     }
  //   });

export const updateProfileApi = (data) => (dispatch) =>
  fetchPut("users/me", { data })
    .then((response) => {
      response = {
        success: true,
        data: response,
      };

      dispatch(Actions.myProfile(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
      } else {
        dispatch(
          Actions.myProfile({
            success: false,
            data: "",
            message: error.message || "Error in getting my profile APi",
          })
        );
      }
    });

export const updateSettingsApi = (data) => (dispatch) =>
  fetchPut("users/settings", { data })
    .then((response) => {
      response = {
        success: true,
        data: response,
        message: "You have successfully updated the profile!",
      };

      dispatch(Actions.settings(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
      }
    });

export const uploadPictureApi = (file) => (dispatch) =>
  fetchPost("users/picture", {
    data: toUploadProfileImage(file),
    contentType: null,
    raw: true,
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: response.imageUrl,
        message: "",
      };

      dispatch(Actions.uploadProfileImage(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return {
          success: false,
          data: "unauth",
          message: "Unauthorized User",
        };
      } else {
        dispatch(
          Actions.uploadProfileImage({
            success: false,
            data: "",
            message: error.message || "Error in getting events APi",
          })
        );
        return {
          success: false,
          data: "",
          message: error.message || "Error in getting events APi",
        };
      }
    });

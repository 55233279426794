import React from "react";
import classNames from "classnames";
import moment from "moment";

import { Flex, Span } from "components/atoms";

const Calendar = (props) => {
  const { className = [], date } = props;
  const calendarClass = classNames(
    "tw-bg-grey-light",
    "tw-w-full",
    "tw-rounded-2xl",
    "tw-py-4",
    "tw-justify-evenly",
    ...className
  );

  const getWeekDays = () => {
    if (!date) return null;
    let weekStart = date.clone().startOf("week");
    let days = [];
    for (let i = 0; i <= 6; i++) {
      let d = moment(weekStart).add(i, "days");
      days.push({
        label: d.format("dd"),
        value: d.format("D"),
        active: d.format("d") == date.format("d"),
      });
    }
    return days;
  };

  return (
    <Flex direction="row" className={[calendarClass]}>
      {getWeekDays()?.map(({ label, value, active }) => (
        <Flex
          className={[
            { "tw-bg-green": active },
            "tw-px-2",
            "tw-py-4",
            "tw-rounded-xl",
            "tw-text-center",
          ]}
        >
          <Span color={active ? "white" : "black"} className={["tw-mb-2"]}>
            {" "}
            {label}
          </Span>
          <Span color={active ? "white" : "black"}>{value}</Span>
        </Flex>
      ))}
    </Flex>
  );
};

export default Calendar;

import React from "react";
import classNames from "classnames";
import { twRounded } from "../rounded";
import { textColors } from "../textColors";

const colorScheme = {
  white: ["tw-from-red-white tw-to-white"],
  black: ["tw-from-red-black tw-to-black"],
  red: ["tw-from-red-dark tw-to-red"],
  green: ["tw-from-green-dark tw-to-green"],
  darkBlue: ["tw-from-darkBlue-dark tw-to-darkBlue"],
  blue: ["tw-from-blue-dark tw-to-blue"],
  buttonWhite: ["tw-from-buttonWhite-dark tw-to-buttonWhite"],
  grey: ["tw-from-grey-dark tw-to-grey"],
  mediumGrey: ["tw-text-grey-medium"],
  shuffleGreen: ["tw-from-shuffleGreen-dark tw-to-shuffleGreen"],
  orange: ["tw-from-orange tw-to-orange-dark"],
  blood: ["tw-from-red-blood tw-to-red-blood"],
};

const Button = (props) => {
  const {
    type = "button",
    className = [],
    onClick = () => {},
    children,
    size = "md",
    disabled = false,
    textColor = "white",
    rounded = "2xl",
    secondary = false,
    tertiary = false,
    quaternary = false,
    quinternary = false,
    hexternary = false,
    inactive = false,
    septenary = false,
    octernary = false,
    ninetenary = false,
    blood = false,
  } = props;

  const styleSizes = {
    md: ["tw-py-4", "tw-px-2"],
    imd: ["tw-py-2", "tw-px-5"],
    sm: ["tw-py-1", "tw-px-5"],
    xsm: ["tw-py-1", "tw-px-3"],
    xs: ["tw-py-1", "tw-px-2"],
    capital: ["tw-py-2", "tw-px-1"],
    base: ["tw-py-3"], //These buttons have fix 45% width. No px needed.
  };
  const color =
    (inactive && "white") ||
    (secondary && "red") ||
    (tertiary && "blue") ||
    (quaternary && "darkBlue") ||
    (quinternary && "black") ||
    (hexternary && "buttonWhite") ||
    (septenary && "grey") ||
    (octernary && "shuffleGreen") ||
    (ninetenary && "orange") ||
    (blood && "blood") ||
    "green";

  const btnClass = classNames(
    "tw-inline-block",
    "tw-flex-shrink-0",
    "tw-text-center",
    "tw-align-middle",
    "tw-select-none",
    `${twRounded[rounded]}`,
    `${textColors[textColor]}`,
    "tw-bg-gradient-to-t",
    { [colorScheme[color]]: !disabled },
    { "tw-bg-grey": disabled },
    ...styleSizes[size],
    ...className
  );
  return (
    <button
      type={type}
      className={btnClass}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </button>
  );
};

export default Button;

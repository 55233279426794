import React from "react";
import classNames from "classnames";
import { textColors } from "../textColors";

const Heading = (props) => {
  const {
    className = [],
    children,
    color = "white",
    size = false,
    marginBottom = 6,
  } = props;
  const headingClass = classNames(
    `${textColors[color]}`,
    { "tw-text-3xl": !size },
    `tw-mb-${marginBottom}`,
    ...className
  );

  return <h1 className={headingClass}>{children}</h1>;
};

export default Heading;

import React from "react";
import { Flex } from "components/atoms";
import { Paragraph } from "../../atoms";
import { texts } from "../../../Text";

const FeedbackComponent = (props) => {
  const { text, feedbackUser } = props;

  return (
    <Flex
      className={[
        "tw-p-2 tw-mb-4 tw-w-full tw-bg-grey-light tw-rounded-3xl tw-border tw-border-grey-button",
      ]}
    >
      <Paragraph size="sm" color="gray" className={["tw-p-2"]}>
        {'"'}
        {text} {'"'}
      </Paragraph>
      <Flex
        className={["tw-w-full tw-p-4 tw-text-right tw-text-gray tw-text-sm"]}
      >
        {feedbackUser}
      </Flex>
    </Flex>
  );
};

export default FeedbackComponent;

import React from "react";
import {
  Paragraph,
  Input,
  Heading,
  Flex,
  Button,
} from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, ErrorInfo } from "../../molecules";

const MatchesFinally = (props) => {
  const { fnCancel, copyToClipboard, error, stepNumber, urlToCopy } = props;
  return (
    <Flex
      className={[
        "tw-h-full tw-bg-black tw-z-50 tw-overflow-auto",
      ]}
    >
      <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
      <Flex className={["tw-px-8 xsm:tw-mt-10 md:tw-w-mmd md:tw-m-auto"]}>
        <Heading className={[" tw-items-center"]}>{texts.finally}</Heading>
        <Paragraph className={["tw-py-4"]} muted={false} size="xs">
          {texts.copyLinkText}
        </Paragraph>
        <Input
          placeholder="Link"
          className={["tw-my-2"]}
          rounded="lg"
          size="xxs"
          value={urlToCopy ? urlToCopy :texts.leetHome}
        />
        {error && <ErrorInfo error={error} />}
       
        <Button onClick={copyToClipboard} className={["tw-w-full tw-mt-2"]} tertiary>
          {texts.copyLinkButton}
        </Button>
      </Flex>
    </Flex>
  );
};

export default MatchesFinally;

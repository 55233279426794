export const textColors = {
  black: "tw-text-black",
  white: "tw-text-white",
  darkBlueDark: "tw-text-darkBlue-dark",
  green: "tw-text-green",
  gray: "tw-text-grey-dark",
  red: "tw-text-red",
  blood: "tw-text-red-blood",
  yellow: "tw-text-yellow",
};

import * as Action from "./actions";
import NewStoreState from "../newStoreState";

export function ModalReducer(state = NewStoreState.modal, action) {
  switch (action.type) {
    case Action.SET_LOGIN_MODAL_STATE:
      return { ...state, isLoginModalOpened: action.payload };
    case Action.SET_SIGNUP_MODAL_STATE:
      return { ...state, isSignUpModalOpened: action.payload };
    case Action.SET_LOGIN_MODAL_ERROR_STATE:
      return { ...state, isLoginModalErrorOpened: action.payload };
    default:
      return state;
  }
}

export const MATCH_STATUS = {
  JOINED: "Joined",
  REQUESTED: "Requested",
  PAST: "Past",
};

export const API_CHAT_KEY = process.env.REACT_APP_CHAT_STREAM_KEY;

export const PAST_MATCH_MINUTES_AFTER_START = 30;

export const MOBILE_OS = {
  IOS_SAFARI: "IOS_SAFARI",
  ANDROID: "ANDROID",
  OTHER: "OTHER",
};

import React from "react";
import { texts } from "../../../Text/index";
import { Span, Button, Flex, Img } from "components/atoms";
import { teamUserStatus } from "../../../containers/constants";

const TeamMemberCard = (props) => {
  const { player, teamMembers, onAddOrAccept, onRemove } = props;
  const existingTeamUser = teamMembers.filter(user => user.userDetails?.id == player?.id)[0];
  return (
    <Flex
      direction="row"
      className={[
        "tw-bg-white tw-pr-1 tw-m-1 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center",
      ]}
    >
      <Img
        src={player?.profilePictureUrl || "/img/unselected-player.png"}
        alt="leet logo"
        className={["tw-w-12 tw-h-12 tw-rounded-l-2xl"]}
      ></Img>
      <Flex className={["tw-p-1 tw-flex-1 tw-justify-center"]}>
        <Span size="xs" color="black">
          {player?.firstName} {player?.lastName}
        </Span>
        <Span size={"xs"} className={["tw-text-gray-300"]} muted={true}>
          {player?.playerType}
        </Span>
      </Flex>
      {!existingTeamUser && (
        <Button
          size="xs"
          secondary={false}
          className={["tw-h-7 tw-w-16 tw-text-xxs"]}
          onClick={onAddOrAccept}
          rounded="lg"
        >
          {texts.add}
        </Button>
      )}
      {existingTeamUser && existingTeamUser.status == teamUserStatus.requestedToJoin && (
        <Flex direction="row" className={["tw-justify-between"]}>
          <Button
            onClick={onAddOrAccept}
            className={["tw-mr-2 tw-h-7 tw-w-14 tw-text-xxs tw-py-0"]}
            rounded="lg"
            size="xs"
          >
            {texts.accept}
          </Button>
          <Button
            secondary
            onClick={onRemove}
            size="xs"
            className={["tw-h-7 tw-w-14 tw-text-xxs"]}
            rounded="lg"
          >
            {texts.cancel}
          </Button>
        </Flex>
      )}
      {existingTeamUser && existingTeamUser.status == teamUserStatus.joined && (
        <Button
          size="xs"
          secondary={true}
          className={["tw-h-7 tw-w-16 tw-text-xxs"]}
          onClick={onRemove}
          rounded="lg"
        >
          {texts.remove}
        </Button>
      )}
    </Flex>
  );
};

export default TeamMemberCard;

import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const coreLink = (props) => {
  const { children, className = [], to, external = false } = props;
  const linkClass = classNames(...className);

  if (external) {
    return (
      <a className={linkClass} href={to}>
        {children}
      </a>
    );
  } else {
    return (
      <Link className={linkClass} to={to}>
        {children}
      </Link>
    );
  }
};

export default coreLink;

export const GET_EVENTS = "GET_EVENTS";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_MY_EVENTS = "GET_MY_EVENTS";
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const EVENT_DETAIL = "EVENT_DETAIL";
export const STOP_LOAD_EVENTS = "STOP_LOAD_EVENTS";
export const DELETE_EVENT = "DELETE_EVENT";

export function getEvents(data) {
  return {
    type: GET_EVENTS,
    payload: data,
  };
}

export function getAllEvents(data) {
  return {
    type: GET_ALL_EVENTS,
    payload: data,
  };
}

export function getMyEvents(data) {
  return {
    type: GET_MY_EVENTS,
    payload: data,
  };
}

export function addFeedback(data) {
  return {
    type: ADD_FEEDBACK,
    payload: data,
  };
}

export function eventDetail(data) {
  return {
    type: EVENT_DETAIL,
    payload: data,
  };
}

export function stopLoadEvents(data) {
  return {
    type: STOP_LOAD_EVENTS,
    payload: data,
  };
}

export function deleteEvent(data) {
  return {
    type: DELETE_EVENT,
    payload: data,
  };
}

import React from "react";
import classNames from "classnames";

const ErrorInfo = (props) => {
  const { size = "base", className = [], error } = props;
  const errorClass = classNames(
    "tw-text-white",
    `tw-text-${size}`,
    "tw-bg-error",
    "tw-p-2",
    "tw-my-2",
    "tw-leading-tight",
    "tw-rounded-2xl",
    ...className
  );

  return <p className={errorClass}>{error}</p>;
};

export default ErrorInfo;

import * as Action from "./actions";
import NewStoreState from "../../newStoreState";
import { KEY_ID } from "../../constants";

export function AuthEventsReducer(state = NewStoreState.authEvents, action) {
  let events = { ...state };
  switch (action.type) {
    case Action.GET_EVENTS:
      events.eventList = [...action.payload.data];
      return events;

    case Action.GET_ALL_EVENTS:
      const newEvents = [...events.eventList, ...action.payload.data];
      // Make sure that list contains only unique events.
      events.eventList = [
        ...new Map(
          newEvents.map((event) => [event?.eventResponse[KEY_ID], event])
        ).values(),
      ];
      return events;

    case Action.GET_MY_EVENTS:
      const newMyEvents = [...events.myEventList, ...action.payload.data];
      // Make sure that list contains only unique events.
      events.myEventList = [
        ...new Map(
          newMyEvents.map((event) => [event?.eventResponse[KEY_ID], event])
        ).values(),
      ];
      return events;

    case Action.ADD_FEEDBACK:
      events.feedbacks = action.payload.data;
      return events;

    case Action.EVENT_DETAIL:
      if (!action.payload.data?.eventResponse) {
        return;
      }
      events.event = action.payload.data;
      let contains = false;
      events.eventList = events?.eventList?.map((event) => {
        if (
          event.eventResponse?.id === action.payload.data?.eventResponse?.id
        ) {
          contains = true;
          return action.payload.data;
        } else {
          return event;
        }
      });
      if (!contains) {
        events.eventList = [...events.eventList, action.payload.data];
      }
      return events;

    case Action.STOP_LOAD_EVENTS:
      events.stopLoadEvents = action.payload.data;
      return events;

    case Action.DELETE_EVENT:
      events.eventList = events.eventList?.filter(
        (event) => event.eventResponse?.id !== action.payload.data
      );
      return events;

    default:
      return state;
  }
}

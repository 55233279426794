import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { resetPasswordForm } from "services/auth";

import SubHeader from "deprecated/sub-header";
import Modal from "deprecated/modal";
import MessageModal from "deprecated/modal/messageModal";

const ResetPassword = (props) => {
  const { onResetPassword, history } = props;

  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  var url = new URL(window.location.href);
  var userId = url.searchParams.get("uid");
  var code = url.searchParams.get("code");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    if (!userId && !code) {
      setMessage("User/Token not found");
      setMessageModalOpen(true);
    } else {
      let submitData = {
        userId: userId,
        code: code,
        password: data.password,
        confirmPassword: data.confirmPassword,
      };
      onResetPassword(submitData).then(({ message }) => {
        setMessage(message);
        setMessageModalOpen(true);
      });
    }
  };

  return (
    <>
      <div className="create-account-wrapper pb-200">
        <SubHeader title="RESET PASSWORD" selectAreaCount="0" {...props} />
        <div className="container py-5">
          <div className="row bg-white">
            <div className="col-12 overflow-hidden bg-white px-3 pb-0">
              <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="row mt-3 mb-3">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is Required",
                        })}
                        className="form-control"
                        type="password"
                      />
                    </div>
                  </div>
                  {errors.password?.message && (
                    <div className="col-12 mt-2">
                      <div className="alert alert-danger" role="alert">
                        {errors.password?.message}
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        placeholder="Confirm Password"
                        {...register("confirmPassword", {
                          required: "Confirm Password is Required",
                          validate: (value) =>
                            value === watch("password", "") ||
                            "The passwords do not match",
                        })}
                        className="form-control"
                        type="password"
                      />
                    </div>
                  </div>
                  {errors.confirmPassword?.message && (
                    <div className="col-12 mt-2">
                      <div className="alert alert-danger" role="alert">
                        {errors.confirmPassword?.message}
                      </div>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn organiseGame create-account-btn ligt-yellow-btn btn-lg btn-block"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal open={messageModalOpen} setOpen={setMessageModalOpen}>
        <MessageModal
          text={message}
          onExit={() => history.push("/")}
          onConfirm={() => history.push("/")}
        />
      </Modal>
    </>
  );
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onResetPassword: (data) => dispatch(resetPasswordForm(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import React from "react";
import moment from "moment";
import { downloadEventICSfile } from "./downloadIcs";
import { Heading, Flex, Button } from "components/atoms";
import Modal from "deprecated/modal";
import { texts } from "../../../../Text/index";
import { flowSteps as createTeamSteps } from "../../../../containers/constants";
import { BlackModalTopBar } from "../../../molecules";

const googleBasedUrl = "https://calendar.google.com/calendar/r/eventedit?";
const outlookBasedUrl =
  "https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&";

const EventCalendar = (props) => {
  const { eventName, startDate, location, details, backToMatchPage } = props;

  const startDT = moment.utc(startDate);
  const endDT = moment.utc(startDate).add(1, "hours");

  const googleCalendarProps = new URLSearchParams({
    text: eventName,
    dates: `${startDT.format(`YYYYMMDD[T]HHmmss[Z]`)}/${endDT.format(
      `YYYYMMDD[T]HHmmss[Z]`
    )}`,
    details,
    location,
  });

  const outlookCalendarProps = new URLSearchParams({
    body: eventName,
    subject: eventName,
    startdt: startDT.format(`YYYY-MM-DD[T]HH:mm:ssZ`),
    enddt: endDT.format(`YYYY-MM-DD[T]HH:mm:ssZ`),
    location,
  });

  const AppleEvent = {
    eventName: eventName,
    description: details,
    location: location,
    startDate: startDT.format(`YYYY-MM-DD[T]HH:mm:ssZ`),
    endDate: endDT.format(`YYYY-MM-DD[T]HH:mm:ssZ`),
  };

  return (
    <Flex
      className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
    >
    <Modal open={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50"]}>
        <BlackModalTopBar
          onClick={backToMatchPage}
          stepNumber={createTeamSteps.step1 + "/" + createTeamSteps.step2}
        />
        <Flex className={["tw-px-12 tw-py-16"]}>
          <Heading className={[" tw-items-center"]}>
            {texts.selectCalendar}
          </Heading>
          <a href={downloadEventICSfile(AppleEvent)} download="leetevent.ics">
            <Button className={["tw-w-full tw-my-2"]}>
              {texts.appleCalendar}
            </Button>
          </a>
          <a
            href={`${googleBasedUrl}${googleCalendarProps.toString()}`}
            target="_blank"
          >
            <Button className={["tw-w-full tw-my-2"]}>
              {texts.googleCalendar}
            </Button>
          </a>
          <a href={`${outlookBasedUrl}${outlookCalendarProps}`} target="_blank">
            <Button className={["tw-w-full tw-my-2"]}>{texts.outlook}</Button>
          </a>
        </Flex>
      </Flex>
    </Modal>
  </Flex>
  );
};

export default EventCalendar;

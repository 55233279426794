import React from "react";
import ReactDOM from "react-dom";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      root: null,
      el: null,
      isFirstModal: null,
    };

    this.initModal = this.initModal.bind(this);
    this.removeModal = this.removeModal.bind(this);
  }

  initModal() {
    const { root } = this.state;
    let el = document.createElement("div") || {};
    el.className = "modal-root";
    root.appendChild(el);

    let isChildModal = root?.classList.contains("modal-open");
    this.setState({ isFirstModal: !isChildModal, el });

    if (!isChildModal) {
      root.classList.add("modal-open");
    }
  }

  removeModal() {
    let { root, el, isFirstModal } = this.state;

    if (root && el) {
      try {
        root.removeChild(el);
      } catch (e) {}
    }
    if (isFirstModal && root?.classList.contains("modal-open")) {
      root.classList.remove("modal-open");
    }

    this.setState({ el: null });
  }

  componentDidMount() {
    const { open } = this.props;

    let root = document.body;
    let isFirstModal = !root?.classList.contains("modal-open");
    this.setState({ isFirstModal, root }, () => {
      if (open) {
        this.initModal();
      }
    });
  }

  componentWillUnmount() {
    this.removeModal();
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    const { root, isFirstModal } = this.state;

    if (prevProps.open === true && open === false && isFirstModal) {
      this.removeModal();
    }
    if (prevProps.open === false && open === true) {
      this.initModal();
    }
  }

  render() {
    const { el, root } = this.state;
    const { open, setOpen, children, fullscreen } = this.props;

    if (!el || !root || !open) return null;

    return ReactDOM.createPortal(
      <div
        className={`modal-wrapper ${
          !fullscreen && "md:tw-w-mmd"
        } md:tw-m-auto md:tw-left-0 md:tw-right-0`}
      >
        {React.cloneElement(children, {
          open: open,
          toggle: () => setOpen(!open),
        })}
      </div>,
      el
    );
  }
}

export default Modal;

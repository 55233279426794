import React from "react";
import { BasePage, Flex } from "components/atoms";
import { Banner, TeamMembersComponent } from "components/molecules";
import HeaderComponent from "../../../containers/header";
import { headerVersions } from "../../../containers/header/constants";
import { texts } from "Text";

const TeamMembersPage = (props) => {
  const { players, teamName, creatorName, onDoubleClick } = props;
  return (
    <BasePage>
      <HeaderComponent
        headerText={texts.teamMembers}
        headerVersion={headerVersions.version3}
      />
      <Flex className={["tw-rounded-3xl", "tw-bg-blue"]}>
        <Banner
          title={teamName}
          caption={`${creatorName}`}
          src={"/img/match-details-banner.png"}
        />
      </Flex>

      <TeamMembersComponent players={players} onDoubleClick={onDoubleClick} />
    </BasePage>
  );
};

export default TeamMembersPage;

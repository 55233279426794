export const downloadEventICSfile = (AppleEvent) => {
  const convertDate = (date) => {
    var eventDate = new Date(date).toISOString();
    return eventDate;
  };

  const makeIcsFile = (AppleEvent) => {
    const { eventName, startDate, endDate, description, location } = AppleEvent;
    var calendarData =
      "BEGIN:VCALENDAR\n" +
      "CALSCALE:GREGORIAN\n" +
      "METHOD:PUBLISH\n" +
      "PRODID:-//Leet-Event//EN\n" +
      "VERSION:2.0\n" +
      "BEGIN:VEVENT\n" +
      "UID:Leet-1\n" +
      "DTSTART;VALUE=DATE:" +
      convertDate(startDate) +
      "\n" +
      "DTEND;VALUE=DATE:" +
      convertDate(endDate) +
      "\n" +
      "LOCATION:" +
      location +
      "\n" +
      "SUMMARY:" +
      eventName +
      "\n" +
      "DESCRIPTION:" +
      description +
      "\n" +
      "END:VEVENT\n" +
      "END:VCALENDAR";

    var data = new File([calendarData], { type: "text/plain" });

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    let icsFile = null;
    if (icsFile !== null) {
      window.URL.revokeObjectURL(icsFile);
    }

    icsFile = window.URL.createObjectURL(data);

    return icsFile;
  };

  return makeIcsFile(AppleEvent);
};

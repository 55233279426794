export const toTeam = (data) => {
  if (data) {
    return {
      createdBy: data.createdBy?.id,
      enabled: data.enabled,
      id: data.id,
      imageUrl: data.imageUrl,
      name: data.name,
      teamUsers: data.teamUsers,
    };
  }
  return {};
};

export const toTeamArray = (data) => {
  if(data) {
    return data
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(item => toTeam(item));
  }
  return [];
}
export const headerVersions = Object.freeze({
  version1: "version1",
  version2: "version2",
  version3: "version3",
  version4: "version4",
  version5: "version5",
});

export const headerGameTypes = Object.freeze({
  OPENGAME: "openGame",
  TEAMGAME: "teamGame",
  MYGAMES: "myGames"
});

import React from "react";
import { Flex } from "components/atoms";
import { Input } from "../../atoms";
import { texts } from "../../../Text";

const TeamsResult = ({
  isOrganiser,
  teamOneResult,
  teamTwoResult,
  setTeamOneResult,
  setTeamtwoResult,
}) => {
  return (
    <Flex direction="row" className={["tw-w-full"]}>
      {!isOrganiser && (
        <Flex direction="row" className={["tw-w-full"]}>
          <Flex
            className={[
              "tw-p-2 tw-bg-teamColors-darkBlue tw-w-1/2 tw-rounded-bl-3xl tw-text-center tw-text-white tw-text-3xl",
            ]}
          >
            {teamOneResult ? teamOneResult : "-"}
          </Flex>
          <Flex
            className={[
              "tw-p-2 tw-bg-red tw-w-1/2 tw-rounded-br-3xl tw-text-center tw-text-white tw-text-3xl",
            ]}
          >
            {teamTwoResult ? teamTwoResult : "-"}
          </Flex>
        </Flex>
      )}

      {isOrganiser && (
        <Flex direction="row" className={["tw-w-full"]}>
          <Flex
            className={[
              "tw-p-2 tw-bg-teamColors-darkBlue tw-w-1/2 tw-rounded-bl-3xl tw-text-center tw-text-white tw-text-3xl",
            ]}
          >
            <Input
              className={["tw-text-black text-center"]}
              placeholder={texts.insertScore}
              value={teamOneResult}
              marginBottom="mbnone"
              onChange={(event) => setTeamOneResult(event.target.value)}
            />
          </Flex>
          <Flex
            className={[
              "tw-p-2 tw-bg-red tw-w-1/2 tw-rounded-br-3xl tw-text-center tw-text-white tw-text-3xl",
            ]}
          >
            <Input
              className={["tw-text-black text-center"]}
              placeholder={texts.insertScore}
              value={teamTwoResult}
              marginBottom="mbnone"
              onChange={(event) => setTeamtwoResult(event.target.value)}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default TeamsResult;

import React from "react";
import { Flex, Img, Span, Heading } from "components/atoms";
import { texts } from "Text";

const TeamVSteamCore = (props) => {
  const { teamOne, teamTwo, smallCard = false } = props;
  const width = smallCard ? "tw-w-20" : "tw-w-28 ";
  const height = smallCard ? "tw-h-20" : "tw-h-28 ";
  return (
    <Flex
      direction="row"
      className={["tw-w-full tw-rounded-t-2xl tw-overflow-hidden tw-bg-grey-light"]}
    >
      {teamOne && (
        <Img
          className={[`${width} ${height} tw-rounded-2xl`]}
          src={teamOne.imageUrl || "/img/teamDefaultImg.png"}
        />
      )}

      <Flex className={["tw-justify-center tw-flex-1"]}>
        <Span
          size="xs"
          color={true}
          className={["tw-text-black tw-text-center tw-mb-1"]}
        >
          {teamOne?.name || texts.lookingforOpponent}
        </Span>
        <Span
          size="lg"
          color={true}
          className={["tw-text-black tw-text-center tw-mb-1"]}
        >
          {texts.vs}
        </Span>
        <Span
          size="xs"
          color={true}
          className={["tw-text-black tw-text-center"]}
        >
          {teamTwo?.name || texts.lookingforOpponent}
        </Span>
      </Flex>
      {teamTwo ? (
        <Img className={[`${width} ${height} tw-rounded-2xl`]} src={teamTwo.imageUrl || "/img/teamDefaultImg.png"} />
      ) : (
        <Flex
          className={[
            `${width} ${height}`,
            "tw-rounded-2xl tw-bg-grey tw-justify-center tw-items-center",
          ]}
        >
          <Heading marginBottom={0}>{texts.questionMark}</Heading>
        </Flex>
      )}
    </Flex>
  );
};

export default TeamVSteamCore;

import React from "react";
import { Paragraph, Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, SteppButtons, ErrorInfo } from "../../molecules";
import { matchTypeOptions} from "containers/constants";

const WhichTeamAreYouPlayingWith = (props) => {
  const {
    fnCancel,
    next,
    goBack,
    stepNumber,
    teams,
    selected,
    setSelected,
    createTeam,
    error,
    selectedMatchType,
    userId
  } = props;

  function isTeamAdmin(team) {
    return team.teamUsers.filter(user => user.userDetails.id == userId)[0].isAdmin;
  }

  const filteredTeams = teams.filter(team => isTeamAdmin(team));

  if (!teams || teams.length < 1) {
    return (
      <Flex
        className={[
          "tw-h-full tw-bg-black tw-z-50 tw-overflow-auto tw-px-8 md:tw-w-mmd md:tw-m-auto",
        ]}
      >
        <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
        <Flex className={["tw-py-16"]}>
          <Heading className={["tw-items-center"]}>
            {texts.ifDontHaveTeam}
          </Heading>
          <Button
            tertiary
            className={["tw-my-4 tw-text-sm"]}
            onClick={createTeam}
          >
            {texts.createNewTeam}
          </Button>
        </Flex>
        <SteppButtons
          fwdText={texts.next}
          fnBack={goBack}
          fnForward={createTeam}
        />
      </Flex>
    );
  }

  return (
    <Flex className={["tw-h-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
      <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
      <Flex className={["tw-px-8 xsm:tw-my-10 md:tw-w-mmd md:tw-m-auto"]}>
        {selectedMatchType === matchTypeOptions.organiseAMatch && (
          <Heading className={["tw-items-center"]}>
            {texts.doYouWantToInviteTeamMembers}
          </Heading>
        )}
        {selectedMatchType !== matchTypeOptions.organiseAMatch && (
          <Heading className={["tw-items-center"]}>
            {texts.whichTeamAreYouPlayingAs}
          </Heading>
        )}
        {filteredTeams && filteredTeams.length > 0 && (
          <Paragraph className={[""]} muted={false} size="xs">
            {texts.selectWhichTeam}
          </Paragraph>
        )}
        <Flex className={["tw-max-h-64 tw-overflow-auto"]}>
          {filteredTeams &&
            filteredTeams.length > 0 &&
            filteredTeams.map((team) => {
              return (
                <Button
                  quinternary={selected !== team.id}
                  hexternary={selected === team.id}
                  textColor={selected === team.id ? "black" : "white"}
                  className={["tw-my-1 tw-text-sm tw-border-2 tw-border-white"]}
                  onClick={() => {
                    if (team.id) {
                      setSelected(team.id);
                    }
                  }}
                >
                  {team.name}
                </Button>
              );
            })}
        </Flex>
        {(!filteredTeams || filteredTeams.length < 1) && (
          <Paragraph className={[""]} muted={false} size="sm">
          {texts.noTeamsError}
        </Paragraph>
        )}
        {error && <ErrorInfo error={error} />}
        <SteppButtons fwdText={texts.next} fnBack={goBack} fnForward={next} />
      </Flex>
    </Flex>
  );
};

export default WhichTeamAreYouPlayingWith;

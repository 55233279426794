import * as Action from "./actions";
import NewStoreState from "../../newStoreState";
import { KEY_ID } from "../../constants";

export function UnauthEventsReducer(
  state = NewStoreState.unAuthEvents,
  action
) {
  let unauthEvents = { ...state }; //Create new object for updating components.
  switch (action.type) {
    case Action.UNAUTH_EVENTS:
      const events = [...unauthEvents.eventList, ...action.payload.data];
      unauthEvents.eventList = [
        ...new Map(events.map((event) => [event[KEY_ID], event])).values(),
      ];
      return unauthEvents;

    case Action.RESET_UNAUTH_EVENTS:
      unauthEvents.eventList = [...action.payload.data];
      return unauthEvents;

    case Action.STOP_UNAUTH_EVENTS:
      unauthEvents.stopLoadEvents = action.payload.data;
      return unauthEvents;

    default:
      return state;
  }
}

export const texts = {
  questionMark: "?",
  //#region Settings
  optOutForSmsUpdatesTitle: "Opt-out for SMS updates",
  optOutForSmsUpdatesText: `SMS is used so that you can get the latest matches. 
  If you think it's too much, just turn it off with the button above`,
  emailNotifications: "Email Notifications",
  updateContactPhoneNumber: "Update Contact Phone Number",
  phoneNumberWithCountryCode: "Phone number with country code",
  yourContactDetails:
    "Your contact details are necessary when you join a match so that a match organiser can contact you directly.",
  smsNotificationsUpdated: "SMS notifications updated successfully!",
  emailNotificationsUpdated: "Email notifications updated successfully!",
  phoneNUmberUpdated: "Phone number updated succesfully!",
  //#endregion Settings

  //#region Links
  linkMyProfile: "My Profile",
  linkWhatsHappening: "Matches",
  linkGames: "MATCHES",
  linkPlayers: "Players",
  linkMyFriends: "My Friends",
  linkChat: "Chat",
  linkOrganiseGame: "ORGANISE A MATCH",
  linkLogout: "LOG OUT",

  linkNeedSupport: "NEED SUPPORT?",
  linkContactLeet: "CONTACT LEET",
  linkFAQ: "FAQ",
  linkTeams: "Join A Group",
  //#endregion Links

  //#region Buttons
  openMatch: "Join a Match",
  myGames: "My Matches",
  teamMatch: "Challenge",
  challengeATeam: "Challenge A Group",
  finish: "Finish",
  join: "Join",
  submit: "Submit",
  edit: "Edit",
  next: "Next",
  back: "Back",
  add: "Add",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  remove: "Remove",
  confirm: "Confirm",
  addAPlayer: "Add a player",
  deleteTeam: "Delete Group",
  saveTeam: "Save Group",
  uploadAvatar: "Upload Avatar",
  closeRegistrations: "Close Registrations",
  cancelMatch: "Cancel Match",
  cancelInvite: "Cancel Invite",
  logout: "Log Out",
  account: "Log In",
  settings: "Settings",
  signUp: "Sign Up",
  threeDots: ". . .",
  invite: "Invite",
  inviteV2: "Invite them to LEET",
  inviteFriend: "Invite Friend",
  accept: "Accept",
  decline: "Decline",
  turnOn: "Turn ON",
  turnOff: "Turn OFF",
  saveNumber: "Save Number",
  editProfile: "Edit Profile",
  upcoming: "Upcoming",
  organisedMatches: "Organised Matches",
  past: "Past",
  saveProfile: "Save Profile",
  playerAndGoaler: "Both",
  cancelJoining: "Cancel Joining",
  checkFeedback: "Check Feedback",
  shuffle: "SHUFFLE",
  doubleTapToSwitchTeam: "Double tap or double click on the player to change their team!",
  playersCapital: "PLAYERS",
  saveTeams: "Save Teams",
  reportFeedback: "Report feedback",
  ok: "Ok",
  appleCalendar: "Apple Calendar",
  googleCalendar: "Google Calendar",
  outlook: "Microsoft Outlook",
  acceptInvite: "Accept Invite",
  skip: "Skip",
  doneRegistration: "Finish registration",
  notMyTeam: "Not my group",
  teamMembers: "Group Members",
  addTeam: "Join Group",
  leave: "Leave",
  sent: "Sent",
  beginner: "Beginner",
  intermediate: "Intermediate",
  advanced: "Advanced",
  pro: "Pro",
  //#endregion Buttons

  //#region placeholders
  emailAddress: "Email Address",
  selectMatchPlace: "Choose a place",
  leetHome: "Leet Home Page",
  gameName: "Match name",
  pastMatchFeedBack:
    "Feedback to the organiser. The feedback is anonymous to everyone. Organiser will only see this feedback.",
  //#endregion placeholders

  //#region Teams
  joinTeamSuccess: "You have successfully joined this group!",
  requestToJoinTeamSuccess: "Your request to join this group has been sent!",
  defultTeamName: "No opponent yet",
  wins: "WINS",
  draws: "DRAWS",
  losses: "LOSSES",
  teamProfile: "Group Profile",
  teamDeleted: "Your group has been successfully deleted!",
  confirmDeleteTeam: "Are you sure you want to delete this group?",
  succesFullyRemovedFromTeam: "You were successfully removed from this group.",
  sureToDeleteYourselveFromTeam:
    "Are you sure that you want to remove yourselve from this group?",
  createNewTeam: "Create new Group",
  joinATeam: "Join a Group",
  teams: "Groups",
  myTeams: "My Groups",
  addTeamName: "Lets give your Group a name.",
  playersInMyTeam: "Players in my group",
  whoWillYouAddToTeam: "Who will you add to your group?",
  searchPlayersOnTeams:
    "Search Players on Leet to invite them.",
  noPlayerInYourTeam:
    "There are currently no players in your group. Add players and have fun!",
  playerSucessfullyAddedToTeam: "Player has been successfully added to your group!",
  playerSucessfullyRemovedFromTeam:
    "Player has been successfully removed from your event!",
  newTeamCreated: "Your group has been successfully created!",
  teamUpdated: "Your group has been successfully updated!",
  feedbackSubmitted: "Your feedback has been successfully submitted!",
  addTeamAvatar: "Lets give your Group an avatar.",
  playingInThisTeam: "I'm playing in this team",
  dontHaveTeams: "Oh no! You don’t have any groups. Create a new one.",
  teamOne: "Team 1",
  teamTwo: "Team 2",
  teamSavedSuccessfully: "Teams are saved successfully!",
  searchYourTeam: "Search for your group",
  insertTeamNameText: "Insert a friend's group name",
  insertTeamNamePlaceholder: "Group Name",
  couldNotFindPlayer: "We could not find a player with this name or email",
  similarTeamFound: "A similar group is found.",
  isThisYourTeam: "Is this your group?",
  userAddedToTeam: "You have successfully joined this group.",
  groupName: "Group Name",
  groupsPlaying: "Groups Playing",
  //#endregion Teams

  userRequestInitiated: `Your request is under process. Please wait until it is served.`,
  recommendedPhoneNumber: `Not compulsory but note that your contact number is useful when
you join a match so that a match organiser can contact you
directly.`,
  termsMustAccept: "Terms must be accepted!",
  footbalGround: " Football Ground",
  challengeThem: "Challenge Them",
  lookingforOpponent: "LOOKING FOR OPPONENT",
  vs: "VS",
  players: "Players",
  goaler: "Goaler",
  futballer: "Player",
  uploadASquareImage: "Upload a square image",
  enterFriendlyName: "Enter a friendly name (30 characters max)",
  hello: "Hello",
  findAmazingMatches: "Find Amazing Matches Near You",
  matchesHappening: "Football matches happening",
  findFriends: "Let's not stay lonely. Add your friends!",
  friendNotFound: "Friend not found? Invite them.",
  friendNotFoundV2: "Cannot find your friends?",
  enterTheirEmailAddress:
    "Enter their email address and we'll send them an invite to join LEET",
  noEventsFound: "No events found!",
  faqHeader: "FAQ ! Help",
  faqQuickStart: "QUICK START",
  yourProfile: "Your Profile",
  selectPlayerType: "Select what type of player are you",
  profileIsUpdated: "Your profile has been successfully updated!",
  chatHeader: "Chat",
  palyersFound: "Players Found",
  friendList: "Friends List",
  playerProfile: "Player Profile",
  addFriend: "Add Friend",
  acceptFriendRequest: "Accept Friend",
  chat: "Chat",
  linkCopiedSuccessfully:
    "The link of this page has been copied successfully on your clipboard!",
  feedbackHeader: "Feedback",
  noFeedback: "No feedback to show yet!",
  friendCanceled: "The player is successfully removed from your friend list.",
  friendAccepted: "The friend request has been successfully accepted.",
  friendRequestSent: "A friend request was sent successfully.",
  bookingPitch: "Book a pitch",
  addTeamNameV2: "Group name",
  thirtyCharMax: "(30 Character max)",
  imLookingTo: "I'm looking to...",
  findAPlayer: "Find a player",

  //#region Matches
  userRemoved: "User is successfully removed from the event!",
  userRequestAccepted: "User request successfully accepted!",
  noPlayersInThisEvent:
    "There are no players accepted in this event yet. Invite some in the edit event menu!",
  manageUsersInThisEvent: "Manage users in this event",
  manageUsers: "Manage users",
  organiser: "Organised by",
  eventSuccessfulyDeleted: "This event was successfuly deleted.",
  sureToDeleteThisEvent: "Are you sure you want to delete this event?",
  youNeedToSelectATeam:
    "You need to select a group in order to organise a match.",
  searchTeamsOnLeet: "Search groups on LEET",
  searchTeams: "Search groups",
  inviteATeamToPlayAgainst: "Invite a group to play against?",
  whichTeamAreYouPlayingAs: "Which group are you playing as?",
  doYouWantToInviteTeamMembers: "Do you want to invite your group members?",
  selectWhichTeam: "Select which group you are playing as:",
  ifDontHaveTeam: "Don't you have your group yet? Let's create one.",
  doYouHaveYourOwnTeam: "Do you have your own group?",
  noMatchesToDisplay: "There are no matches to display",
  reultsUpdated: "The results are successfully updated!",
  editMatch: "Edit Match",
  playersPlaying: "Players Playing",
  matchDuration: "45 min Duration",
  addToCall: "Add to Cal",
  aboutThisMatch: "About this Match",
  joinAndRsvp: "Join Match",
  matches: "Matches",
  organiseAMatch: "Organise a Match",
  bookAPitch: "Book A Pitch",
  matchDetails: "Match Details",
  needToBookPitch: "Do you need to book a pitch?",
  addGameName: "Lets give your Match a name.",
  matchAdditionalInfo: "Match additional information",
  textAreaMatchAdditionalInfo:
    "Give any additional information players need to know.",
  whereAndWhen: "Where and when?",
  selectDateAndTime: "Select date and time from the drop down below.",
  matchLocation: "Match location",
  datePicker: "Select Date and Time",
  privateMatch: "Private Match : ",
  privateMatchOn: "ON",
  privateMatchOff: "OFF",
  howManyPlayers: "How many players?",
  howManyPlayersAreYouLookingFor: "How many players are you looking for?",
  playesrsInTotal: "How many players will you be in total?",
  playingInThisMatch: "I'm playing in this match",
  alone: "Alone",
  howAreYouOrganisingIt: "How Are you Organising it?",
  joinAsTeamOrAlone: "Are you joining the match as a group or alone?",
  inviteOtherPlayersAndTeams: "Inviting other players?",
  searchPlayersOnLeet:
    "Search players on LEET or insert their e-mail address to invite them.",
  searchPlayers: "Search players",
  playerNotFound: "Player not found? Invite them.",
  teamNotFound: "Group not found? Invite them.",
  teamNotFoundV2: "Group not found?  Create it!",
  teamNotFoundV3:
    "We couldn't find a group with this name. Try another search word!",
  copyLinkButton: "Copy Link",
  copyLinkText:
    "Copy the link below and share it with all your friends to join!",
  finally: "Finally share your match and let's make it happen!",
  done: "Done",
  joinRequestSent: "Join request sent!",
  chooseYourTeamHeader: "Choose your group",
  eventTeams: "Teams",
  postMatch: "Post Match",
  insertScore: "Insert Score",
  organiserFeedback: "Organiser Feedback",
  reportProblem: "Report a problem",
  friendInvited: "Friend has been successfully invited!",
  selectCalendar: "Which calendar do you use?",
  inviteAccepted: "You have successfully accepted the invite!",
  //#endregion Matches

  //#region Errors
  updateTeamFailed:
    "We could not update your group for some reason. Please contact Leet support!",
  fbLoginError:
    "An error occured during facebook login. Please contact leet support!",
  uploadAvatarFailed: `Your group has been successfuly created, but your group picture could not be processed. 
    Please try to update it in your groups section with a different image!`,
  userIdIsMissing: "Some problem occured! Please try to register again.",
  firstNameIsMissing: "Fist name is required!",
  lastNameIsMissing: "Lat name is required!",
  dateOfBirthIsMissing: "Date of birth is required!",
  localityIdIsMissing: "Locality is required!",
  phoneNumberIsMissing: "Phonenumber is required!",
  teamNameIsRequired: "Group name is required!",
  gameNameIsRequired: "Match name is required!",
  errorAddFriendsApi: "Error in send friend request API.",
  errorUnauthorisedUser: "Unauthorized User",
  errorInviteUser: "Error in send invite to user",
  errorRemoveUser: "Error in remove invites user",
  noTeamsError: "You can only create a group match for groups that you administer!",
  locationIsRequired: "Please select the match location!",
  setValidDate: "Please select a valid date!",
  gameCreateFailed:
    "Unfortunatelly we could not create the match. Please contact Leet admin!",
  editProfileFailed:
    "Unfortunatelly we could not update your profile. Please contact Leet admin!",
  nameIsRequired: "Name is required!",
  userNotFound:
    "An error occured! We couldn't find the selected user. Please contact LEET support!",
  selectJoinMode: "Please select a group to join!",
  jointRequestFailed:
    "Unfortunatelly we could not complete the join request. Please contact Leet admin!",
  notLoggedIn:
    "Currently you are not logged in. Please log in before perform an action.",
  genericError:
    "Some error occured during the process. Please contact LEET support!",
  emailIsRequired: "Please provide a valid emailaddress!",
  notValidPhoneNumber: "Please provide a valid phone number!",
  missingPhoneNumber:
    "You need a valid phone number before organising a match. Please provide one!",
  missingPhoneNumberPlayer:
    "You need a valid phone number before joining a match. Please provide one!",
  setValidResult: "Please provide valid results for the teams",
  createTeamFailed: `We encountered an issue when creating your group. 
  Please contact the administrator for help.`,
  //#endregion Errors
};

import { fetchGet } from "services";
import * as Actions from "combineReducer/locations/actions";

export const citiesApi = () => (dispatch) =>
  fetchGet("localities")
    .then((response) => {
      dispatch(Actions.setCities(response));
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });

export const matchLocationsApi = () => (dispatch) =>
  fetchGet("locations")
    .then((response) => {
      dispatch(Actions.setMatchLocations(response));
      return response;
    })
    .catch((error) => {
      return {
        message: error.message || "Error in match locations API.",
      };
    });

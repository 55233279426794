import React from "react";
import { BasePage, Flex, Textarea } from "components/atoms";
import { Banner, TeamVSteamCore } from "components/molecules";
import moment from "moment";
import EventTeamsComponent from "../../molecules/eventTeams";
import HeaderComponent from "../../../containers/header";
import TeamsResult from "../../molecules/postMatch/teamsResult";
import PostMatchButtons from "../../molecules/postMatch/postMatchButtons";
import { MAX_FEEDBACK_LENGTH } from "../../../containers/constants";
import { headerVersions } from "../../../containers/header/constants";
import { texts } from "Text";
import { Paragraph, Img, Span } from "../../atoms";

const PostMatchPage = ({
  teamOnePlayers,
  teamTwoPlayers,
  maxNumberOfPlayers,
  isOrganiser,
  isTeamVSteam,
  checkFeedback,
  goToMatchDetails,
  setTeamOneResult,
  setTeamtwoResult,
  teamOneResult,
  teamTwoResult,
  onFeedback,
  sendFeedback,
  sendResults,
  matchDetails,
  organiserName,
  isEventPlayer,
  onTeamMemberDoubleClick
}) => {
  const teamOne = matchDetails?.sides[0]?.team;
  const teamTwo = matchDetails?.sides[1]?.team;
  const date = moment.utc(matchDetails?.dateTime).local();
  return (
    <BasePage>
      <HeaderComponent
        headerText={texts.postMatch}
        headerVersion={headerVersions.version3}
      />

      {isTeamVSteam ? (
        <TeamVSteamCore teamOne={teamOne} teamTwo={teamTwo} />
      ) : (
        <Flex className={["tw-rounded-3xl", "tw-bg-blue"]}>
          <Banner
            title={matchDetails?.name}
            caption={`organised by ${organiserName}`}
            src={"/img/match-details-banner.png"}
            rounded={false}
            className={["tw-rounded-t-3xl"]}
          />
        </Flex>
      )}
      <TeamsResult
        isOrganiser={isOrganiser}
        teamOneResult={teamOneResult}
        teamTwoResult={teamTwoResult}
        setTeamOneResult={setTeamOneResult}
        setTeamtwoResult={setTeamtwoResult}
      />
      <Flex direction="row" className={["tw-flex-wrap"]}>
        <Flex className={["tw-w-2/4 tw-pt-6"]}>
          <Span color="black" size="sm">
            <Img
              src="/img/calendar-icon.svg"
              className={["tw-inline-flex", "tw-pr-2"]}
              width={24}
              height={24}
            />
            {date?.format("ll")}
          </Span>
        </Flex>

        <Flex className={["tw-w-2/4 tw-pt-6"]}>
          <Span color="black" size="sm">
            <Img
              src="/img/clock-icon.svg"
              className={["tw-inline-flex", "tw-pr-2"]}
              width={24}
              height={24}
            />
            {date?.format("h:mm a")}
          </Span>
        </Flex>
      </Flex>
      {isOrganiser && (
        <Flex>
          <Paragraph className={["tw-mt-4"]} color="gray">
            Double tap on a player to change his side. The player's stats will be updated accordingly.
          </Paragraph>
        </Flex>
      )}
      <EventTeamsComponent
        maxNumberOfPlayers={maxNumberOfPlayers}
        teamOne={teamOnePlayers}
        teamTwo={teamTwoPlayers}
        onDoubleClick={isOrganiser ? onTeamMemberDoubleClick : null}
      />
      {!isOrganiser && (
        <Flex className={["tw-py-2"]}>
          <Paragraph color="black">{texts.organiserFeedback}</Paragraph>
          <Textarea
            className={["tw-border-2 tw-border-grey tw-h-48 textarea-feedback"]}
            defaultheight={false}
            placeholder={texts.pastMatchFeedBack}
            onChange={(e) => onFeedback(e.target.value)}
            maxLength={MAX_FEEDBACK_LENGTH}
          />
        </Flex>
      )}
      <PostMatchButtons
        checkFeedback={checkFeedback}
        goToMatchDetails={goToMatchDetails}
        isOrganiser={isOrganiser}
        sendFeedback={sendFeedback}
        sendResults={sendResults}
        isEventPlayer={isEventPlayer}
      />
      <a href="mailto:info@leet.mt" className="text-muted">
        <Flex
          className={[
            "tw-w-full tw-py-4 tw-justify-center tw-items-center tw-text-grey tw-text-sm",
          ]}
        >
          {texts.reportProblem}
        </Flex>
      </a>
    </BasePage>
  );
};

export default PostMatchPage;

import React from "react";
import { useHistory } from "react-router";
import { Flex, Img, Button, Heading, Paragraph, Separator } from "components/atoms";
import { playerLevels, playerTypes } from "containers/constants";
import { texts } from "../../../Text/index";

const CrestProfilePage = (props) => {
  const {
    user,
    cities,
    myTeams,
  } = props;

  const history = useHistory();

  const getlocalityById = () => {
    const locality = cities.find((city) => city?.id === user?.localityId);
    return locality?.name || "";
  };

  const defaultUserName = `${user?.firstName || ""} ${user?.lastName || ""}`;
  const shortenedDefaulUserName = `${defaultUserName.substring(0,15)}${defaultUserName.length > 15 ? '...' : ''}`;
  const location = user?.locality?.name || getlocalityById();
  const playerType = user?.playerType;
  const playerLevel = user?.playerLevel?.toLowerCase();
  const profileImgUrl = user?.profilePictureUrl;

  const playerLevelStars = 
    playerLevel === playerLevels.beginner ? `⭐`
      : playerLevel === playerLevels.intermediate ? `⭐⭐` 
        : playerLevel === playerLevels.advanced ? `⭐⭐⭐` 
          : playerLevel === playerLevels.pro ? `⭐⭐⭐⭐` 
            : ' ';

  if (user && user?.dateOfBirth){
    var diff_ms = Date.now() - new Date(user?.dateOfBirth).getTime();
    var age_dt = new Date(diff_ms); 
    var age = Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const commonParams = "tw-absolute tw-text-[#FFFFFF]";


  return (
    <Flex className={["tw-items-center"]}>
      <Flex className={["tw-items-center tw-w-[400px] tw-bg-transparent tw-z-10"]}>
        <Img
          className={["tw-relative tw-h-[500px] tw-w-[400px] tw--z-[10]"]}
          position="tw-object-fit"
          src={"/img/Crest-Red.png"}
        />
        <div className={["image-blurred-edge"]}>
          <Img
            className={["tw-absolute tw-h-[150px] tw-w-[150px] tw-top-[75px] tw-right-[60px] tw-rounded-3xl"]}
            src={profileImgUrl || "/img/unselected-player.png"}
          />
        </div>
        <Paragraph className={[`${commonParams} tw-top-[310px] tw-left-[230px]`]}>{texts.teams}</Paragraph>
        <Flex direction="row" position="absolute" className={[" tw-top-[340px] tw-left-[200px]"]}>
          {
            myTeams &&
              myTeams.length > 0 && 
              myTeams.slice(0,3).map((team) => {
                return (<Img
                  className={[`tw-h-[30px] tw-w-[30px] tw-mx-1 cursor-pointer tw-rounded-3xl`]}
                  src={team.imageUrl || "/img/teamDefaultImg.png"}
                  onClick={() => history.push(`/group/${team.id}`)}
                  title={team.name}
                  alt={team.name}
                />
              )})
          }
        </Flex>
        <Flex direction="row" position="absolute" className={[" tw-top-[380px] tw-left-[200px]"]}>
          {
            myTeams &&
              myTeams.length > 0 && 
              myTeams.slice(3,6).map((team) => {
                return (<Img
                  className={[`tw-h-[30px] tw-w-[30px] tw-mx-1 cursor-pointer tw-rounded-3xl`]}
                  src={team.imageUrl || "/img/teamDefaultImg.png"}
                  onClick={() => history.push(`/group/${team.id}`)}
                  title={team.name}
                  alt={team.name}
                />
              )})
          }
        </Flex>
        <Heading className={[`${commonParams} tw-top-[250px]`]}>{shortenedDefaulUserName}</Heading>
        <Separator className={[`${commonParams} tw-top-[265px]`]} width="tw-w-[250px]" color="tw-bg-white"></Separator>
        <Heading className={[`${commonParams} tw-top-[75px] tw-left-[90px] tw-text-4xl`]} size={true}>{age}</Heading>
        <Paragraph className={[`${commonParams} tw-top-[115px] tw-left-[95px]`]}>Age</Paragraph>
        <Img
          src={playerType === playerTypes.goalKeeper ? "/img/GoalerIcon.png" : "/img/footballer.png"}
          alt={playerType === playerTypes.goalKeeper ? playerTypes.goalKeeper : playerTypes.player}
          className={[`${commonParams} tw-top-[155px] tw-left-[85px] tw-w-[50px]`]}
        ></Img>
        <Paragraph className={[`${commonParams} tw-top-[200px] tw-left-[85px]`]}>{playerLevelStars}</Paragraph>
        <Paragraph size="sm" className={[`${commonParams} tw-top-[310px] tw-left-[90px]`]}>Won</Paragraph>
        <Paragraph size="sm" className={[`${commonParams} tw-top-[310px] tw-left-[155px] tw-font-bold`]}>{user?.stats?.matchesWon || 0}</Paragraph>
        <Paragraph size="sm" className={[`${commonParams} tw-top-[330px] tw-left-[90px]`]}>Lost</Paragraph>
        <Paragraph size="sm" className={[`${commonParams} tw-top-[330px] tw-left-[155px] tw-font-bold`]}>{user?.stats?.matchesLost || 0}</Paragraph>
        <Paragraph size="sm" className={[`${commonParams} tw-top-[350px] tw-left-[90px]`]}>Draw</Paragraph>
        <Paragraph size="sm" className={[`${commonParams} tw-top-[350px] tw-left-[155px] tw-font-bold`]}>{user?.stats?.matchesDraws || 0}</Paragraph>
        <Separator className={[`${commonParams} tw-top-[286px] tw-left-[143px]`]} width="tw-w-[1px]" color="tw-bg-white" height="tw-h-[60px]"></Separator>
      </Flex>
    </Flex>)
};

export default CrestProfilePage;

import * as Actions from "../../combineReducer/teams/actions";
import { fetchGet, fetchPost, fetchPut, fetchDelete } from "services";
import { toTeam, toTeamArray } from "./adapter";
import { toUploadProfileImage } from "../profile/adapter";

export const getAllTeams = (data) => (dispatch) =>
  fetchGet("teams", { data })
    .then((response = {}) => {
      const data = toTeamArray(response?.teams);
      dispatch(Actions.getAllTeams(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const getTeams = () => (dispatch) =>
  fetchGet("teams/me")
    .then((response = {}) => {
      const data = toTeamArray(response?.teams);
      dispatch(Actions.getMyTeams(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const createTeam = (data) => (dispatch) =>
  fetchPost("teams", {
    data,
  })
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.addNewTeam(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const updateTeam = (data) => (dispatch) =>
  fetchPut("teams", {
    data,
  })
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.updateTeam(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const deleteTeam = (data) => (dispatch) =>
  fetchDelete(`teams/${data.id}`)
    .then((response = {}) => {
      dispatch(Actions.deleteTeam(data?.id));
      return { success: true };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const requestToJoinTeam = (data) => (dispatch) =>
  fetchPost(`teams/${data.teamId}/user`)
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.updateTeam(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const addUserToTeam = (data) => (dispatch) =>
  fetchPut(`teams/${data.teamId}/user/${data.userId}`)
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.updateTeam(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const removeUserFromTeam = (data) => (dispatch) =>
  fetchDelete(`teams/${data.teamId}/user/${data.userId}`)
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.updateTeam(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const setAdminUser = (data) => (dispatch) =>
  fetchPut(`teams/${data.teamId}/admin/${data.userId}`)
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.updateTeam(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const getTeamById = (data) => (dispatch) =>
  fetchGet(`teams/${data?.teamId}`, {
    data,
  })
    .then((response = {}) => {
      const data = toTeam(response);
      dispatch(Actions.addNewTeamById(data));
      return { success: true, data };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

export const uploadTeamAvatar = (data) => (dispatch) =>
  fetchPost(`teams/${data?.teamId}/avatar`, {
    data: toUploadProfileImage(data?.file),
    contentType: null,
    raw: true,
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: { imageUrl: response?.imageUrl, id: data?.teamId },
        message: "",
      };
      dispatch(Actions.addTeamAvatar(response?.data));
      return response;
    })
    .catch((error) => {
      return { success: false, error: error };
    });

import React from "react";
import { Flex } from "../../atoms";
import { texts } from "../../../Text/index";

function MenuItems(props) {
  const { unreadMessages, navigate } = props;
  const navlinkClass = [
    "tw-p-1 tw-m-1 tw-font-montSerrat tw-font-medium tw-text-white tw-cursor-pointer",
  ];
  return (
    <Flex className={["tw-px-2 tw-py-4 tw-items-center"]}>

      <Flex
        onClick={(e) => navigate(e, "/my-profile")}
        className={navlinkClass}
      >
        {texts.linkMyProfile}
      </Flex>

      <Flex onClick={(e) => navigate(e, "/")} className={navlinkClass}>
        {texts.linkWhatsHappening}
      </Flex>

      <Flex onClick={(e) => navigate(e, "/groups")} className={navlinkClass}>
        {texts.teams}
      </Flex>

      <Flex
        onClick={(e) => navigate(e, "/find-player")}
        className={navlinkClass}
      >
        {texts.linkPlayers}
      </Flex>

      <Flex onClick={(e) => navigate(e, "/faq")} className={navlinkClass}>
        {texts.linkFAQ}
      </Flex>

      <Flex onClick={(e) => navigate(e, "/chat")} className={navlinkClass}>
        {texts.linkChat}
        {!!unreadMessages && (
          <span className="badge badge-red">{unreadMessages}</span>
        )}
      </Flex>
    </Flex>
  );
}

export default MenuItems;

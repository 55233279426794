import * as Actions from "combineReducer/events/unAuth/actions";
import { fetchGet } from "services";

// This endpoint is separated from authEvents because the response object is different
// so they will be stored in a different reducer.
export const unauthGameEvents =
  (
    data = {
      includePastEvents: true,
      pageNum: "1",
      pageSize: "20",
      setUpdate: false,
    }
  ) =>
  (dispatch) =>
    fetchGet("events", { data })
      .then((response) => {
        response = {
          success: true,
          data: response?.events,
          message: response?.events?.length
            ? "Successfully get friends"
            : "No friends found",
        };
        if (data?.setUpdate) {
          dispatch(Actions.resetUnauthGameEvents(response));
          return response;
        }

        response.data.length > 0
          ? dispatch(Actions.unauthGameEvents(response))
          : dispatch(Actions.stopUnauthEvents());
        return response;
      })
      .catch((error) => {
        if (error.status === 401) {
          return { message: "Unauthorized User" };
        } else {
          dispatch(
            Actions.unauthGameEvents({
              success: false,
              data: [],
              message: error.message || "Error in get unauthGame event API",
            })
          );
          return {
            message: error.message || "Error in get unauthGame event API",
          };
        }
      });

import React from "react";

import { Portal } from "components/molecules";
import {
  Heading,
  Button,
  Separator,
  Link,
  Input,
  Form,
  Flex,
} from "components/atoms";
import { LOGIN_EMAIL_VALIDATION } from "containers/login/const";
import FacebookSignupFailed from "components/signup/facebookSignupFailed";
import { useSelector } from "react-redux";

const LoginPage = (props) => {
  const { onSubmit, toggle, fbLogin } = props;

  const loginModalErrorOpen = useSelector((state) => {
    return state?.ModalReducer?.isLoginModalErrorOpened;
  });

  return (
    <Portal toggle={toggle} isMainLogoVisible isOpacity={false}>
      {loginModalErrorOpen ? (
        <>
          <FacebookSignupFailed />
        </>
      ) : (
        <>
          <Heading>Login</Heading>
          <Button quaternary onClick={fbLogin}>
            Facebook Login
          </Button>
          <Separator />
        </>
      )}
      <Form onSubmit={onSubmit}>
        {({ register }) => (
          <>
            <Input
              {...register("email", LOGIN_EMAIL_VALIDATION)}
              placeholder="Insert Email Address"
              className={["tw-mb-6"]}
            />
            <Button type="submit">Login | Sign Up</Button>
          </>
        )}
      </Form>
      <Link
        to="mailto:info@leet.mt"
        className={["tw-text-center", "tw-mt-6", "tw-text-muted"]}
        external
      >
        Contact Support
      </Link>
    </Portal>
  );
};

export default LoginPage;

import React from "react";
import classNames from "classnames";
import { textColors } from "../textColors";
import { textSizes } from "../textSizes";

const Span = (props) => {
  const {
    size = "base",
    className = [],
    muted,
    color = "white",
    children,
  } = props;
  const paragraphClass = classNames(
    `${textColors[color]}`,
    { "tw-text-muted": muted },
    `${textSizes[size]}`,
    "tw-leading-tight",
    ...className
  );

  return <span className={paragraphClass}>{children}</span>;
};

export default Span;

import NewStoreState from "../newStoreState";
import * as Action from "./actions";

export function LocationsReducer(state = NewStoreState.locations, action) {
  let locations = { ...state }; //Create new object for updating components.
  switch (action.type) {
    case Action.SET_CITIES:
      locations.cities = action.payload;
      return locations;
    case Action.SET_MATCH_LOCATIONS:
      locations.matchLocations = action.payload;
      return locations;

    default:
      return state;
  }
}

export const TAG_COLORS = [
  {
    name: "Blue",
    value: "Blue",
    active: false,
    activeColorName: "ligt-blue-team",
    deActiveColorName: "ligt-blue-team-opacity",
    isLoader: false,
  },
  {
    name: "Yellow",
    value: "Yellow",
    active: false,
    activeColorName: "yellow-team",
    deActiveColorName: "yellow-team-opacity",
    isLoader: false,
  },
  {
    name: "Red",
    value: "Red",
    active: false,
    activeColorName: "red-team",
    deActiveColorName: "red-team-opacity",
    isLoader: false,
  },
  {
    name: "Green",
    value: "Green",
    active: false,
    activeColorName: "greenish-team",
    deActiveColorName: "greenish-team-opacity",
    isLoader: false,
  },
  {
    name: "Purple",
    value: "Purple",
    active: false,
    activeColorName: "light-pink-team",
    deActiveColorName: "light-pink-team-opacity",
    isLoader: false,
  },
];

export const LOGIN_TYPES = {
  DEFAULT: "Normal",
  FACEBOOK: "Facebook",
};

export const FRIENDS_LIST_DEFAULT_PAGE_SIZE = 100;
export const USER_EVENT_DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_USER_AMOUNT = 20;

export const EVENT_TYPES = {
  joined: "mygames",
  requested: "gamesReq",
};

import { PAST_MATCH_MINUTES_AFTER_START, MOBILE_OS } from "./const";
import { toast } from "react-toastify";

export const generateArrayOfYears = () => {
  var max = new Date().getFullYear() - 1;
  var min = max - 80;
  var years = [];
  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

export const hindeNavBar = () => {
  let grabIcon = document.getElementById("humber");
  grabIcon.className = "collapsed navbar-toggler";
  let targetEl = document.getElementsByClassName("navbar-collapse");
  targetEl[0].classList.remove("show");
};

export const copyToClipboard = (url) => {
  if (url) {
    navigator.clipboard.writeText(url);
    toast.success("URL copied");
  }
};

export const cancelFlow = (history) => {
  history.push("/");
};

export const isPostMatch = (matchStartDate) => {
  if (!matchStartDate) {
    return;
  }

  //The "startdate" is the actual start date of the match.
  //The "pastMatchStartDate" is the time where we display the post match screen.
  //30 min after match started by default.
  const startdate = new Date(matchStartDate);
  const pastMatchStartDate = new Date(
    startdate.getTime() + PAST_MATCH_MINUTES_AFTER_START * 60000
  );

  const now = new Date();
  return now.getTime() > pastMatchStartDate;
};

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const getMobileOS = () => {
  const isIos = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
  const iSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  const andriod = /android/i.test(navigator?.userAgent);
  if (isIos && iSafari) {
    return MOBILE_OS.IOS_SAFARI;
  } else if (andriod) {
    return MOBILE_OS.ANDROID;
  }
  return MOBILE_OS.OTHER;
};

export const isTeamVSteam = (event) => {
  return !!event?.sides[0]?.team;
};

import LandingComponent from "./containers/landingPage";
import OrganiseMatchComponent from "./containers/organiseMatch";
import PlayerProfile from "containers/playerProfile";
import TermsConditions from "./containers/termsOfUse/termsOfUse";
import ResetPassword from "views/reset-password";
import MyProfileComponent from "./containers/myProfile";
import FindPlayer from "./containers/findPlayer";
import Settings from "./containers/settings";
import BookPitchComponent from "views/book-pitch";
import ConfirmEmail from "views/confirm-email";
import ChatComponent from "views/chat";
import TeamsComponent from "./containers/teams";
import EditTeamComponent from "./containers/teams/editTeam";
import MatchComponent from "containers/match";
import TeamDetailsComponent from "containers/teamDetails";
import EditMatchComponent from "containers/editMatch";
import FaqComponent from "./containers/faq";
import EventTeamsComponent from "./containers/eventTeams";
import PostMatch from "./containers/postMatch";
import Feedback from "./containers/feedback";
import TeamMembers from "./containers/teamDetails/teamMembers";
import Registration from "containers/registration";

const RouteList = [
  {
    path: "/",
    component: LandingComponent,
    exact: true,
  },
  {
    path: "/terms",
    component: TermsConditions,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/event/:eventId?",
    component: MatchComponent,
    exact: true,
  },
  {
    path: "/edit-match/:eventId?",
    component: EditMatchComponent,
    exact: true,
  },
  {
    path: "/group/:teamId?",
    component: TeamDetailsComponent,
    exact: true,
  },
  {
    path: "/group/members/:teamId?",
    component: TeamMembers,
    exact: true,
  },
  {
    path: "/event/teams/:eventId?",
    component: EventTeamsComponent,
  },
  {
    path: "/event/feedbacks/:eventId?",
    component: Feedback,
  },
  {
    path: "/player-profile/:playerId",
    component: PlayerProfile,
    showFooter: true,
  },
  {
    path: "/create-account",
    component: Registration,
  },
  {
    path: "/organize-match",
    component: OrganiseMatchComponent,
    showFooter: true,
  },
  {
    path: "/edit-match",
    component: OrganiseMatchComponent,
    isPrivate: true,
    showFooter: true,
  },
  {
    path: "/post-match/:eventId?",
    component: PostMatch,
  },
  {
    path: "/find-player",
    component: FindPlayer,
    isPrivate: true,
    showFooter: true,
  },
  {
    path: "/my-profile",
    component: MyProfileComponent,
    isPrivate: true,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
  {
    path: "/book-pitch",
    component: BookPitchComponent,
    isPrivate: true,
  },
  {
    path: "/confirm-email",
    component: ConfirmEmail,
  },
  {
    path: "/chat/:userId?",
    component: ChatComponent,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/chat/match/:matchId",
    component: ChatComponent,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/groups",
    component: TeamsComponent,
    isPrivate: false,
  },
  {
    path: "/group/edit/:teamId?/:step?",
    component: EditTeamComponent,
    isPrivate: false,
  },
  {
    path: "/faq",
    component: FaqComponent,
    isPrivate: false,
  },
];

export default RouteList;

import React from "react";
import { texts } from "../../../Text/index";
import { Span, Button, Flex, Img } from "components/atoms";

const TeamCard = (props) => {
  const {
    team,
    onClick,
    isOrganiseFlow = false,
    isJoined = false,
    isRequested = false,
    isOnSelectedList = false,
    isTeamCreator,
    goToTeamDetails,
    showNumPlayers = true
  } = props;
  return (
    <Flex
      direction="row"
      className={[
        "tw-pr-2 tw-m-2 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center tw-bg-grey-light",
        { "tw-mx-0": isOrganiseFlow },
        { "tw-my-1": isOrganiseFlow },
      ]}
    >
      <Img
        src={team?.imageUrl || "/img/teamDefaultImg.png"}
        alt={team?.name || "teamDefaultImg.png"}
        className={["tw-h-16 tw-w-16 tw-rounded-l-2xl tw-mr-4"]}
        onClick={() => goToTeamDetails(team.id)}
      ></Img>
      <Flex
        className={["tw-flex-1 tw-justify-center"]}
        onClick={() => goToTeamDetails(team.id)}
      >
        <Span size="xs" color="black" className={[""]}>
          {team?.name?.slice(0, 30)}
        </Span>
        {showNumPlayers && (<Span size="xxs" muted={true}>
          {team?.teamUsers?.length || "0"} {texts.players}
        </Span>)}
      </Flex>
      <Flex className={["tw-flex-1"]}></Flex>
      {isJoined && (
        <Button
          size="xs"
          onClick={onClick}
          className={["tw-h-7 tw-w-16 tw-text-xxs"]}
          rounded="lg"
          secondary={isOnSelectedList}
          septenary={!isTeamCreator && !isOrganiseFlow}
          disabled={!isTeamCreator && !isOrganiseFlow}
        >
          {isOrganiseFlow
            ? isOnSelectedList
              ? texts.remove
              : texts.add
            : texts.edit}
        </Button>
      )}
      {!isJoined && isRequested && (
       <Button
          size="xs"
          className={["tw-h-7 tw-w-16 tw-text-xxs"]}
          rounded="lg"
          disabled={true}
        >
          {texts.sent}
        </Button>
      )}
      {!isJoined && !isRequested && (
        <Button
          size="xs"
          onClick={onClick}
          className={["tw-h-7 tw-w-16 tw-text-xxs"]}
          rounded="lg"
        >
          {texts.join}
        </Button>
      )}
    </Flex>
  );
};

export default TeamCard;

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  myProfileApi,
  updateSettingsApi,
  updateProfileApi,
} from "services/profile";
import { Flex, Button, Input, Paragraph } from "components/atoms";
import Header from "containers/header/index";
import { ErrorInfo } from "../../components/molecules";
import { headerVersions } from "../header/constants";
import { texts } from "../../Text/index";

const Settings = (props) => {
  const { onUpdateSettings, onUpdateProfile } = props;
  const playerData = props?.myProfile?.profile;

  const [smsNotifications, setSmsNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!playerData) {
      return;
    }
    setSmsNotifications(playerData.settings?.smsNotifications);
    setEmailNotifications(playerData.settings?.emailNotifications);
    setPhoneNumber(playerData?.phoneNumber?.toString());
  }, [playerData]);

  // Use common data obj for the calls.
  const data = {
    acceptedTerms: playerData.settings?.acceptedTerms,
    viewedTutorial: playerData.settings?.viewedTutorial,
    emailMarketing: playerData.settings?.emailMarketing,
    emailNotifications: playerData.settings?.emailNotifications,
    smsNotifications: playerData.settings?.smsNotifications,
  };
  const updateSmsNotifications = () => {
    data.smsNotifications = !smsNotifications;
    setSmsNotifications(!smsNotifications);
    onUpdateSettings(data).then((response) => {
      if (response.success) {
        toast.success(texts.smsNotificationsUpdated);
        return;
      }
      toast.error(texts.genericError);
    });
  };

  const updateEmailNotifications = () => {
    data.emailNotifications = !emailNotifications;
    setEmailNotifications(!emailNotifications);
    onUpdateSettings(data).then((response) => {
      if (response.success) {
        toast.success(texts.emailNotificationsUpdated);
        return;
      }
      toast.error(texts.genericError);
    });
  };

  const savePhoneNumber = () => {
    if (Number(phoneNumber)) {
      setError("");
      const updatedata = {
        firstName: playerData.firstName,
        lastName: playerData.lastName,
        gender: playerData.gender,
        dateOfBirth: playerData.dateOfBirth,
        playerType: playerData.playerType,
        localityId: playerData.localityId,
        profilePictureUrl: playerData.profilePictureUrl,
        phoneNumber: Number(phoneNumber),
      };

      onUpdateProfile(updatedata).then((response) => {
        if (response?.success) {
          toast.success(texts.phoneNUmberUpdated);
          return;
        }
        toast.error(texts.genericError);
        return;
      });
    } else {
      setError(texts.notValidPhoneNumber);
    }
  };

  return (
    <Flex className={["tw-h-full tw-w-full tw-overflow-auto md:tw-w-mmd md:tw-m-auto"]}>
      <Header
        headerText={texts.settings}
        headerVersion={headerVersions.version3}
      />
      <Paragraph
        className={["tw-w-full tw-text-black tw-pt-4 tw-px-4"]}
        muted={true}
        size="xs"
      >
        {texts.optOutForSmsUpdatesTitle}
      </Paragraph>
      <Paragraph className={["tw-text-grey tw-px-4"]} muted={true} size="xxs">
        {texts.optOutForSmsUpdatesText}
      </Paragraph>
      <Flex className={["tw-mb-6 tw-border-b tw-border-grey-light"]}>
        <Button
          secondary={!smsNotifications}
          onClick={updateSmsNotifications}
          className={["tw-mx-4 tw-mb-6"]}
          border-b-2
          border-fuchsia-600
        >
          {smsNotifications ? texts.turnOff : texts.turnOn}
        </Button>
      </Flex>
      <Paragraph className={["tw-text-grey tw-px-4"]} muted={true} size="xs">
        {texts.emailNotifications}
      </Paragraph>
      <Flex className={["tw-mb-6 tw-border-b tw-border-grey-light"]}>
        <Button
          onClick={updateEmailNotifications}
          secondary={!emailNotifications}
          className={["tw-mx-4 tw-mb-6"]}
        >
          {emailNotifications ? texts.turnOff : texts.turnOn}
        </Button>
      </Flex>
      <Paragraph className={["tw-text-black tw-px-4"]} muted={false} size="xs">
        {texts.updateContactPhoneNumber}
      </Paragraph>
      <Input
        className={[
          "tw-mx-4 tw-mb-6 tw-border tw-border-grey-light tw-shadow-md",
        ]}
        placeholder={texts.phoneNumberWithCountryCode}
        value={phoneNumber ? phoneNumber : ""}
        onChange={(event) => setPhoneNumber(event.target.value)}
        type="number"
      />
      {error && <ErrorInfo error={error} className={["tw-mx-4 tw-mt-0"]} />}
      <Paragraph
        className={["tw-text-grey tw-px-4 tw-mb-4"]}
        muted={true}
        size="xs"
      >
        {texts.yourContactDetails}
      </Paragraph>
      <Button tertiary onClick={savePhoneNumber} className={["tw-mx-4"]}>
        {texts.saveNumber}
      </Button>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    citiesState: state.LocationsReducer.cities,
    myProfile: state.MyProfileReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: () => dispatch(myProfileApi()),
  onUpdateSettings: (data) => dispatch(updateSettingsApi(data)),
  onUpdateProfile: (data) => dispatch(updateProfileApi(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Settings);

import React from "react";
import classNames from "classnames";
import { twRounded } from "../rounded";

const Select = React.forwardRef((props, ref) => {
  const {
    name,
    onBlur,
    onChange,
    options = [],
    placeholder,
    className = [],
    rounded = "lg",
    backgroundColor,
    padding,
    wrapperDisabled = false,
  } = props;

  const selectClass = classNames(
    "focus:tw-outline-none",
    `${twRounded[rounded]}`,
    `tw-bg-${backgroundColor}`,
    `${padding}`
  );
  const wrapper = classNames(
    { "select-wrapper": !wrapperDisabled },
    "tw-flex",
    "tw-flex-col",
    "tw-flex-shrink-0",
    "tw-relative",
    ...className
  );

  return (
    <div className={wrapper}>
      <select
        ref={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        className={selectClass}
      >
        {placeholder && <option value={0}>{placeholder}</option>}
        {options &&
          options.length > 0 &&
          options.map((option, idx) => (
            <option
              key={idx}
              value={option?.id}
              selected={option?.selected}
              className="tw-text-xs"
            >
              {option.name}
            </option>
          ))}
      </select>
    </div>
  );
});

export default Select;

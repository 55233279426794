import React from "react";
import { Flex } from "components/atoms";
import TeamPlayerSticker from "./teamPlayerSticker";

const TeamColumn = (team, maxNumber, teamNumber, onDoubleClick) => {
  if (!team) {
    return;
  }
  return (
    <Flex className={teamNumber === 2 ? ["tw-items-end"] : []}>
      {[...Array(maxNumber)].map((_, i) => {
        return <TeamPlayerSticker player={team[i]} teamNumber={teamNumber} onDoubleClick={onDoubleClick} />;
      })}
    </Flex>
  );
};

export default TeamColumn;

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  myProfileApi,
  // userProfileApi,
  uploadPictureApi,
  updateProfileApi,
} from "services/profile";
import { authGameEvents } from "services/events";
import { getUserById } from "services/users";
import { citiesApi } from "services/locations";
import { toast } from "react-toastify";
import { Flex } from "components/atoms";
import PlayerProfilePage from "components/pages/playerProfile";
import Header from "containers/header/index";
import { headerVersions } from "../header/constants";
import { texts } from "../../Text/index";
import { actionButtons } from "../../components/pages/myProfile/constants";
import CrestProfilePage from "../../components/pages/playerProfile/crestProfile";
import { USER_EVENT_DEFAULT_PAGE_SIZE } from "services/constants";
import { getAllTeams } from "services/teams";

const PlayerProfile = (props) => {
  const { myProfileId, onAuthGameEvents, userEvents, onGetAllTeams, allTeams,} = props;
  const { playerId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  
  const [name, setName] = useState("");
  const [file, setFile] = React.useState(null);
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [active, setActive] = useState(1);
  const [events, setEvents] = useState(null);
  const [cities, setCities] = useState([]);
  const [playerLinkCopied, setPlayerLinkCopied] = useState(false);

  const validatePlayerData = () => {
    if (!name) {
      setError(texts.nameIsRequired);
      return false;
    }
    return true;
  };

  useEffect(() => {
    onGetAllTeams({ userId: playerId }, true)
    if (!userEvents || userEvents?.length < 1) {
      onAuthGameEvents({
        pageSize: USER_EVENT_DEFAULT_PAGE_SIZE,
        pageNum: 1,
        includePastEvents: true,
      });
    }
  }, []);

  useEffect(() => {
    let data = { setUpdate: true, pageNum: "1", pageSize: "100" };
    if (active === actionButtons.UPCOMING) {
      data = { includePastEvents: false, ...data };
      props.authGameEvents(data);
    }
    if (active === actionButtons.PAST) {
      data = { includePastEvents: true, ...data };
      props.authGameEvents(data);
    }
    if (active === actionButtons.ORGANISED_MATCHES) {
      data = { includePastEvents: false, ...data };
      props.authGameEvents(data);
    }
  }, [active]);

  useEffect(() => {
    if (playerId === myProfileId) {
      history.push("/my-profile");
    }
    setEvents(props.userEvents);
    if (!cities || cities.length < 1) {
      props.onLoadCities();
    }
    if (!user && playerId) {
      dispatch(getUserById(playerId));
    }
  }, [playerId]);

  useEffect(() => {
    setEvents(props.userEvents);
    setUser(props.userReducer?.user);
    setCities(props.citiesState);
    setUserStatus(props.userReducer?.status);
  }, [props.userEvents, props.userReducer]);

  const startChat = (id) => {
    history.push(`/chat/${id}`);
  };

  const copyPlayerLink = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    if (!playerLinkCopied) {
      toast.success(texts.linkCopiedSuccessfully);
      setPlayerLinkCopied(true);
    }
  };

  return (
    <Flex className={["tw-h-full md:tw-w-mmd md:tw-m-auto"]}>
      <Header
        headerText={texts.playerProfile}
        headerVersion={headerVersions.version3}
      />
      <CrestProfilePage
          user={user}
          cities={cities}
          myTeams={allTeams}
        />
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    userEvents: state.AuthEventsReducer.eventList,
    citiesState: state.LocationsReducer.cities,
    userReducer: state.UserReducer.selectedUser,
    myProfileId: state.MyProfileReducer?.profile?.id,
    allTeams: state.AllTeamsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authGameEvents: (data) => dispatch(authGameEvents(data)),
  onLoadCities: () => dispatch(citiesApi()),
  onUploadPicture: (data) => dispatch(uploadPictureApi(data)),
  onUpdateProfile: (data) => dispatch(updateProfileApi(data)),
  onAuthGameEvents: (data) => dispatch(authGameEvents(data)),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);

import React from "react";
import { texts } from "../../../Text/index";
import { Button, Flex } from "components/atoms";

const PlayerCradButtons = (props) => {
  const { player, goToChat } = props;

  return (
    <Flex
      direction="row"
      className={[
        "tw-bg-grey-light tw-m-2 tw-mb-1 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center",
      ]}
    >
      <Flex>
          <Button
            size="xs"
            tertiary
            className={["tw-h-7 tw-w-20 tw-text-xs"]}
            onClick={() => goToChat(player)}
            rounded="lg"
          >
            {texts.linkChat}
          </Button>
      </Flex>
    </Flex>
  );
};

export default PlayerCradButtons;

import React from "react";
import { Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";

const MatchTypeButtons = (props) => {
  const { active, setActive, actionButtons, myProfile = true } = props;
  return (
    <Flex
      direction="row"
      className={[
        `tw-w-full tw-py-8 tw-text-xxs ${
          myProfile ? "tw-justify-between" : "tw-justify-around"
        }`,
      ]}
    >
      <Button
        inactive={active !== actionButtons.UPCOMING}
        size="xs"
        onClick={() => setActive(actionButtons.UPCOMING)}
        className={[""]}
        textColor={active === actionButtons.UPCOMING ? "white" : "grey-button"}
      >
        {texts.upcoming}
      </Button>
      <Button
        inactive={active !== actionButtons.PAST}
        size="sm"
        onClick={() => setActive(actionButtons.PAST)}
        className={[""]}
        textColor={active === actionButtons.PAST ? "white" : "grey-button"}
      >
        {texts.past}
      </Button>
      {myProfile && (
        <Button
          inactive={active !== actionButtons.ORGANISED_MATCHES}
          size="xs"
          onClick={() => setActive(actionButtons.ORGANISED_MATCHES)}
          className={[""]}
          textColor={
            active === actionButtons.ORGANISED_MATCHES ? "white" : "grey-button"
          }
        >
          {texts.organisedMatches}
        </Button>
      )}
    </Flex>
  );
};

export default MatchTypeButtons;

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageInput,
  MessageInputFlat,
  MessageList,
  MessageTeam,
  Thread,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import { useParams } from "react-router-dom";
import { friendListApi } from "services/users";
import Header from "containers/header/index";
import { headerVersions } from "../containers/header/constants";
import { texts } from "../Text/index";

function ChatComponent({ profile, chatClient }) {
  const profileData = profile;
  const profileId = profileData.id;
  const filters = {
    $or: [{ members: { $in: [profileId] } }, { public: true }],
  };
  const options = { state: true, presence: true, limit: 25 };
  const sort = { last_message_at: -1 };
  const theme = "messaging";
  const [playerChannelId, setPlayerChannelId] = useState(null);

  let { userId, matchId } = useParams();

  //Let's make a random http call to avoid beeing able to open chat when we are not authenticated.
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(friendListApi({ pageNum: 1, pageSize: 1 }));
  }, []);

  useEffect(() => {
    if (playerChannelId) return;
    if (userId && chatClient && userId != profileId) {
      let channel = chatClient.channel("messaging", {
        members: [profileId, userId],
      });
      channel.create().then(() => {
        setPlayerChannelId(channel.id);
      });
    }
  }, [chatClient]);

  if (!chatClient || (userId && !playerChannelId && userId != profileId))
    return null;

  return (
    <div className="tw-bg-white md:mt-4 md:tw-w-60 md:tw-m-auto">
      <Header
        headerText={texts.chatHeader}
        headerVersion={headerVersions.version3}
      />
      <Chat client={chatClient} theme={theme}>
        <ChannelList
          filters={filters}
          sort={sort}
          options={options}
          customActiveChannel={playerChannelId || matchId}
        />
        <Channel>
          <Window>
            <ChannelHeader />
            <MessageList Message={MessageTeam} />
            <MessageInput Input={MessageInputFlat} />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.MyProfileReducer.profile,
});

export default connect(mapStateToProps)(ChatComponent);

export const termsOfUse = {
  pageTitle: `Terms of  Use`,
  subjectTotermsTitle: `Subject to the terms and conditions`,
  subjectTotermsContent: `Four Barrels Limited (“LEET”) grants to Customer, for a BETA testperiod, a limited non-exclusive, nontransferable, 
    revocablelicense to use its software products (“Software”). 
    Customeracknowledges that the Software contains confidential informationand trade secrets of LEET. 
    Customer will not use the Software forany purpose other than Customer’s internal evaluation and theprovision of feedback to LEET, 
    and not to disclose to any thirdparty without the prior written approval of LEET.`,
  acknowledgementOfBetaTitle: `Acknowledgment of Beta Software.`,
  acknowledgementOfBetaContent: `Software is prerelease code and is not at the level of performance
   or compatibility of a final, generally available product offering.
   Software may not operate correctly and may be substantially
   modified prior to first commercial shipment, or withdrawn.
   Software is provided "AS IS" without warranty of any kind. The
   entire risk arising out of the use or performance of Software
   remains with the Customer. In no event shall LEET be liable for
   any damage whatsoever arising out of the use of or inability to
   use Software, even if LEET has been advised of the possibility of
   such damages.`,
  IndemnityTitle: `Indemnity.`,
  IndemnityContent: `Customer agrees to indemnify and hold LEET, its officers,
   directors and employees harmless from any losses (including
   attorneys’ fees) that result from any third party claims related
   to Customer’s access, use or misuse of the Beta product(s), or any
   act or omission by Customer in violation of this Agreement.`,
  FeedBackTitle: `Feedback & IP.`,
  FeedBackContent: ` Customer will provide reasonable feedback to LEET concerning the
   features and functionality of the Software. If Customer provides
   feedback to LEET, all such feedback will be the sole and
   exclusive property of LEET. Customer hereby irrevocably transfers
   and assigns to LEET and agrees to irrevocably assign and transfer
   to LEET all of Customer's right, title, and interest in and to
   all feedback including all intellectual property rights therein
   (collectively, “Intellectual Property Rights”).`,
  privacyPolicyTitle: `Privacy Policy`,
  privacyPolicyContent: ` We follow appropriate measures and procedures in order to maintain
   the level of personal data protection to prevent unauthorized
   access, accidental loss and damage. Your personal data shall not
   be disclosed to third parties without your express consent, except
   under legal obligation. We will only retain your data for as long
   as it is necessary to fulfil the purpose it is being collected and
   processed for. Any personal data held by us for marketing and
   service update notifications, as requested by you, will be kept by
   us until such time that you notify us that you no longer wish to
   receive this information. In the event of any complaints or
   matters concerning your data, kindly contact info@leet.mt.`,
  disputeResolutionTitle: `Dispute Resolution, Governing Law`,
  disputeResolutionContent: `The parties will attempt to resolve any dispute related to this
   Agreement through good faith, informal negotiation. If initial
   negotiation does not resolve the dispute, this agreement is
   subject to the laws of Malta and will be disputed under the courts
   of arbitration in Malta.`,
  delitionUserDataTitle: `Deletion of User Data`,
  delitionUserDataContent: `If a user wishes to permanently delete their account and have all their data removed, 
   the user may do so by sending an email to info@leet.mt from the email account that was used to sign up to the platform and performing this request. 
   All the user's data will be permanently deleted within 1 week.`,
};

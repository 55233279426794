import React from "react";
import { Paragraph, Heading, Flex, Select, Checkbox } from "components/atoms";
import { playersInTotalDropDown } from "../../../containers/organiseMatch/constants";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, ErrorInfo, SteppButtons } from "../../molecules";
import { matchTypeOptions } from "containers/constants";

const AmountOfPlayers = (props) => {
  const {
    fnCancel,
    next,
    goBack,
    stepNumber,
    error,
    setMaxAmountOfPlayers,
    selectedAmount,
    isOrganiserPlaying,
    setIsOrganiserPlaying,
    selectedMatchType,
  } = props;
  return (
    <Flex className={["tw-h-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
      <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
      <Flex className={["tw-px-8 tw-my-20 md:tw-w-mmd md:tw-m-auto"]}>
        {selectedMatchType !== matchTypeOptions.findAPlayer && (
          <div>
            <Heading className={["tw-w-3/4 tw-items-center"]}>
              {texts.howManyPlayers}
            </Heading>
            <Checkbox
              name="playerInteam"
              value={"play"}
              defaultChecked={isOrganiserPlaying || false}
              label={texts.playingInThisMatch}
              className={["tw-text-white tw-mb-4"]}
              size="xs"
              onChange={() => setIsOrganiserPlaying(!isOrganiserPlaying) }
            />
            {error && <ErrorInfo error={error} />}
            <Paragraph className={["tw-w-full tw-pt-4"]} muted={false} size="xxs">
              {texts.playesrsInTotal}
            </Paragraph>
          </div>
        )}
        {selectedMatchType === matchTypeOptions.findAPlayer && (
          <Heading className={["tw-w-3/4 tw-items-center"]}>
          {texts.howManyPlayersAreYouLookingFor}
        </Heading>
        )}
        <Select
          className={["tw-text-sm tw-mb-4"]}
          options={playersInTotalDropDown(1, 99, selectedAmount)}
          onChange={(event) =>
            setMaxAmountOfPlayers(Number(event.target.value))
          }
        ></Select>
        <SteppButtons fwdText={texts.next} fnBack={goBack} fnForward={next} />
      </Flex>
    </Flex>
  );
};

export default AmountOfPlayers;

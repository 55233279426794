import React from "react";
import Modal from "deprecated/modal";
import { CloseButton, Flex, Img, Heading, Button } from "components/atoms";
import { useHistory } from "react-router";
import { texts } from "../../Text/index";
import { toast } from "react-toastify";
import { setLoginModalState } from "combineReducer/modal/actions";
import { connect } from "react-redux";
import { getCookie } from "services/cookies";
import { matchTypeOptions } from "../constants";

const LookingFor = (props) => {
  const {
    setLoginModalOpen,
    profile,
    tokenReducer,
    setSelectedMatchType
  } = props;
  const history = useHistory();

  const myProfileData = profile;

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    //We can check if we are logged in with checking if we either have the token from FbAuth
    //or if we have leetUserToken cookie from email auth.
    const cookie = getCookie("leetUserToken") || tokenReducer?.token;
    if (!cookie) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
  }, []);

  const organiseMatch = (selected) => {
    if (!isLoggedIn) {
      history.goBack();
      setLoginModalOpen(true);
      return;
    }
    if (!Number(myProfileData.phoneNumber)) {
      toast.error(texts.missingPhoneNumber);
      history.push("/settings");
    } else {
      setSelectedMatchType(selected);
    }
  };

  const bookAPitch = () => {
    window.open("https://sportsbooking.mt/football/?aff=4", '_blank', 'noreferrer');
  };

  return (
    <Flex
      className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
    >
      <Modal open={true}>
        <Flex className={["tw-h-full tw-w-full"]}>
          <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50"]}>
            <Flex
              direction="row"
              className={["tw-p-2 tw-m-2 tw-justify-between tw-items-center md:tw-w-mmd md:tw-mx-auto md:tw-px-10"]}
            >
              <Img
                src={"/img/logo.png"}
                alt="leet logo"
                className={["tw-w-12 tw-h-auto"]}
              ></Img>
              <CloseButton
                size="sm"
                className={["tw-h-10 tw-text-sm"]}
                onClick={() => { history.goBack(); }}
              />
            </Flex>
            <Flex className={["tw-px-8 tw-py-4 md:tw-w-mmd md:tw-m-auto"]}>
              <Heading className={[" tw-items-center"]}>{texts.imLookingTo}</Heading>
              <Flex className={["tw-py-4"]}>
                <Button
                  ninetenary
                  onClick={() => organiseMatch(matchTypeOptions.findAPlayer)}
                  className={["find-player"]}
                  rounded="lg"
                >
                  {texts.findAPlayer.toUpperCase()}
                </Button>
              </Flex>
              <Flex className={["tw-pb-4"]}>
                <Button
                  ninetenary
                  onClick={() => organiseMatch(matchTypeOptions.organiseAMatch)}
                  className={["organise-match"]}
                  rounded="lg"
                >
                  {texts.organiseAMatch.toUpperCase()}
                </Button>
              </Flex>
              <Flex className={["tw-justify-center tw-pb-4"]}>
                <Button
                  ninetenary
                  onClick={() => organiseMatch(matchTypeOptions.challengeATeam)}
                  className={["challenge-team"]}
                  rounded="lg"
                >
                  {texts.challengeATeam.toUpperCase()}
                </Button>
              </Flex>
              <Flex className={["tw-justify-center tw-pb-4"]}>
                <Button
                  tertiary
                  onClick={bookAPitch}
                  className={["book-a-pitch"]} //used for tracking purposes
                  rounded="lg"
                >
                  {texts.bookAPitch.toUpperCase()}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.MyProfileReducer.profile,
    tokenReducer: state.TokenReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLoginModalOpen: (data) => dispatch(setLoginModalState(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LookingFor);

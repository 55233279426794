import React from "react";
import { CloseButton, Flex, Img } from "components/atoms";

const LogoCloseTopBar = (props) => {
  const { onClick } = props;

  return (
    <Flex
      direction="row"
      className={["tw-p-2 tw-m-2 tw-justify-between tw-items-center md:tw-w-mmd md:tw-mx-auto md:tw-px-10"]}
    >
      <Img
        src={"/img/logo.png"}
        alt="leet logo"
        className={["tw-w-12 tw-h-auto"]}
      ></Img>
      <CloseButton
        size="sm"
        className={["tw-h-10 tw-text-sm"]}
        onClick={onClick}
      />
    </Flex>
  );
};

export default LogoCloseTopBar;

import React from "react";
import { BasePage, Flex, Button, Paragraph } from "components/atoms";
import { Banner } from "components/molecules";
import ShufflePlayers from "../../molecules/eventTeams/shufflePlayers";
import EventTeamsComponent from "../../molecules/eventTeams";
import HeaderComponent from "../../../containers/header";
import { headerVersions } from "../../../containers/header/constants";
import { texts } from "Text";

const EventTeamsPage = ({
  teamOne,
  teamTwo,
  saveTeam,
  maxNumberOfPlayers,
  isOrganiser,
  shufflePlayers,
  eventName,
  organiserName,
  onDoubleClick,
}) => {
  return (
    <BasePage>
      <HeaderComponent
        headerText={texts.eventTeams}
        headerVersion={headerVersions.version3}
      />
      <Flex className={["tw-rounded-3xl", "tw-bg-blue"]}>
        <Banner
          title={eventName}
          caption={`organised by ${organiserName}`}
          src={"/img/match-details-banner.png"}
        />
      </Flex>
      {isOrganiser && (
        <Flex>
          <ShufflePlayers shufflePlayers={shufflePlayers} />
          <Button onClick={saveTeam} className={["tw-my-4"]}>
            {texts.saveTeams}
          </Button>
          <Paragraph color="black" size="xs">{texts.doubleTapToSwitchTeam}</Paragraph>
        </Flex>
      )}
      <EventTeamsComponent
        maxNumberOfPlayers={maxNumberOfPlayers}
        teamOne={teamOne}
        teamTwo={teamTwo}
        onDoubleClick={isOrganiser ? onDoubleClick : null}
      />
    </BasePage>
  );
};

export default EventTeamsPage;

import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Heading, Flex } from "components/atoms";
import { texts } from "../../../Text/index";
import { flowSteps as createTeamSteps } from "../../../containers/constants";
import { BlackModalTopBar, SteppButtons, UploadImg } from "../../molecules";

const GiveYourTeamAvatar = (props) => {
  const {
    setCreateTeamsStep,
    imagePreview,
    setImagePreview,
    setError,
    clearErrors,
    error,
    backToTeamPage,
    setFile,
  } = props;
  return (
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black md:tw-w-mmd md:tw-m-auto"]}>
        <BlackModalTopBar
          onClick={backToTeamPage}
          stepNumber={createTeamSteps.step1 + "/" + createTeamSteps.step2}
        />
        <Flex className={["tw-px-4 xsm:tw-px-8 tw-py-24"]}>
          <Flex direction="row" className={[]}>
            <UploadImg
              name="profilePictureUrl"
              setError={setError}
              clearErrors={clearErrors}
              imagePreview={imagePreview}
              setImagePreview={setImagePreview}
              setFile={setFile}
            />
            <Heading className={["tw-items-center"]}>
              {texts.addTeamAvatar}
            </Heading>
          </Flex>
          <Paragraph className={["tw-w-full"]} muted={false} size="xs">
            {texts.uploadASquareImage}
          </Paragraph>
          <SteppButtons
            fwdText={texts.next}
            fnBack={() => setCreateTeamsStep(createTeamSteps.step1)}
            fnForward={() => setCreateTeamsStep(createTeamSteps.step3)}
          ></SteppButtons>
        </Flex>
      </Flex>
    </Flex>
    </Modal>
  );
};

export default GiveYourTeamAvatar;

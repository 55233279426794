import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { inviteFriendApi } from "services/friends";
import { inviteUser, eventRequestRemove } from "services/eventUser";
import { editGame, eventDetailById, deleteEventById } from "services/events";
import { friendListApi } from "services/users";
import { matchLocationsApi } from "services/locations";
import { myProfileApi } from "services/profile";
import { getTeams, getAllTeams } from "services/teams";
import { copyToClipboard, cancelFlow, validateEmail } from "../../utils/helper";
import OrganiseMatchSteps from "../organiseMatch/organiseMatchSteps";
import { texts } from "../../Text/index";
import { toast } from "react-toastify";
import { flowSteps as createMatchSteps } from "../constants";
import Modal from "deprecated/modal";
import { ConfirmComponent } from "../../components/molecules";
import moment from "moment";
import { userEventStatus } from "../constants";

const EditMatch = (props) => {
  const {
    selectedEvent,
    suggestedUsers,
    onGetMyTeams,
    myTeams,
    organiserProfileId,
    getEventDetailById,
    allTeams,
    onGetAllTeams,
  } = props;

  const history = useHistory();
  let { eventId } = useParams();

  //Based on this integer we display one of the teams pages.
  const [createMatchStep, setCreateMatchStep] = useState(1);
  const [gameName, setGameName] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [location, setLocation] = useState(""); // stores the locationId.
  const [teamMembers, setTeamMembers] = useState([]);
  const [maxAmountOfPlayers, setMaxAmountOfPlayers] = useState("");
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(null); // This refers to teamId if the organiser will be a team. -1 is asigned if organised alone.
  const [playersNotInTeam, setPlayersNotInTeam] = useState([]);
  const [date, setDate] = useState(new Date());
  const [urlToCopy, setUrlToCopy] = useState("https://beta.leet.mt/");
  const [privateMatch, setPrivateMatch] = useState(false);
  const [email, setEmail] = useState("");
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isTeamevent, setIsTeamevent] = useState(false);
  const [isOrganizerPlaying, setIsOrganizerPlaying] = useState(false);
  const [opponentTeams, setOpponentTeams] = useState([]); // This refers to teamIds we are inviting to play against.
  const [searchWord, setSearchWord] = useState(""); // This refers to team names search.
  const [pageNumber , setPageNumber] = useState(1);

  const finalPage = '5';

  const goToNextPage = (stepNumber) => {
    setPageNumber(pageNumber+1);
    setCreateMatchStep(stepNumber);
  }

  const goToPreviousPage = (stepNumber) => {
    setPageNumber(pageNumber-1);
    setCreateMatchStep(stepNumber);
  }

  useEffect(() => {
    getEventDetailById(eventId);
  }, [eventId]);

  useEffect(() => {
    const users = suggestedUsers?.map((value) => value.user);
    setPlayersNotInTeam(users);
  }, [suggestedUsers]);

  useEffect(() => {
    setGameName(selectedEvent?.name);
    setGameDetails(selectedEvent?.description);
    setLocation(selectedEvent?.locationId);
    setTeamMembers(selectedEvent?.users.map((user) => user?.id));
    setMaxAmountOfPlayers(selectedEvent?.maxPlayers);
    setDate(moment.utc(selectedEvent?.dateTime).local().toDate());
    setPrivateMatch(selectedEvent?.private);
    const users = suggestedUsers?.map((value) => value.user);
    setPlayersNotInTeam(users);
    setIsTeamevent(!!selectedEvent?.sides[0]?.team);
    setSelected(selectedEvent?.sides[0]?.team?.id || null);
    setIsOrganizerPlaying(selectedEvent?.users?.filter(x => x.isOrganizer)[0]?.status == userEventStatus.JOINED);
  }, [selectedEvent]);

  const onSubmit = () => {
    let submitData = {
      id: eventId,
      name: gameName,
      dateTime: moment.utc(date),
      locationId: location,
      maxPlayers: parseInt(maxAmountOfPlayers),
      description: gameDetails,
      invitedPlayersIds: teamMembers,
      invitedTeamIds: opponentTeams,
      reservedPlayers: 0,
      isPlaying: isOrganizerPlaying,
      private: privateMatch,
      playingAsTeamId: selected || null,
    };
    //Add organiserId to data for now to make calls work!
    if (!submitData?.invitedPlayersIds.includes(organiserProfileId)) {
      submitData?.invitedPlayersIds.push(organiserProfileId);
    }
    if (selected === -1) {
      submitData.invitedTeamIds = [];
    }

    props.onEditEvent(submitData).then(({ message, data, success } = {}) => {
      if (success) {
        setUrlToCopy(
          `${process.env.REACT_APP_WEBURL}event/${data?.eventResponse?.id}`
        );
        goToNextPage(createMatchSteps.step7);
        toast.success(texts.teamUpdated);
      } else {
        toast.error(message ? message : texts.gameCreateFailed);
        history.push("/");
      }
    });
  };

  const validateStep = () => {
    if (createMatchStep === createMatchSteps.step1) {
      gameName
        ? goToNextPage(createMatchSteps.step2)
        : setError(texts.gameNameIsRequired);
      return;
    }
    if (!location) {
      setError(texts.locationIsRequired);
      return;
    }
    if (date < new Date()) {
      setError(texts.setValidDate);
      return;
    }
    goToNextPage(createMatchSteps.step3);
  };

  const addOrRemoveTeamMember = (member) => {
    //Todo: Set teamMembers if added and remove teammembers from suggestedUsers.
    const memberId = member?.id;
    let inviteUserData = {
      eventId: eventId,
      userId: memberId,
    };
    if (!teamMembers.includes(memberId)) {
      setTeamMembers((teamMembers) => [...teamMembers, memberId]);
      props.onInviteUser(inviteUserData).then(({ message, data, success } = {}) => {
        if (success) {
          toast.success(texts.playerSucessfullyAddedToTeam);
        } else {
          toast.error(message ? message : texts.errorInviteUser);
        }
      });
    } else {
      const reducedMembers = teamMembers.filter((id) => id !== memberId);
      setTeamMembers(reducedMembers);
      props.onEventRequestRemove(inviteUserData).then(({ message, data, success } = {}) => {
        if (success) {
          toast.success(texts.playerSucessfullyRemovedFromTeam);
        } else {
          toast.error(message ? message : texts.errorRemoveUser);
        }
      });
    }
  };

  const searchOnChange = React.useCallback(
    debounce((value) => {
      props
        .onSuggestedUsers({ name: value || "" }, true)
        .then((response) => {});
    }, 800),
    []
  );

  useEffect(() => {
    if (!organiserProfileId) {
      props.fetchProfile();
    }
    if (playersNotInTeam.length === 0) {
      searchOnChange();
    }
    if (!props.matchLocations || props.matchLocations?.length === 0) {
      props.onLoadMatchLocations();
    }
    if (createMatchStep === createMatchSteps.step0) {
      setGameName("");
      setError("");
      setTeamMembers([]);
    }
    if (createMatchStep === createMatchSteps.step2) {
      setError("");
    }
  }, [createMatchStep]);

  const getStepString = () => {
    return [
      pageNumber.toString(),
      "/",
      finalPage,
    ].join("");
  };

  const inviteFriend = () => {
    if (!email || !validateEmail(email)) {
      setError(texts.emailIsRequired);
    } else {
      props.onInviteFrien(email).then((response) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          if (response?.status === 409) {
            toast.error(response.message);
          }
        }
      });
      setError("");
    }
  };

  const goToInviteFriend = () => {
    setError("");
    goToNextPage(createMatchSteps.step7);
  };

  useEffect(() => {
    onGetMyTeams();
    onGetAllTeams();
  }, []);

  const deleteEvent = () => {
    setIsDeleteModalOpened(!isDeleteModalOpened);
  };
  const confirmDeleteEvent = () => {
    props.onDeleteEvent(eventId).then((response) => {
      if (response.success) {
        history.push("/");
        toast.success(texts.eventSuccessfulyDeleted);
      } else {
        toast.error(texts.genericError);
      }
    });
  };

  const addteam = (teamId) => {
    if (!teamId) {
      return;
    }
    if (opponentTeams.includes(teamId)) {
      setOpponentTeams(opponentTeams.filter((id) => teamId !== id));
    } else {
      setOpponentTeams([teamId, ...opponentTeams]);
    }
  };

  const searchTeam = React.useCallback(
    debounce((value) => {
      onGetAllTeams({ name: value }, true).then((response) => {});
    }, 800),
    []
  );

  return (
    <>
      <OrganiseMatchSteps
        createMatchStep={createMatchStep}
        getStepString={getStepString}
        cancelOrganiseMatch={() => cancelFlow(history)}
        history={history}
        setCreateMatchStep={setCreateMatchStep}
        gameName={gameName}
        setGameName={setGameName}
        gameDetails={gameDetails}
        setGameDetails={setGameDetails}
        error={error}
        validateStep={validateStep}
        date={date}
        setDate={setDate}
        setLocation={setLocation}
        setMaxAmountOfPlayers={setMaxAmountOfPlayers}
        addOrRemoveTeamMember={addOrRemoveTeamMember}
        profileData={props?.profile?.profile}
        selected={selected}
        setSelected={setSelected}
        onSubmit={onSubmit}
        playersNotInTeam={playersNotInTeam}
        teamMembers={teamMembers}
        searchOnChange={searchOnChange}
        urlToCopy={urlToCopy}
        copyToClipboard={copyToClipboard}
        locations={props.matchLocations}
        selectedLocation={location}
        privateMatch={privateMatch}
        setPrivateMatch={setPrivateMatch}
        goToInviteFriend={goToInviteFriend}
        inviteFriend={inviteFriend}
        setEmail={setEmail}
        allTeams={allTeams}
        myTeams={myTeams}
        isUpdateMatch={true}
        selectedAmount={parseInt(maxAmountOfPlayers)}
        isOrganiserPlaying={isOrganizerPlaying}
        setIsOrganiserPlaying={setIsOrganizerPlaying}
        deleteEvent={deleteEvent}
        isTeamevent={isTeamevent}
        addTeam={addteam}
        opponentTeams={opponentTeams}
        searchTeam={searchTeam}
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
      />
      <Modal open={isDeleteModalOpened}>
        <ConfirmComponent
          text={texts.sureToDeleteThisEvent}
          fnYes={confirmDeleteEvent}
          fnNo={deleteEvent}
          fnCancel={deleteEvent}
        ></ConfirmComponent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.MyProfileReducer,
    matchLocations: state.LocationsReducer?.matchLocations,
    myTeams: state.MyTeamsReducer,
    allTeams: state.AllTeamsReducer,
    suggestedUsers: state.UserReducer?.usersList,
    selectedEvent: state?.AuthEventsReducer?.event?.eventResponse,
    organiserProfileId: state.MyProfileReducer.profile?.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEventDetailById: (data) => dispatch(eventDetailById(data)),
  onLoadMatchLocations: (data) => dispatch(matchLocationsApi(data)),
  onSuggestedUsers: (data, refreshdata) =>
    dispatch(friendListApi(data, refreshdata)),
  onEditEvent: (data) => dispatch(editGame(data)),
  fetchProfile: () => dispatch(myProfileApi()),
  onInviteFrien: (data) => dispatch(inviteFriendApi(data)),
  onGetMyTeams: () => dispatch(getTeams()),
  onDeleteEvent: (data) => dispatch(deleteEventById(data)),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
  onInviteUser: (data) => dispatch(inviteUser(data)),
  onEventRequestRemove: (data) => dispatch(eventRequestRemove(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditMatch);

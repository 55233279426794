import { StreamChat } from "stream-chat";
import { API_CHAT_KEY } from "utils/const";
import * as Actions from "combineReducer/chat/actions";
import { fetchGet } from "services";

const chatClient = StreamChat.getInstance(API_CHAT_KEY);

export const initChat = (id, token) => (dispatch) => {
  let connect;
  if (!token) {
    connect = dispatch(chatToken()).then(({ chatToken }) =>
      chatClient.connectUser({ id }, chatToken)
    );
  } else {
    connect = chatClient.connectUser({ id }, token);
  }
  return connect
    .then(() => {
      if (!isNaN(chatClient.user.unread_count)) {
        dispatch(Actions.setUnreadMessages(chatClient.user.unread_count));
      }
      chatClient.on((event) => {
        if (!isNaN(event?.total_unread_count)) {
          dispatch(Actions.setUnreadMessages(event.total_unread_count));
        }
      });
      return chatClient;
    })
    .catch((err) => console.log(err));
};

export const chatToken = () => (dispatch) =>
  fetchGet("chat/token")
    .then((response = {}) => {
      dispatch(Actions.setUserChatToken(response.chatToken));
      return response;
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });

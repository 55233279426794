import React from "react";

import { Flex, Img } from "components/atoms";

const maxRating = 5;

// This element is temporarily hidden.
const Rating = (props) => {
  const { size = "base", className = [], value = 0, starSize = {} } = props;
  const ratingClass = ["tw-hidden tw-h-3", ...className];

  return (
    <Flex direction="row" className={ratingClass}>
      {[...Array(maxRating).keys()].map((x) => (
        <Img
          key={x}
          src={
            x <= value - 1 ? "/img/star-icon.svg" : "/img/star-empty-icon.svg"
          }
          width={starSize.width || 18}
          height={starSize.height || 18}
          className={["tw-ml-1"]}
        />
      ))}
    </Flex>
  );
};

export default Rating;

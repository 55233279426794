import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Input, Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import {
  BlackModalTopBar,
  TeamPlayerCard,
  SteppButtons,
} from "../../molecules";

const InviteOtherPlayers = (props) => {
  const {
    players,
    addOrRemoveTeamMember,
    submit,
    goBack,
    fnCancel,
    stepNumber,
    teamMembers,
    searchOnChange,
    invitePlayer,
  } = props;
  return (
    <Modal open={true}>
      <Flex
        className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}
      >
        <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
        <Flex className={["tw-px-12 xsm:tw-mt-10 md:tw-w-mmd md:tw-m-auto"]}>
          <Heading className={[" tw-items-center"]}>
            {texts.inviteOtherPlayersAndTeams}
          </Heading>
          <Paragraph className={["tw-max-w-xxs"]} muted={false} size="xs">
            {texts.searchPlayersOnLeet}
          </Paragraph>
          <Input onChange={(e) => searchOnChange(e.target.value)}></Input>
          <Flex className={["tw-h-48 tw-mb-4 tw-overflow-auto"]}>
            {players && players.length > 0 ? (
              players.map((player) => {
                return (
                  <TeamPlayerCard
                    onClick={() => addOrRemoveTeamMember(player)}
                    player={player}
                    teamMembers={teamMembers}
                  ></TeamPlayerCard>
                );
              })
            ) : (
              <Flex
                direction="row"
                className={[
                  "tw-py-4 tw-mt-2 tw-text-white tw-text-xxs tw-justify-between",
                ]}
              >
                {texts.playerNotFound}

                <Button
                  onClick={() => invitePlayer()}
                  rounded="lg"
                  size="sm"
                  className={["tw-ml-3"]}
                >
                  {texts.invite}
                </Button>
              </Flex>
            )}
          </Flex>
          <SteppButtons
            fwdText={texts.finish}
            fnBack={goBack}
            fnForward={submit}
          ></SteppButtons>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default InviteOtherPlayers;

import React from "react";
import { Flex, Img} from "components/atoms";
import EditProfileComponent from "./editProfile";
import { texts } from "../../../Text/index";
import CrestProfilePage from "../playerProfile/crestProfile";

const MyProfilePage = (props) => {
  const {
    editProfile,
    setEditProfile,
    user,
    setName,
    setSelectedLocation,
    setSelectedDay,
    setSelectedMonth,
    setSelectedYear,
    cities,
    setFile,
    imagePreview,
    setImagePreview,
    submit,
    setPlayerType,
    setPlayerLevel,
    error,
    myTeams
  } = props;

  const getlocalityById = () => {
    const locality = cities.find((city) => city?.id === user?.localityId);
    return locality?.name || "";
  };

  const defaultUserName = `${user?.firstName} ${user?.lastName}`;
  const location = user?.locality?.name || getlocalityById();
  const playerType = user?.playerType;
  const profileImgUrl = user?.profilePictureUrl;

  return (
    <Flex className={["tw-p-4 tw-pt-0"]}>
      {editProfile ? (
        <EditProfileComponent
          setName={setName}
          setPlayerType={setPlayerType}
          setPlayerLevel={setPlayerLevel}
          setSelectedLocation={setSelectedLocation}
          setSelectedDay={setSelectedDay}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
          cities={cities}
          defaultUserName={defaultUserName}
          setFile={setFile}
          imagePreview={imagePreview || profileImgUrl}
          setImagePreview={setImagePreview}
          submit={submit}
          error={error}
          user={user}
        />
      ) : (
        <Flex>
          <Img
            src={"/img/edit-profile.png"}
            title="editProfileIcon"
            alt="edit"
            className={["tw-relative tw-h-10 tw-w-10 tw-top-0 tw-ml-auto", "tw-cursor-pointer"]} //"tw-h-4 tw-mt-2"
            onClick={() => setEditProfile(true)}
          />
          <CrestProfilePage
            user={user}
            cities={cities}
            myTeams={myTeams}
        />
        </Flex>
      )}
    </Flex>
  );
};

export default MyProfilePage;

import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Input, Heading, Flex } from "components/atoms";
import { texts } from "../../../Text/index";
import { flowSteps as createTeamSteps } from "../../../containers/constants";
import { BlackModalTopBar } from "../../molecules";
import TeamMemberCard from "../../molecules/teams/teamMemberCard";
import SteppButtons from "../../molecules/general/steppButtons";
import GoalerFutballerRadio from "../../molecules/general/goalerFutballerRadio";

const AddPlayerToTeam = (props) => {
  const {
    players,
    addOrAcceptTeamMember,
    removeTeamMember,
    submit,
    backToTeamPage,
    fnBack,
    teamMembers,
    onSearchChange,
    onTypeChange,
  } = props;
  return (
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black md:tw-w-mmd md:tw-m-auto"]}>
          <BlackModalTopBar
            onClick={backToTeamPage}
            stepNumber={createTeamSteps.step2 + "/" + createTeamSteps.step2}
          />
          <Flex className={["tw-px-12"]}>
            <Heading className={[" tw-items-center"]}>
              {texts.whoWillYouAddToTeam}
            </Heading>
            <Paragraph className={["tw-w-full"]} muted={false} size="xs">
              {texts.searchPlayersOnTeams}
            </Paragraph>
            <Input onChange={(e) => onSearchChange(e.target.value)}></Input>
            <GoalerFutballerRadio onChange={onTypeChange} />
            <Flex className={["tw-h-48 tw-mb-4 tw-overflow-auto"]}>
              {players && players.length > 0 ? (
                players.map((player) => {
                  return (
                    <TeamMemberCard
                      onAddOrAccept={() => addOrAcceptTeamMember(player?.user)}
                      onRemove={() => removeTeamMember(player?.user)}
                      player={player?.user}
                      teamMembers={teamMembers}
                    ></TeamMemberCard>
                  );
                })
              ) : (
                  <Flex className={["tw-pt-8 tw-text-white"]}>{texts.couldNotFindPlayer}</Flex>
                )}
            </Flex>
            <SteppButtons
              fwdText={texts.saveTeam}
              fnBack={fnBack}
              fnForward={() => submit()}
            ></SteppButtons>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default AddPlayerToTeam;

export const faqTexts = {
  whatIsLeetSumm: "What is LEET?",
  whatIsLeetDetail:
    "LEET is a community based app that lets people organise 5-a-side football matches.",

  isItFreeSumm: "Is it free?",
  isItFreeDetail:
    "LEET is totally free! The only thing that needs to be paid for is the matches since pitches are rented out, just like when it’s done traditionally.",

  howToStartSumm: "How to start on LEET?",
  howToStartDetail: `Polish up your profile
Head to the lobby and join a game
Find any friends you might have on LEET
Why not try organise a game?`,

  howToFindaGameSumm: "How do I find a game?",
  howToFindaGameDetail: `Go to the MENU
Tap on MATCHES HAPPENING
Select a game from the list
Tap on it to check it’s details
Tap on Join if you like it
Wait until the organiser approves
You receive an Email or SMS
Turn up and have fun!`,

  howDoIOrganiseSumm: "How do I organise a game?",
  howDoIOrganiseDeails: "Tap on ORGANISE A GAME Enter the match details. Once satisfied, Tap on FIND PLAYERS Invite players or wait for join requests Fulfill your game Turn up and play!",

  findingFriendSumm: "Finding a friend",
  findingFriendDetail: `Got a friend that plays? You can see if they play on LEET.
Tap on the Menu
Tap on SEARCH PLAYERS
Search for them from the top search box
Add them to your list of friends
Can't find them? Invite them to LEET via email!`,

  organisedAndMistakeSum: "Organised a game and I made a mistake",
  organisedAndMistakeDetail: `Lets have it sorted out. Do the following:
Tap on your profile
Tap on ORGANISED MATCHES
Tap the game you wish to edit
Tap on EDIT
Update what you need to arange
Tap on SUBMIT`,

  whyNeedMobileSumm: "Why do you need my mobile number?",
  whyNeedMobileDetail: `It’s so that organisers and players can communicate when joining games, or maybe a change of last minute plans happen. 
It’s always good to have it handy. It will be kept private and only shared with organisers when joining and being accepted to the match. 
LEET also needs it for security reasons.`,

  dontHAveFootbalFriendsSumm:"I don’t have football friends, can I join anyone’s game?",
  dontHAveFootbalFriendsDetail: `Of course! That’s what LEET is all about! Check out the lobby and check out the games you are interested in.
 Request to join and wait for the magic to happen.`,

  canOrganiseOwnGamesSumm: "Can I organise my own games?",
  canOrganiseOwnGamesDetails: `Yes, you can organise your own game too and invite anyone you like to join and play.`,

  cantFindfriendSumm: "I can’t find my friends on LEET.",
  cantFindfriendDetail: `If you cannot find your friends on LEET, don’t worry. You will be prompted to invite them from outside LEET, 
just insert their email address and they will be invited to join LEET.`,

  privateGameSumm:"I want to organise a match but don’t wish everyone to see it.",
  PrivateGameDetail: `Not to worry. You can easily toggle it to be private or public when setting it up.`,

  forgottPasswordSumm: "I have forgotten my password.",
  forgottPasswordDetail: `You haven’t forgotten your password, it was never there to begin with ;)`,

  whyNopasswordSumm: "Why isn’t there a password to login or sign up?",
  whyNopasswordDetail: `We use a magic link system, where when inserting your email address, we send you a unique link to access your account. 
No passwords to remember and highly secure.`,

  onlyForGoalKeeperSumm: "I’m only looking for a goalkeeper, is that possible?",
  onlyForGoalKeeperDetail: `Yes, when setting up your game, there is a list to invite and you can filter out the goalkeepers in the community from everyone.`,

  gotWholeTeamSumm:"I've got a whole group, do I have to invite them each manually?",
  gotWholeTeamDetail: `Well you can, but an easier approach is to create a group on LEET 
and invite all your friends to it. When organising games, organise it as a group and the system automatically 
invites them all to that game. They just need to accept on their end so you know who can make it and who cannot.`,
};

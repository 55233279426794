import React from "react";
import { Flex, Span, Img } from "components/atoms";
import { TEAM_ONE } from "./constants";

// From config:
// teamColors: {
//   lightRed: "#FFF3F3",
//   lightRedLines: "#F70F0F",
//   darkRed: "#E55050",
//   darkRedLines: "#B93030",
//   lightBlue: "#DAE1EC",
//   lightBlueLines: "#5FB7D2",
//   darkBlue: "#488CF1",
//   darkBlueLines: "#006F92",
// },

const TeamPlayerSticker = ({ player, teamNumber, onDoubleClick }) => {
  const stickerPlayerColor =
    teamNumber === TEAM_ONE
      ? "tw-bg-teamColors-darkBlue"
      : "tw-bg-teamColors-darkRed";

  const stickerEmptyColor =
    teamNumber === TEAM_ONE
      ? "tw-bg-teamColors-lightBlue"
      : "tw-bg-teamColors-lightRed";

  const borderPlayerColor =
    teamNumber === TEAM_ONE
      ? "tw-border-teamColors-darkBlueLines"
      : "tw-border-teamColors-darkRedLines";

  const borderEmptyColor =
    teamNumber === TEAM_ONE
      ? "tw-border-teamColors-lightBlueLines"
      : "tw-border-teamColors-lightRedLines";

  const playerName = `${player?.firstName} ${player?.lastName}`.slice(0, 22);
  return (
    <Flex onDoubleClick={() => onDoubleClick(player)}>
      {player ? (
        <Flex
          direction="row"
          className={[
            `tw-my-2 tw-items-center tw-w-36 tw-rounded-2xl ${stickerPlayerColor} tw-border-solid tw-border-2 ${borderPlayerColor}`,
          ]}
        >
          <Img
            src={player.profilePictureUrl || "/img/unselected-player.png"}
            title="mainHeaderLogo"
            alt="Logo"
            className={["tw-h-8 tw-w-8 tw-rounded-2xl tw-mr-2"]}
          />
          <Span size="xxs" className={["tw-px-2 tw-text-left"]}>
            {playerName}
          </Span>
        </Flex>
      ) : (
        <Flex>
          <Flex
            direction="row"
            className={[
              `tw-my-2 tw-w-36 tw-h-8 tw-rounded-2xl ${stickerEmptyColor} tw-border-dashed tw-border-2 ${borderEmptyColor}`,
            ]}
          ></Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default TeamPlayerSticker;

import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { eventDetailById, eventFeedback } from "services/events";
import { texts } from "../../Text/index";
import Header from "containers/header/index";
import { headerVersions } from "../header/constants";
import { Flex, Paragraph } from "components/atoms";
import FeedbackComponent from "../../components/molecules/feedback/feedbackComponent";

const Feedback = (props) => {
  const {
    onEventFeedback,
    eventFeedback,
  } = props; 
  let { eventId } = useParams();
  const [feedbacks, setFeedbacks] = React.useState([]);

  useEffect(() => {
    if (eventId) {
      onEventFeedback({ id: eventId });
    }
  }, []);

  useEffect(() => {
    if (eventFeedback) {
      setFeedbacks(eventFeedback);
    }
  }, [eventFeedback]);

  return (
    <Flex className={["tw-h-full tw-p-4 tw-overflow-auto tw-bg-white md:tw-w-mmd md:tw-m-auto"]}>
      <Header
        headerText={texts.feedbackHeader}
        headerVersion={headerVersions.version3}
      />
      <Flex>
        {(!feedbacks.feedback || feedbacks.feedback.length < 0) && (
          <Paragraph className={["tw-text-center"]} muted={false} size="sm" color="black">
          {texts.noFeedback}
        </Paragraph>
        )}
        
        {feedbacks && feedbacks.length > 0 && feedbacks.map((feedbackObj, idx) => {
          return (
            <FeedbackComponent
              key={idx} //Only for react key constraint.
              text={feedbackObj.feedback}
              feedbackUser={`${feedbackObj.user.firstName} ${feedbackObj.user.lastName}`}
            ></FeedbackComponent>
          )  
        })}
      </Flex>
    </Flex>
  );
};
const mapStateToProps = (state) => {
  return {
    eventFeedback: state?.AuthEventsReducer?.feedbacks?.feedback || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  eventDetailById: (data) => dispatch(eventDetailById(data)),
  onEventFeedback: (data) => dispatch(eventFeedback(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

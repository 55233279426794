import React from "react";
import { Flex, Span, Heading } from "components/atoms";
import { texts } from "Text";

const TeamDetails = (props) => {
  const { name, wins = 0, losses = 0, draws =0 } = props;

  // wins and losses will be implemented at a later stage.

  return (
    <Flex className={["tw-w-2/3 tw-h-full tw-pl-10 tw-overflow-hidden"]}>
      <Heading
        size={true}
        className={["tw-w-full tw-text-center tw-text-xl"]}
        marginBottom={0}
        color="gray"
      >
        {name}
      </Heading>

      <Flex direction="row" className={["tw-justify-between"]}>
        <Flex className={["tw-h-full"]}>
          <Heading
            size={true}
            className={["tw-text-center tw-text-5xl"]}
            marginBottom={0}
            color="green"
          >
            {wins}
          </Heading>
          <Span size="xxs" color="black" className={["tw-pb-2"]}>
            {texts.wins}
          </Span>
        </Flex>
        <Flex className={["tw-h-full"]}>
          <Heading
            size={true}
            className={["tw-text-center tw-text-5xl"]}
            marginBottom={0}
            color="yellow"
          >
            {draws}
          </Heading>
          <Span size="xxs" color="black" className={["tw-pb-2"]}>
            {texts.draws}
          </Span>
        </Flex>
        <Flex className={["tw-h-full"]}>
          <Span>
            <Heading
              size={true}
              className={["tw-text-center tw-text-5xl"]}
              marginBottom={0}
              color="blood"
            >
              {losses}
            </Heading>
          </Span>
          <Span size="xxs" color="black" className={["tw-pb-2"]}>
            {texts.losses}
          </Span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TeamDetails;

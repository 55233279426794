import React from "react";
import { Flex, Img } from "components/atoms";

const Portal = (props) => {
  const {
    children,
    toggle,
    isMainLogoVisible = false,
    isHeaderLogoVisible = true,
    iscloseButtonVisible = true,
    isOpacity = true,
  } = props;
  return (
    <Flex
      className={[
        "tw-items-center tw-w-full tw-h-full tw-p-4 tw-overflow-auto tw-bg-black",
        isOpacity ? "tw-bg-opacity-90" : "",
      ]}
    >
      <Flex
        className={[
          "tw-w-full tw-h-14 tw-items-center tw-mb-4 md:tw-w-mmd md:tw-m-auto",
        ]}
        direction="row"
      >
        {isHeaderLogoVisible && !isMainLogoVisible && (
          <Img src="/img/logo.png" className="tw-w-20 tw-h-auto" />
        )}

        {iscloseButtonVisible && (
          <Img
            onClick={() => toggle()}
            src="/img/close-white.png"
            className={["tw-ml-auto  tw-cursor-pointer"]}
            width={"20"}
            height={"20"}
            title={"close"}
            alt={"close"}
          />
        )}
      </Flex>
      {isMainLogoVisible && (
        <Img src="/img/logo.png" className="tw-w-2/4 tw-h-auto md:tw-w-48" />
      )}
      <Flex
        className={[
          "tw-pt-2",
          "tw-w-full",
          "md:tw-w-mmd md:tw-m-auto",
          { "tw-pt-12": isMainLogoVisible },
        ]}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Portal;

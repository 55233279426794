import React from "react";
import { Button, Img, Flex } from "components/atoms";

import { texts } from "Text";

const ShufflePlayers = ({ shufflePlayers }) => {
  return (
    <Flex  className={["tw-mt-4"]}>
      <Button
        onClick={shufflePlayers}
        octernary={true}
        size="imd"
        className={["tw-my-4"]}
      >
        <Flex
          direction="row"
          className={["tw-w-full tw-justify-between tw-items-center"]}
        >
          <Flex className={["tw-items-center"]}>{texts.shuffle}</Flex>
          <Img
            src="/img/dice.png"
            className={["tw-absolute tw-left-28"]}
            width={72}
            height={72}
          />
          <Flex className={[" tw-items-center"]}>{texts.playersCapital}</Flex>
        </Flex>
      </Button>
    </Flex>
  );
};

export default ShufflePlayers;

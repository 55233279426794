import React from "react";
import { Paragraph, Heading, Flex, Button } from "components/atoms";
import "react-datepicker/dist/react-datepicker.css";
import { texts } from "../../../Text/index";
import { BlackModalTopBar } from "../../molecules";
import Modal from "deprecated/modal";

const HowAreYouJoining = (props) => {
  const { fnCancel, onConfirm, teams, selected, setSelected, onCreateTeam, onJoinTeam, matchDetails, userId } = props;

  const teamOne = matchDetails?.sides[0]?.team;
  const teamTwo = matchDetails?.sides[1]?.team;

  function isPartOfTeam(team) {
    var teamIds = teams.map(team => team.id);
    return teamIds.includes(team.id);
  }

  function isTeamAdmin(team) { 
    return team.teamUsers.filter(user => user.userDetails.id == userId)[0].isAdmin;
  }

  const filteredTeams = teams.filter(team => team.id !== teamOne.id && isTeamAdmin(team));
  
  return (
    <Flex
      className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
    >
      <Modal open={true}>
        <Flex className={["tw-h-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
          <BlackModalTopBar onClick={fnCancel} />
          <Flex className={["tw-px-8 xsm:tw-my-10 "]}>
            <Heading className={["tw-items-center"]}>
              {texts.chooseYourTeamHeader}
            </Heading>
            <Heading>
              Side 1
            </Heading>
            <Button
              quinternary={selected !== teamOne.id}
              hexternary={selected === teamOne.id}
              textColor={!isPartOfTeam(teamOne) ? "mediumGray" : selected === teamOne.id ? "black" : "white"}
              className={["tw-my-1 tw-text-sm tw-border-2 tw-border-white"]}
              onClick={() => {if (isPartOfTeam(teamOne)) setSelected(teamOne.id)}}
            >
              {teamOne.name}
            </Button>
            <Heading className={["tw-mt-4"]}>
              Side 2
            </Heading>
            {teamTwo && (
              <Button
                quinternary={selected !== teamTwo.id}
                hexternary={selected === teamTwo.id}
                textColor={!isPartOfTeam(teamTwo) ? "mediumGray" : selected === teamTwo.id ? "black" : "white"}
                className={["tw-my-1 tw-text-sm tw-border-2 tw-border-white"]}
                onClick={() => {if (isPartOfTeam(teamTwo)) setSelected(teamTwo.id)}}
              >
                {teamTwo.name}
              </Button> 
            )}
            {!teamTwo && (
              <Flex className={["tw-max-h-64 tw-overflow-auto"]}>
                <Paragraph>Select an opposing team:</Paragraph>
              {filteredTeams &&
                filteredTeams.length > 0 &&
                filteredTeams.map((team) => {
                  return (
                    <Button
                      quinternary={selected !== team.id}
                      hexternary={selected === team.id}
                      textColor={selected === team.id ? "black" : "white"}
                      className={["tw-my-1 tw-text-sm tw-border-2 tw-border-white tw-mb-2"]}
                      onClick={() => setSelected(team.id)}
                    >
                      {team.name}
                    </Button>
                  );
                })}
                {!filteredTeams ||
                  filteredTeams.length == 0 && (
                    <Flex className={["tw-mt-2"]}>
                      <Paragraph className={["tw-italic tw-text-grey"]}>Only team admins can challenge other teams.</Paragraph>
                      <Paragraph className={["tw-italic tw-text-grey"]}>You currently don't administer any teams.</Paragraph>
                    </Flex>
                  )
                }
              </Flex>
            )}
            <Button 
              className={["tw-w-full tw-my-8"]}
              disabled={selected == null}
              onClick={onConfirm}>
                {texts.joinAndRsvp}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default HowAreYouJoining;

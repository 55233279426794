export const flowSteps = {
  step0: 0,
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
  step5: 5,
  step6: 6,
  step7: 7,
  step8: 8,
  step9: 9,
  step10: 10,
};

export const matchTypeOptions = {
  findAPlayer: 1,
  organiseAMatch: 2,
  challengeATeam: 3,
};

export const playerLevels = {
  beginner: "beginner",
  intermediate: "intermediate",
  advanced: "advanced",
  pro: "pro",
}

export const playerTypes = {
  goalKeeper: "GoalKeeper",
  player: "Player",
  both: "Both",
}

export const playerStatus = {
  NOT_FRIENDS: "NotFriends",
  REQUEST_SENT: "RequestSent",
  REQUEST_RECIVED: "RequestReceived",
  FRIENDS: "Friends",
  ADDED_FROM_FACEBOOK: "AddedFromFacebook",
  PLAYED_TOGETHER: "PlayedTogether",
};

export const requestFriendStatus = {
  REQUEST_SENT: "Requested",
  FRIENDS: "Accepted",
  ADDED_FROM_FACEBOOK: "AddedFromFacebook",
};

export const userEventStatus = {
  NOT_PLAYING: "NotPlaying",
  INVITED: "Invited",
  REQUESTED_TO_JOIN: "RequestedToJoin",
  JOINED: "Joined",
};

export const USER_STATUS_REDUCE = {
  JOIN: "JOIN",
  CANCEL: "CANCEL",
  ACCEPT: "ACCEPT",
};

export const MAX_FEEDBACK_LENGTH = 249;

export const teamSides = {
  sideOne: "Side1",
  sideTwo: "Side2",
};

export const HEADER_SIZES = {
  MINIMISED_LOGGED_IN: 220,
  MINIMISED_LOGGED_OUT: 60,
  NORMAL_LOGGED_IN: 280,
  NORMAL_LOGGED_OUT: 230,
};

export const IOS_BOTTOM_NAVIGATOR_SIZE = 80;
export const DEFAULT_BOTTOM_NAVIGATOR_SIZE = 40;

export const userJoinType = {
  ALONE: "alone",
};

export const teamUserStatus = {
  joined: "Joined",
  requestedToJoin: "RequestedToJoin",
};

import { combineReducers } from "redux";
import { TokenReducer } from "./token/reducer";
import { AuthEventsReducer } from "./events/auth/reducer";
import { UnauthEventsReducer } from "./events/unAuth/reducer";
import { ChatReducer } from "./chat/reducer";
import { FriendListReducer } from "./friends/reducer";
import { MyTeamsReducer, AllTeamsReducer } from "./teams/reducer";
import { UserReducer } from "./users/reducer";
import { MyProfileReducer } from "./myProfile/reducer";
import { LocationsReducer } from "./locations/reducer";
import { ModalReducer } from "./modal/reducer";

export default combineReducers({
  // New reducers.
  ChatReducer,
  TokenReducer,
  ModalReducer,
  LocationsReducer,
  AuthEventsReducer,
  UnauthEventsReducer,
  MyProfileReducer,
  FriendListReducer,
  UserReducer,
  MyTeamsReducer,
  AllTeamsReducer,
});

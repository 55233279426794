import React from "react";
import { Flex, Span } from "components/atoms";
import TeamColumn from "./teamColumn";
import { texts } from "Text";
import { TEAM_ONE, TEAM_TWO } from "./constants";

const EventTeamsComponent = ({ maxNumberOfPlayers, teamOne, teamTwo, onDoubleClick }) => {
  const maxNumberOfTeam = (teamOne != null && teamTwo != null && maxNumberOfPlayers > 0) 
    ? Math.max(Math.round(maxNumberOfPlayers / 2), teamOne.length, teamTwo.length)
    : Math.round(maxNumberOfPlayers / 2);

  return (
    <Flex direction="row" className={["tw-mt-4"]}>
      <Flex className={["tw-w-1/2"]}>
        <Span color="black">{texts.teamOne}</Span>
        {TeamColumn(teamOne, maxNumberOfTeam, TEAM_ONE, onDoubleClick)}
      </Flex>
      <Flex className={["tw-w-1/2 tw-pl-4"]}>
        <Span color="black">{texts.teamTwo}</Span>
        {TeamColumn(teamTwo, maxNumberOfTeam, TEAM_TWO, onDoubleClick)}
      </Flex>
    </Flex>
  );
};

export default EventTeamsComponent;

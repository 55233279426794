import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Heading, Flex, Input } from "components/atoms";
import { texts } from "../../../Text/index";
import { flowSteps as createTeamSteps } from "../../../containers/constants";
import {
  BlackModalTopBar,
  SteppButtons,
  UploadImg,
  ErrorInfo,
} from "../../molecules";

const GiveYourTeamAvatarV2 = (props) => {
  const {
    imagePreview,
    setImagePreview,
    setError,
    clearErrors,
    error,
    backToTeamPage,
    setFile,
    teamName,
    setTeamName,
    fnBack,
    fnForward,
    searchTeam,
  } = props;
  return (
    <Flex
        className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
      >
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 md:tw-w-mmd md:tw-m-auto"]}>
          <BlackModalTopBar
            onClick={backToTeamPage}
            stepNumber={createTeamSteps.step1 + "/" + createTeamSteps.step2}
          />
          <Flex className={["tw-px-4 xsm:tw-px-8 tw-py-8"]}>
            <Flex direction="row" className={[]}>
              <UploadImg
                name="profilePictureUrl"
                setError={setError}
                clearErrors={clearErrors}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
                setFile={setFile}
              />
              <Heading className={["tw-items-center"]}>
                {texts.addTeamAvatar}
              </Heading>
            </Flex>
            <Paragraph className={["tw-w-full"]} muted={false} size="xs">
              {texts.uploadASquareImage}
            </Paragraph>

            <Paragraph
              className={["tw-w-full tw-pt-12"]}
              noMargin={true}
              muted={false}
              size="xs"
            >
              {texts.addTeamNameV2}
            </Paragraph>
            <Paragraph className={["tw-w-full"]} muted={false} size="xs">
              {texts.thirtyCharMax}
            </Paragraph>
            <Input
              placeholder={texts.insertTeamNamePlaceholder}
              onChange={(e) => {
                setTeamName(e.target.value);
                searchTeam(e.target.value);
              }}
              value={teamName}
            ></Input>
            {error && <ErrorInfo error={error} />}
            <SteppButtons
              fwdText={texts.next}
              fnBack={fnBack}
              fnForward={fnForward}
            ></SteppButtons>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
    </Flex>
  );
};

export default GiveYourTeamAvatarV2;

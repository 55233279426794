import * as Actions from "combineReducer/events/auth/actions";
import { fetchGet, fetchGet2, fetchPost, fetchPut, fetchDelete } from "services";

export const createGame = (data) => (dispatch) =>
  fetchPost("events", {
    data,
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Game created",
      };
      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        return {
          success: false,
          message: error.message || "Error in create event API.",
        };
      }
    });

export const editGame = (data) => (dispatch) =>
  fetchPut("events", { data })
    .then((response) => {
      response = {
        success: true,
        data: response,
        message: "Event updated successfuly!",
      };

      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User", success: false };
      } else {
        dispatch(
          Actions.eventDetail({
            message: error.message || "Error in edit event API.",
            success: false,
            data: {},
          })
        );

        return {
          message: error.message || "Error in edit event API.",
          success: false,
        };
      }
    });

export const authGameEvents =
  (
    data = {
      includePastEvents: true,
      pageNum: "1",
      pageSize: "20",
      setUpdate: false,
    }
  ) =>
  (dispatch) =>
    fetchGet2("events/all", { data })
      .then((response = {}) => {
        response = {
          success: true,
          data: response.eventUserResponses,
          message: "",
        };
        if (data?.setUpdate) {
          dispatch(Actions.getEvents(response));
          return response;
        }

        response.data?.length > 0
          ? dispatch(Actions.getAllEvents(response))
          : dispatch(Actions.stopLoadEvents({ data: true }));
        return response;
      })
      .catch((error) => {
        if (error.status === 401) {
          return { message: "Unauthorized User" };
        } else {
          dispatch(
            Actions.getAllEvents({
              success: false,
              data: [],
              message: error.message || "Error in get authGame events API",
            })
          );
          return {
            message: error.message || "Error in get authGame events API",
          };
        }
      });

export const eventDetailById = (id) => (dispatch) =>
  fetchGet("events/" + id)
    .then((response) => {
      response = {
        success: true,
        data: response,
        message: "",
      };
      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        dispatch(
          Actions.eventDetail({
            success: false,
            data: {},
            message: error.message || "Error in get event detail",
          })
        );
        return {
          message: error.message || "Error in get event detail",
        };
      }
    });

export const deleteEventById = (id) => (dispatch) =>
  fetchDelete("events/" + id)
    .then((response) => {
      response = {
        success: true,
        data: {},
        message: "You have successfully deleted the event!",
      };
      dispatch(Actions.deleteEvent(id));
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          message: "Error in delete event detail.",
        };
        return {
          response,
        };
      }
    });

export const eventFeedback = (data) => (dispatch) => {
  const { id } = data;
  return fetchGet(`events/${id}/feedback`)
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
      };
      dispatch(Actions.addFeedback(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        return {
          success: false,
          message: error.message || "Error in add event feedback API.",
        };
      }
    });
};

export const postEventFeedback = (data) => (dispatch) => {
  const { id, feedback } = data;
  return fetchPost(`events/${id}/feedback`, {
    data: { feedback },
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
      };
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        return {
          success: false,
          message: error.message || "Error in add event feedback API.",
        };
      }
    });
};

export const myEvents =
  ({ isJoined, pageNum = 1, pageSize = 20 }) =>
  (dispatch) =>
    fetchGet("events/me", { data: { filter: isJoined, pageNum, pageSize } })
      .then((response) => {
        response = {
          success: true,
          data: response.eventUserResponses,
          message: "",
        };
        response?.data?.length > 0
          ? dispatch(Actions.getMyEvents(response))
          : dispatch(Actions.stopLoadEvents());
      })
      .catch((error) => {
        if (error && error.status === 401) {
        } else {
          dispatch(
            Actions.getMyEvents({
              success: false,
              data: [],
              message: error.message || "Error in getting events APi",
            })
          );
        }
      });

import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, TeamCard } from "../../molecules";

const SimilarTeamFound = (props) => {
  const { teams, fnBack, doneRegistration, joinTeam } = props;
  return (
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 md:tw-w-mmd md:tw-m-auto"]}>
          <BlackModalTopBar onClick={fnBack} />
          <Flex className={["tw-h-48 tw-px-12"]}>
            <Heading className={[" tw-items-center"]}>
              {texts.similarTeamFound}
            </Heading>
            <Paragraph className={["tw-w-full"]} muted={false} size="xs">
              {texts.isThisYourTeam}
            </Paragraph>

            <Flex className={["tw-h-48 tw-mb-4 tw-overflow-auto"]}>
              {teams &&
                teams.map((team) => {
                  return (
                    <TeamCard
                      onClick={() => joinTeam(team ?.id)}
                      team={team}
                      isJoin={true}
                    ></TeamCard>
                  );
                })}
            </Flex>
            <Button
              secondary={true}
              textColor={"white"}
              rounded="xl"
              className={["tw-my-2 tw-text-sm"]}
              onClick={fnBack}
            >
              {texts.notMyTeam}
            </Button>
            <Button
              textColor={"white"}
              rounded="xl"
              className={["tw-my-2 tw-text-sm"]}
              onClick={doneRegistration}
            >
              {texts.doneRegistration}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SimilarTeamFound;

import React from "react";
import {
  Paragraph,
  Input,
  Heading,
  Flex,
  Checkbox,
  Button,
} from "components/atoms";
import Modal from "deprecated/modal";
import { texts } from "../../../Text/index";
import { flowSteps as createTeamSteps } from "../../../containers/constants";
import { BlackModalTopBar, ErrorInfo } from "../../molecules";

const CreateTeams1 = (props) => {
  const {
    setCreateTeamsStepWithValidation,
    teamName,
    setTeamName,
    error,
    backToTeamPage,
  } = props;
  return (
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black md:tw-w-mmd md:tw-m-auto"]}>
        <BlackModalTopBar
          onClick={backToTeamPage}
          stepNumber={createTeamSteps.step1 + "/" + createTeamSteps.step2}
        />
        <Flex className={["tw-w-full tw-px-12 tw-py-16"]}>
          <Heading className={["tw-w-full tw-items-center"]}>
            {texts.addTeamName}
          </Heading>
          <Paragraph className={["tw-w-36"]} muted={false} size="xs">
            {texts.enterFriendlyName}
          </Paragraph>
          <Input
            placeholder={texts.groupName}
            className={["tw-mb-4"]}
            maxLength={30}
            onChange={(event) => setTeamName(event?.target?.value)}
            value={teamName ? teamName : ""}
          />
          {error && <ErrorInfo error={error} />}
          <Button
            onClick={() =>
              setCreateTeamsStepWithValidation(createTeamSteps.step2)
            }
            className={["tw-w-full"]}
          >
            {texts.next}
          </Button>
        </Flex>
      </Flex>
    </Flex>
    </Modal>
  );
};

export default CreateTeams1;

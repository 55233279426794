import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getCookie } from "services/cookies";
import { friendListApi } from "services/users";
import { DEFAULT_USER_AMOUNT } from "services/constants";

import { toast } from "react-toastify";
import { validateEmail } from "../../utils/helper";
import Header from "containers/header";
import { Flex } from "components/atoms";
import FindPlayerPage from "components/pages/findPlayers";
import { headerVersions } from "containers/header/constants";
import { texts } from "../../Text";

function FindPlayer(props) {
  const { suggestedUserReducer, tokenReducer } = props;

  const [usersList, setUsersList] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [pageNum, setPageNum] = React.useState(1);
  const [isLoader, setIsLoader] = React.useState(false);

  React.useEffect(() => {
    setUsersList(suggestedUserReducer);
  }, [suggestedUserReducer]);

  const gotoPlayerProfile = (user) => {
    if (user) {
      props.history.push(`/player-profile/${user.id}`);
    } else {
      toast.error(texts.userNotFound);
    }
  };

  // At a later sagge might be necessary.
  const handleScroll = (e) => {
    const { loadmoreUsers } = props;
    if (
      e &&
      !isLoader &&
      !loadmoreUsers &&
      e.target.scrollTop + 1 + e.target.clientHeight >= e.target.scrollHeight
    ) {
      //  Currnetly it's not working on BE. Enable it when it's available.
      //  setPageNum(pageNum + 1);
    }
  };

  React.useEffect(() => {
    dispatch(
      friendListApi({
        pageNum,
        pageSize: DEFAULT_USER_AMOUNT,
      })
    );
  }, [pageNum]);

  const dispatch = useDispatch();

  const goToChat = (user) => {
    if (user && user.id) {
      props.history.push(`/chat/${user.id}`);
    } else {
      toast.error(texts.userNotFound);
    }
  };

  React.useEffect(() => {
    //We can check if we are logged in with checking if we either have the token from FbAuth
    //or if we have leetUserToken cookie from email auth.
    const cookie = getCookie("leetUserToken") || tokenReducer?.token;
    if (!cookie) {
      return;
    }
    dispatch(friendListApi());
  }, []);

  return (
    <Flex>
      <Header
        headerVersion={headerVersions.version4}
        isSearchPlayerType={true}
        placeholder={texts.searchPlayers}
      />
      <FindPlayerPage
        goToProfile={gotoPlayerProfile}
        usersList={usersList}
        goToChat={goToChat}
        handleScroll={handleScroll}
      />
    </Flex>
  );
}
const mapStateToProps = (state) => ({
  suggestedUserReducer: state.UserReducer?.usersList,
  tokenReducer: state.TokenReducer,
  loadmoreUsers: state.UserReducer?.stopLoadFriends,
});

export default connect(mapStateToProps)(FindPlayer);

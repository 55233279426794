import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { eventDetailById, postEventFeedback, editGame } from "services/events";
import { Flex } from "components/atoms";
import PostMatchPage from "components/pages/postMatch";
import { myProfileApi } from "services/profile";
import { toast } from "react-toastify";
import { texts } from "Text";
import { teamSides, userEventStatus } from "../constants";
import { isTeamVSteam } from "../../utils/helper";
import { eventUserSides } from "services/eventUser";

const PostMatch = (props) => {
  const {
    myProfile,
    eventDetailById,
    fetchProfile,
    onSendFeedback,
    onEditGame,
    onEventUserSides,
  } = props;
  let event = useSelector((state) => state?.AuthEventsReducer?.event || {});
  let { eventId } = useParams();
  const history = useHistory();

  const [players, setPlayers] = useState([]);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [maxNumberOfPlayers, setMaxNumberOfPlayers] = useState([]);
  const [teamOneResult, setTeamOneResult] = useState("");
  const [teamTwoResult, setTeamtwoResult] = useState("");
  const [feedback, setFeedback] = useState("");
  const [organiserName, setOrganiserName] = useState(false);
  const [isEventPlayer, setIsEventPlayer] = useState(false);

  useEffect(() => {
    const organiser = event?.eventResponse?.users?.find(
      (user) => user.isOrganizer === true
    );
    setOrganiserName(`${organiser?.firstName} ${organiser?.lastName}`);
    const player = event?.eventResponse?.users?.find(
      (user) =>
        user?.id === myProfile?.id && user?.status === userEventStatus.JOINED
    );
    if (player) {
      setIsEventPlayer(true);
    }
  }, [event]);

  useEffect(() => {
    eventDetailById(eventId);

    if (!myProfile) {
      fetchProfile();
    }
    const id = event?.eventResponse?.id;
    if (id) {
      const eventResponse = event?.eventResponse;
      setMaxNumberOfPlayers(eventResponse?.maxPlayers);
      setIsOrganizer(event?.isOrganizer);
      setPlayers(eventResponse?.users);
      const sideOneResult = eventResponse?.sides?.find(
        (event) => event.side === teamSides.sideOne
      );
      const sideTwoResult = eventResponse?.sides?.find(
        (event) => event.side === teamSides.sideTwo
      );
      setTeamOneResult(sideOneResult?.score?.toString());
      setTeamtwoResult(sideTwoResult?.score?.toString());
    }
  }, [myProfile]);

  useEffect(() => {
    saveTeam();
  }, [players]);

  const checkFeedback = () => {
    history.push(`/event/feedbacks/${eventId}`);
  };

  const goToMatchDetails = () => {
    history.push(`/event/${eventId}`);
  };

  const onFeedback = (text) => {
    setFeedback(text);
  };

  const onTeamMemberDoubleClick = (player) => {
    if (player.side === teamSides.sideOne) {
      player.side = teamSides.sideTwo;
    } else {
      player.side = teamSides.sideOne;
    }
    setPlayers([player, ...players.filter(user => user.id != player.id)]);
  }

  const saveTeam = () => {
    if (players && players.length > 0) {
      let teamData = {};

      const eventUsersSides = players.map((player) => {
        return {
          userId: player.id,
          side: player.side,
        };
      });
      teamData.eventId = eventId;
      teamData.sides = { eventUsersSides };
      onEventUserSides(teamData).then((response) => {
        if (response.success) {
          //toast.success(texts.teamSavedSuccessfully);
        } else {
          toast.error(texts.genericError);
        }
      });
    }
  };

  const sendFeedback = () => {
    const data = { id: eventId, feedback };
    onSendFeedback(data);
    document.querySelector('.textarea-feedback').value = '';
    toast.success(texts.feedbackSubmitted);
  };

  function isPositiveNumber(str) {
    if (typeof str !== 'string') 
      return false;
  
    if (str.trim() === '') 
      return false;
  
    if (Number.isNaN(Number(str)))
      return false;

    return Number(str) >= 0;
  }

  const sendResults = () => {
    if (!eventId) {
      return;
    }
    if (!isPositiveNumber(teamOneResult) || !isPositiveNumber(teamTwoResult)) {
      toast.error(texts.setValidResult);
      return;
    }
    const putData = {
      id: eventId,
      name: event?.eventResponse?.name,
      dateTime: event?.eventResponse?.dateTime,
      locationId: event?.eventResponse?.locationId,
      isPlaying: event?.eventResponse?.isPlaying,
      maxPlayers: event?.eventResponse?.maxPlayers,
      reservedPlayers: event?.eventResponse?.reservedPlayers,
      description: event?.eventResponse?.description,
      private: event?.eventResponse?.private,
      scoreSide1: Number(teamOneResult),
      scoreSide2: Number(teamTwoResult),
    };

    onEditGame(putData).then((response) => {
      if (response?.success) {
        toast.success(texts.reultsUpdated);
      } else {
        toast.error(texts.genericError);
      }
    });
  };

  const setTeamPlayers = (teamNumber) => {
    const sides = {
      1: "sideOne",
      2: "sideTwo",
    };
    return event?.eventResponse?.users?.filter(
      (user) => user?.status === "Joined" && user?.side === teamSides[sides[teamNumber]]
    );
  };

  return (
    <Flex className={["tw-h-full tw-w-full tw-bg-white md:tw-w-mmd md:tw-m-auto"]}>
      <PostMatchPage
        isOrganiser={isOrganizer}
        checkFeedback={checkFeedback}
        goToMatchDetails={goToMatchDetails}
        maxNumberOfPlayers={maxNumberOfPlayers}
        teamOnePlayers={setTeamPlayers(1)}
        teamTwoPlayers={setTeamPlayers(2)}
        isTeamVSteam={isTeamVSteam(event?.eventResponse)}
        setTeamOneResult={setTeamOneResult}
        setTeamtwoResult={setTeamtwoResult}
        teamOneResult={teamOneResult}
        teamTwoResult={teamTwoResult}
        onFeedback={onFeedback}
        sendFeedback={sendFeedback}
        sendResults={sendResults}
        matchDetails={event?.eventResponse}
        organiserName={organiserName}
        isEventPlayer={isEventPlayer}
        onTeamMemberDoubleClick={onTeamMemberDoubleClick}
      />
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    myProfile: state.MyProfileReducer.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  eventDetailById: (data) => dispatch(eventDetailById(data)),
  fetchProfile: () => dispatch(myProfileApi()),
  onSendFeedback: (data) => dispatch(postEventFeedback(data)),
  onEditGame: (data) => dispatch(editGame(data)),
  onEventUserSides: (data) => dispatch(eventUserSides(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostMatch);

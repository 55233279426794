import React from "react";
import LogoCloseTopBar from "../../components/molecules/general/logoCloseTopBar";
import { Flex, Button, Heading, Paragraph } from "components/atoms";
import { texts } from "../../Text/index";
import { termsOfUse } from "../../Text/termsOfUse";

const TermsOfUse = (props) => {
  const { setTermsAndCondition } = props;

  return (
    <Flex className={["tw-h-full tw-w-full tw-bg-black "]}>
      <LogoCloseTopBar onClick={() => setTermsAndCondition(false)} />
      <Flex className={["tw-h-5/6 tw-bg-black tw-p-4 tw-overflow-auto"]}>
        <Heading className={[""]}>{termsOfUse.pageTitle}</Heading>
        <Paragraph size={"base"}>{termsOfUse.subjectTotermsTitle}</Paragraph>
        <Paragraph size={"xs"}>{termsOfUse.subjectTotermsContent}</Paragraph>
        <Paragraph size={"base"}>
          {termsOfUse.acknowledgementOfBetaTitle}
        </Paragraph>
        <Paragraph size={"xs"}>
          {termsOfUse.acknowledgementOfBetaContent}
        </Paragraph>
        <Paragraph size={"base"}>{termsOfUse.IndemnityTitle}</Paragraph>
        <Paragraph size={"xs"}>{termsOfUse.IndemnityContent}</Paragraph>
        <Paragraph size={"base"}>{termsOfUse.FeedBackTitle}</Paragraph>
        <Paragraph size={"xs"}>{termsOfUse.FeedBackContent}</Paragraph>
        <Paragraph size={"base"}>{termsOfUse.privacyPolicyTitle}</Paragraph>
        <Paragraph size={"xs"}>{termsOfUse.privacyPolicyContent}</Paragraph>
        <Paragraph size={"base"}>{termsOfUse.disputeResolutionTitle}</Paragraph>
        <Paragraph size={"xs"}>{termsOfUse.disputeResolutionContent}</Paragraph>
        <Paragraph size={"base"}>{termsOfUse.delitionUserDataTitle}</Paragraph>
        <Paragraph size={"xs"}>{termsOfUse.delitionUserDataContent}</Paragraph>
        <Flex direction="row" className={["tw-my-8 tw-justify-between"]}>
          <Button
            onClick={() => setTermsAndCondition(true)}
            rounded="2xl"
            size="base"
            className={["tw-w-45"]}
          >
            {texts.accept}
          </Button>
          <Button
            onClick={() => setTermsAndCondition(false)}
            rounded="2xl"
            size="base"
            className={["tw-w-45"]}
            secondary
          >
            {texts.decline}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TermsOfUse;

import React from "react";
import { RadioButton, Flex } from "components/atoms";

const RadioButtons = (props) => {
  const { onChange, name, buttons, className = ["tw-text-sm"] } = props;
  return (
    <Flex
      direction="row"
      className={["tw-bg-transparent tw-items-center tw-justify-center"]}
    >
      {buttons.map((button) => {
        return (
          <RadioButton
            name={name}
            id={button.id}
            className={className}
            value={button.id}
            label={button.name}
            defaultChecked={button.defaultChecked}
            onChange={() => onChange(button.id)}
          />
        );
      })}
    </Flex>
  );
};

export default RadioButtons;

import React from "react";
import moment from "moment";
import { Flex, Span, Button, Img, Thumbnail } from "components/atoms";
import TeamVSteamCore from "../general/teamVSteamCore";
import { texts } from "Text";

const TeamVSteamCard = (props) => {
  const { data, showEvent, key, smallCard } = props;

  const teamOne = data?.sides[0]?.team;
  const teamTwo = data?.sides[1]?.team;

  const date = moment.utc(data.dateTime).local();

  const opacity = date < Date.now() ? "tw-opacity-50" : "tw-opacity-100";

  const joinedPlayers = data?.users?.filter((u) => u.status === "Joined");
  const availableSpaces = data?.maxPlayers - joinedPlayers.length || 0;

  return (
    <Flex
      className={["tw-w-full", "tw-overflow-hidden", "tw-py-2", `${opacity}`]}
      id={key}
      onClick={() => showEvent(data.id)}
    >
      <TeamVSteamCore
        teamOne={teamOne}
        teamTwo={teamTwo}
        smallCard={smallCard}
      />
      <Flex
        direction="row"
        className={["tw-flex-wrap tw-bg-grey-light tw-pt-2 tw-justify-center"]}
      >
        {joinedPlayers.map(({ profilePictureUrl, status, id }) => (
          <Thumbnail
            src={profilePictureUrl || "/img/unselected-player.png"}
            isIncognito={status.toLowerCase() === "invited"}
            className={["tw-w-6 tw-h-6"]}
            isResize={true}
          />
        ))}
        {[...Array(availableSpaces).keys()].map(() => (
          <Thumbnail className={["tw-w-6 tw-h-6"]} isResize={true} />
        ))}
      </Flex>
      <Flex
        direction="row"
        className={[
          "tw-w-full tw-h-12 tw-bg-grey tw-rounded-b-2xl tw-p-2 tw-justify-between tw-items-center",
        ]}
      >
        <Span
          size="xs"
          color={true}
          className={[
            "tw-text-black tw-px-4 tw-py-1 tw-ml-1 tw-bg-grey-light tw-rounded-2xl",
          ]}
        >
          {date?.format("DD")} {date?.format("MMM").toUpperCase()}
        </Span>
        {teamTwo ? (
          <Flex
            direction="row"
            className={["tw-text-black tw-justify-center tw-items-center"]}
          >
            <Img
              src="/img/location.svg"
              width={9}
              height={13}
              className={["tw-w-4", "tw-mr-2"]}
            />
            <Span size="xxs">{`${data?.location?.name} ${texts.footbalGround}`}</Span>
          </Flex>
        ) : (
          <Button size="sm" ninetenary onClick={() => showEvent(data.id)} rounded="2xl">
            {texts.challengeThem}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default TeamVSteamCard;

import * as Actions from "combineReducer/events/auth/actions";
import { fetchPost, fetchPut, fetchDelete } from "services";

export const eventRequestAccept = (data) => (dispatch) =>
  fetchPut("eventuser/acceptRequest", { data })
    .then((response) => {
      response = {
        success: true,
        data: response,
        message: "User’s request to join the game has been accepted!",
      };
      dispatch(Actions.eventDetail(response));

      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in eventRequestAccept API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

    export const eventAcceptInviteAsTeam = (data) => (dispatch) =>
    fetchPut("eventuser/acceptInviteAsTeam", { data })
      .then((response) => {
        response = {
          success: true,
          data: response,
          message: "User’s request to join the game has been accepted!",
        };
        dispatch(Actions.eventDetail(response));
  
        return response;
      })
      .catch((error) => {
        if (error.status === 401) {
          return { message: "Unauthorized User" };
        } else {
          const response = {
            success: false,
            data: {},
            message: error.message || "Error in eventAcceptInvite API.",
          };
          dispatch(Actions.eventDetail(response));
  
          return response;
        }
      });

export const eventAcceptInvite = (data) => (dispatch) =>
  fetchPut("eventuser/acceptInvite", { data })
    .then((response) => {
      response = {
        success: true,
        data: response,
        message: "User’s request to join the game has been accepted!",
      };
      dispatch(Actions.eventDetail(response));

      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in eventAcceptInvite API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

export const eventRequestLeave = (eventId) => (dispatch) =>
  fetchDelete("eventuser/leave", { data: { eventId } })
    .then((response) => {
      response = {
        success: true,
        message: "You have left this event",
      };

      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,

          message: error.message || "Error in eventRequestLeave API.",
        };
        return response;
      }
    });

export const eventRequestRemove =
  ({ eventId, userId }) =>
  (dispatch) =>
    fetchDelete("eventuser/remove", { data: { eventId, userId } })
      .then((response) => {
        response = {
          success: true,
          data: response,
          message: "User’s request to join this game has been rejected",
        };

        return response;
      })
      .catch((error) => {
        if (error.status === 401) {
          return { message: "Unauthorized User" };
        } else {
          const response = {
            success: false,
            data: error,
            message: error.message || "Error in eventRequestRemove API.",
          };

          return response;
        }
      });

export const eventJoin = (data) => (dispatch) =>
  fetchPost("eventuser/join", { data })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Your request to join this game has been sent.",
      };
      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in eventJoin API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

export const inviteUser = (data) => (dispatch) =>
  fetchPost("eventuser/invite", { data })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Invitation to join sent!.",
      };
      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in inviteUser API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

export const eventInviteTeam = (data) => (dispatch) =>
  fetchPost("eventuser/invite/team", {
    data,
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Team added!",
      };
      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in eventInviteTeam API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

export const eventUserSides = (data) => (dispatch) =>
  fetchPut(`eventuser/${data?.eventId}/sides`, { data: data?.sides })
    .then((response) => {
      response = {
        success: true,
        data: response,
        message: "Team sides updated!",
      };

      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in eventUserSides API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

export const eventJoinWithTeam = (data) => (dispatch) =>
  fetchPost("eventuser/joinAsTeam", { data })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Your request to join this game has been sent.",
      };
      dispatch(Actions.eventDetail(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        const response = {
          success: false,
          data: {},
          message: error.message || "Error in eventJoin API.",
        };
        dispatch(Actions.eventDetail(response));

        return response;
      }
    });

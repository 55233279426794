export const GET_MY_TEAMS = "GET_MY_TEAMS";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const ADD_NEW_TEAM = "ADD_NEW_TEAM";
export const ADD_NEW_TEAM_BY_ID = "ADD_NEW_TEAM_BY_ID";
export const DELETE_TEAM = "DELETE_TEAM";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const ADD_ADMIN_USER = "REMOVE_TEAM_MEMBER";
export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const ADD_TEAM_AVATAR = "ADD_TEAM_AVATAR";

export function getMyTeams(data) {
  return {
    type: GET_MY_TEAMS,
    payload: data,
  };
}

export function updateTeam(data) {
  return {
    type: UPDATE_TEAM,
    payload: data,
  };
}

export function addTeamAvatar(data) {
  return {
    type: ADD_TEAM_AVATAR,
    payload: data,
  };
}

export function addNewTeam(data) {
  return {
    type: ADD_NEW_TEAM,
    payload: data,
  };
}

//This goes to allteams.
export function addNewTeamById(data) {
  return {
    type: ADD_NEW_TEAM_BY_ID,
    payload: data,
  };
}

export function deleteTeam(data) {
  return {
    type: DELETE_TEAM,
    payload: data,
  };
}

export function addAdminUser(data) {
  return {
    type: ADD_ADMIN_USER,
    payload: data,
  };
}

export function getAllTeams(data) {
  return {
    type: GET_ALL_TEAMS,
    payload: data,
  };
}

import * as TokenActions from "combineReducer/token/actions";
import * as ProfileActions from "combineReducer/myProfile/actions";
import { toast } from "react-toastify";

import { setCookie } from "services/cookies";
import { fetchGet, fetchPost, fetchRefresh } from "services";

export const forgotPassword = (data) => (dispatch) =>
  fetchPost("auth/forgotPassword", { data })
    .then((response = {}) => {
      return {
        ...response,
        success: true,
        message: "We have sent you an email !",
      };
    })
    .catch((error) => {
      const message = error.message || "Please verify your email address";
      return {
        message,
        success: false,
      };
    });

export const resetPasswordForm = (data) => (dispatch) =>
  fetchPost("auth/resetPassword", { data })
    .then((response = {}) => {
      return {
        ...response,
        success: true,
        message: "Password updated successfuly !",
      };
    })
    .catch((error) => {
      const message = error.message || "Error in reset password API";
      return {
        message,
        success: false,
      };
    });

export const fbOauth = (code) => (dispatch) =>
  fetchGet("auth/signin-facebook", { data: { code } })
    .then((response) => {
      setCookie("leetUserProfile", JSON.stringify(response.userDetails));
      setCookie(
        "leetUserToken",
        response.token.accessToken,
        response.token.expiresInMinutes
      );
      setCookie("refreshToken", response.token.refreshToken);
      dispatch(TokenActions.setUserToken(response));
      return response;
    })
    .catch((error) => {
      const message = error.message || "Error in facebook login";
      const status = error.status || "400";
      dispatch(TokenActions.setUserToken({}));
      return {
        message,
        status,
      };
    });

export const confirmEmail = (data) => (dispatch) =>
  fetchPost("auth/confirmEmail", { data })
    .then((response = {}) => {
      return {
        ...response,
        success: true,
        message: "Your email has been successfully confirmed.",
      };
    })
    .catch((error) => {
      const message = error.message || "Error in confirm password API";
      return {
        message,
        success: false,
      };
    });

export const refreshToken = (data) =>
  fetchRefresh("auth/refresh-token", { data });

// New Login flow endpoints
export const magicLink = (data) => () =>
  fetchPost("auth/magic-link", { data })
    .then((response = {}) => {
      const message = "Check your Mailbox";
      return { ...response, success: true, message };
    })
    .catch((error) => {
      const message = error.message || "Invalid Email";
      toast.error(message);
      return {
        message,
        success: false,
      };
    });

export const magicLinkConfirmEmail = (data) => () =>
  fetchPost("auth/magic-link-confirm", { data })
    .then((response = {}) => {
      if (!response.token) {
        setCookie("leetCreateAccountToken", response?.createAccountToken);
        return { createAccount: true };
      }
      //Set cookies.
      setCookie(
        "leetUserToken",
        response?.token?.accessToken,
        response?.token?.expiresInMinutes
      );
      setCookie("refreshToken", response.token.refreshToken);
      //Even though the response does not contain userdata we have to create
      //this cookie to be look as authenticated.
      setCookie("leetUserProfile", JSON.stringify(response));
      //Set toast notification about success.
      const message = "You logged in successfully!";
      toast.success(message);
      return { ...response, success: true, message };
    })
    .catch((error) => {
      const message = error.message || "Authentication failed";
      toast.error(message);
      return {
        message,
        success: false,
      };
    });

export const createAccount = (data) => (dispatch) =>
  fetchPost("auth/create-account", {
    data,
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Account successfully created.",
      };
      dispatch(
        ProfileActions.myProfile({
          data: response.data.userDetails,
        })
      );
      toast.success(response?.message);
      if (response.data.token) {
        const { accessToken, expiresInMinutes, refreshToken } =
          response.data.token;
        //Set cookies.
        setCookie("leetUserToken", accessToken, expiresInMinutes);
        setCookie("refreshToken", refreshToken);
        //Even though the response does not contain userdata we have to create
        //this cookie to be look as authenticated.
        setCookie("leetUserProfile", JSON.stringify(response));
        toast.success("You are now logged in.");
      }
      return response;
    })
    .catch((error) => {
      const message = error.message || "Error in create account API.";
      const success = false;

      return {
        message,
        success,
      };
    });

export const generalMargin = {};

export const marginBottomList = {
  mbnone: "tw-mb-0",
  mbxxs: "tw-mb-1",
  mbxs: "tw-mb-2",
  mbsm: "tw-mb-3",
  mbbase: "tw-mb-4",
};

export const generalPadding = {};

import React from "react";

const FacebookSignupFailed = (props) => {
  const { onclick } = props;
  return (
    <div className="py-4 d-flex flex-column align-items-center h-100">
      <div className="text-center">
        <p className="px-5 tw-text-white">
          Your e-mail could not be retrieved from your Facebook account.
        </p>
        <p className=".text-light tw-text-white px-3 pt-3 pb-0 mb-0">
          Don't worry,
        </p>
        <p className=".text-light tw-text-white px-3 py-0">
          Login or Signup via LEET.
        </p>
      </div>
    </div>
  );
};
export default FacebookSignupFailed;

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  getTeams,
  updateTeam,
  addUserToTeam,
  removeUserFromTeam,
  deleteTeam,
  uploadTeamAvatar,
} from "services/teams";
import { friendListApi } from "services/users";
import { Flex } from "components/atoms";
import CreateTeams1 from "../../components/pages/teams/createTeamsOne";
import GiveYourTeamAvatar from "../../components/pages/teams/giveYourTeamAvatar";
import PlayersInMyTeam from "../../components/pages/teams/playersInMyTeam";
import AddPlayerToTeam from "../../components/pages/teams/addplayerToTeam";
import { texts } from "../../Text/index";
import { toast } from "react-toastify";
import { flowSteps as createTeamSteps } from "../constants";
import { ConfirmComponent } from "../../components/molecules";
import Modal from "deprecated/modal";

import { teamUserStatus } from "../constants";

const EditTeam = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { teamId, step } = useParams();

  const [teams, setTeams] = useState({});
  //Based on this integer we display one of the teams pages.
  const [createTeamsStep, setCreateTeamsStep] = useState(parseInt(step) || 1);
  const [playingInThisTeam, setPlayingInThisTeam] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamMemberIds, setTeamMemberIds] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]); 
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = React.useState(null);
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [playersNotInTeam, setPlayersNotInTeam] = useState([]);
  const [initialPlayers, setInitialPlayers] = useState([]); //list of user objects.
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); //list of user objects.
  const [searchTerm, setSearchTerm] = useState("");
  const [playerType, setPlayerType] = useState("");

  const updateTeam = (teamData) => {
    const { onUpdateTeam } = props;
    return onUpdateTeam(teamData).then((response) => {
      if (response?.success === true) {
        toast.success(texts.teamUpdated);
      } else {
        toast.error(texts.updateTeamFailed);
      }
    });
  };

  const addUserToTeam = (userData) => {
    const { onAddUserToTeam } = props;
    return onAddUserToTeam(userData).then((response) => {
      if (response?.success === true) {
        toast.success(texts.playerSucessfullyAddedToTeam);
      } else {
        toast.error(texts.updateTeamFailed);
      }
    });
  };

  const removeUserFromTeam = (userData) => {
    const { onRemoveUserFromTeam } = props;
    return onRemoveUserFromTeam(userData).then((response) => {
      if (response?.success === true) {
        toast.success(texts.playerSucessfullyRemovedFromTeam);
      } else {
        toast.error(texts.updateTeamFailed);
      }
    });
  }; 

  const resetImg = () => {
    setFile(null);
    setImagePreview(null);
  };

  const submit = () => {
    //Finish update method...
    let teamData = {
      id: teamId,
      name: teamName,
      userIds: teamMemberIds,
    };

    if (file) {
      props.onUploadTeamAvatar({ file, teamId }).then((response) => {
        if (response.success) {
          teamData.imageUrl = response?.data?.imageUrl;
          updateTeam(teamData);
          resetImg();
        } else {
          toast.error(texts.updateTeamFailed);
          return history.push("/groups");
        }
      });
    } else {
      teamData.imageUrl = imagePreview;
      updateTeam(teamData);
    }
  };

  const setCreateTeamsStepWithValidation = (step) => {
    if (teamName) {
      setCreateTeamsStep(step);
      const playerId = props.profile?.data?.id || "";
      playingInThisTeam
        ? setTeamMemberIds([playerId, ...teamMemberIds])
        : setTeamMemberIds(
            teamMemberIds.filter((memberId) => memberId != playerId)
          );
    } else {
      setError(texts.teamNameIsRequired);
    }
  };

  const searchOnChange = (value) => {
    dispatch(friendListApi({ name: value || "", type: playerType }, true));
    setSearchTerm(value || "");
  };

  const searchPlayerType = (type) => {
    dispatch(friendListApi({ name: searchTerm || "", type }, true));
    setPlayerType(type);
  };

  const addOrAcceptTeamMember = (member) => {
    const memberId = member?.id;
    var existingTeamMember = teamMembers.filter((teamMember) => teamMember.userDetails.id == member.id)[0];
    var updatedMemberIds = teamMemberIds;
    if (!existingTeamMember){
      updatedMemberIds = [...teamMemberIds, memberId];
      setTeamMemberIds(updatedMemberIds);
      setTeamMembers((teamMembers) => [...teamMembers, {status: teamUserStatus.joined, isAdmin: false, userDetails: member}]);
      setInitialPlayers([member, ...initialPlayers]);
      setPlayersNotInTeam(
        props.suggestedUsers.filter((suggstedUser) => !updatedMemberIds?.includes(suggstedUser.user.id))
      );
    }
    else{
      existingTeamMember.status = teamUserStatus.joined;
      setTeamMembers(teamMembers);
      setTeamMemberIds(updatedMemberIds);
      setInitialPlayers(teamMembers.map((teamMember) => teamMember.userDetails));
    }

    addUserToTeam({teamId: teamId, userId: memberId});
  }

  const removeTeamMember = (member) => {
    const memberId = member?.id;
    const reducedMemberIds = teamMemberIds.filter((id) => id !== memberId);
    setTeamMemberIds(reducedMemberIds);
    setInitialPlayers(
      initialPlayers.filter((player) => player.id != memberId)
    );

    removeUserFromTeam({teamId: teamId, userId: memberId});
  }

  const backToTeamPage = () => {
    return history.push("/groups");
  };

  useEffect(() => {
    setTeams(
      props?.teams?.filter((team) => team.createdBy === props.profile?.data?.id)
    );
    if (playersNotInTeam.length === 0) {
      searchOnChange();
    }
    if (teams?.length < 1) {
      props.onGetTeams().then((response) => {
        setSelectedTeam(response?.data?.find((team) => team.id == teamId));
      });
    } else {
      const team = props.teams.find((team) => team.id == teamId);
      if (team) {
        setSelectedTeam(team);
      }
    }
  }, []);

  useEffect(() => {
    setPlayersNotInTeam(
      props.suggestedUsers.filter((suggstedUser) => !teamMemberIds?.includes(suggstedUser.user.id))
    );
  }, [props?.suggestedUsers]);

  useEffect(() => {
    setTeamName(selectedTeam?.name);
    setImagePreview(selectedTeam?.imageUrl);
    const teamMemberIds = selectedTeam?.teamUsers.map(
      (user) => user?.userDetails?.id
    );
    setTeamMemberIds(teamMemberIds);
    setTeamMembers(selectedTeam?.teamUsers);
    setInitialPlayers(
      selectedTeam?.teamUsers?.map((user) => {
        return user.userDetails;
      })
    );

    if (teamMemberIds) {
      const userId = props.profile?.data?.id;
      if (userId) {
        setPlayingInThisTeam(teamMemberIds.includes(userId));
      }
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (createTeamsStep === createTeamSteps.step0) {
      setTeamName("");
      setError("");
      setTeamMemberIds([]);
      setTeamMembers([]);
    }
    if (createTeamsStep === createTeamSteps.step2) {
      setError("");
    }
  }, [createTeamsStep]);

  const goToAddAPlayer = () => {
    setCreateTeamsStep(createTeamSteps.step4);
    dispatch(friendListApi({ name:searchTerm || "", type: playerType }, true));
  }

  const deleteSelectedTeam = () => {
    props.onDeleteTeam({ id: teamId }).then((response) => {
      if (response.success) {
        toast.success(texts.teamDeleted);
        history.push("/groups");
      } else {
        toast.error(texts.genericError);
      }
    });
  };

  return (
    <Flex className={["tw-h-full"]}>
      {createTeamsStep === createTeamSteps.step1 && (
        <CreateTeams1
          backToTeamPage={backToTeamPage}
          setCreateTeamsStepWithValidation={setCreateTeamsStepWithValidation}
          setPlayingInThisTeam={setPlayingInThisTeam}
          playingInThisTeam={playingInThisTeam}
          teamName={teamName}
          setTeamName={setTeamName}
          error={error}
        ></CreateTeams1>
      )}
      {createTeamsStep === createTeamSteps.step2 && (
        <GiveYourTeamAvatar
          backToTeamPage={backToTeamPage}
          setCreateTeamsStep={setCreateTeamsStep}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          setFile={setFile}
        ></GiveYourTeamAvatar>
      )}
      {createTeamsStep === createTeamSteps.step3 && (
        <PlayersInMyTeam
          deleteTeam={() => setIsConfirmOpen(true)}
          addOrAcceptTeamMember={addOrAcceptTeamMember}
          removeTeamMember={removeTeamMember}
          players={initialPlayers}
          teamMembers={teamMembers}
          submit={submit}
          backToTeamPage={backToTeamPage}
          goToAddAPlayer={goToAddAPlayer}
          fnBack={() => setCreateTeamsStep(createTeamSteps.step2)}
          stepNumber={createTeamSteps.step2 + "/" + createTeamSteps.step2}
        ></PlayersInMyTeam>
      )}
      {createTeamsStep === createTeamSteps.step4 && (
        <AddPlayerToTeam
          backToTeamPage={backToTeamPage}
          setCreateTeamsStep={setCreateTeamsStep}
          players={playersNotInTeam}
          teamMembers={teamMemberIds}
          addOrAcceptTeamMember={addOrAcceptTeamMember}
          removeTeamMember={removeTeamMember}
          submit={submit}
          fnBack={() => {setCreateTeamsStep(createTeamSteps.step3); setSearchTerm('');}}
          onSearchChange={searchOnChange}
          onTypeChange={searchPlayerType}
        ></AddPlayerToTeam>
      )}
      <Modal open={isConfirmOpen}>
        <ConfirmComponent
          text={texts.confirmDeleteTeam}
          fnYes={deleteSelectedTeam}
          fnNo={() => setIsConfirmOpen(false)}
          fnCancel={() => setIsConfirmOpen(false)}
        />
      </Modal>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.MyTeamsReducer,
    profile: state.MyProfileReducer,
    suggestedUsers: state.UserReducer?.usersList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateTeam: (data) => dispatch(updateTeam(data)),
  onGetTeams: () => dispatch(getTeams()),
  onSuggestedUsers: (data, refreshdata) =>
    dispatch(friendListApi(data, refreshdata)),
  onUploadTeamAvatar: (data) => dispatch(uploadTeamAvatar(data)),
  onDeleteTeam: (data) => dispatch(deleteTeam(data)),
  onAddUserToTeam: (data) => dispatch(addUserToTeam(data)),
  onRemoveUserFromTeam: (data) => dispatch(removeUserFromTeam(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);

import React from "react";
import { Flex, Img } from "components/atoms";
import { isTeamVSteam } from "utils/helper";
import {
  TeamProfileButtons,
  MatchTypeButtons,
  ProfileGameCard,
  TeamDetails,
  TeamVSteamCard,
} from "../../molecules";
import { actionButtons } from "../myProfile/constants";
import { texts } from "../../../Text/index";

const TeamProfilePage = (props) => {
  const {
    team,
    active,
    setActive,
    events,
    showEvent,
    copyTeamLink,
    startChat,
    setOpenConfirm,
    goToTeamMembers,
    showEventById,
    joinToTeam,
    isInTeam,
    leaveTheTeam,
    isTeamAdmin,
    isRequestedToJoin,
    isTeamCreator,
    edit,
  } = props;

  return (
    <Flex className={["tw-p-4 tw-pt-0"]}>
      <Flex>
        <Flex direction="row" className={["tw-mb-4"]}>
          <Img
            className={["tw-h-24 tw-w-24 tw-rounded-3xl"]}
            src={team?.imageUrl || "/img/unselected-player.png"}
          />
          <TeamDetails
            wins={team?.matchesWon}
            draws={team?.matchesDraws}
            losses={team?.matchesLost}
            name={team?.name}
          />
        </Flex>
        <Flex className={["tw-justify-center"]}>
          <TeamProfileButtons
            deleteTeam={setOpenConfirm}
            selectedTeamId={() => {}}
            goToTeamMembers={goToTeamMembers}
            startChat={startChat}
            copyTeamLink={copyTeamLink}
            joinToTeam={joinToTeam}
            isInTeam={isInTeam}
            leaveTheTeam={leaveTheTeam}
            isTeamAdmin={isTeamAdmin}
            isRequestedToJoin={isRequestedToJoin}
            isTeamCreator={isTeamCreator}
            edit={edit}
          />
        </Flex>
      </Flex>

      <MatchTypeButtons
        active={active}
        setActive={setActive}
        actionButtons={actionButtons}
        myProfile={false}
      />
      <Flex className={["tw-overflow-auto tw-rounded-2xl tw-h-80"]}>
        {events && events.length > 0 ? (
          events.map((event, index) => {
            return isTeamVSteam(event?.eventResponse) ? (
              <TeamVSteamCard
                showEvent={showEventById}
                data={event?.eventResponse}
                key={index}
                smallCard={true}
              />
            ) : (
              <ProfileGameCard
                key={index}
                event={event?.eventResponse}
                showEvent={showEvent}
                status={event?.status}
              />
            );
          })
        ) : (
          <Flex className={["tw-pt-8 tw-text-center"]}>
            {texts.noMatchesToDisplay}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default TeamProfilePage;

import NewStoreState from "../newStoreState";
import * as Action from "./actions";

export function MyProfileReducer(state = NewStoreState.myProfile, action) {
  let myProfile = { ...state }; //Create new object for updating components.
  switch (action.type) {
    case Action.MY_PROFILE:
      myProfile.profile = action.payload.data;
      return myProfile;

    case Action.PROFILE_IMAGE:
      myProfile.profileImage = action.payload.data;
      return myProfile;

    case Action.SETTINGS:
      myProfile.profile.settings = action.payload.data;
      return myProfile;

    default:
      return state;
  }
}

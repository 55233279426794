import React from "react";
import classNames from "classnames";
import { textColors } from "../textColors";
import { textSizes } from "../textSizes";

const Paragraph = (props) => {
  const {
    size = "base",
    className = [],
    muted,
    children,
    color = "white",
    noMargin = false,
  } = props;
  const paragraphClass = classNames(
    `${textColors[color]}`,
    { "tw-text-muted": muted },
    { "tw-text-base": !size },
    `${textSizes[size]}`,
    { "tw-mb-4": !noMargin },
    "tw-leading-tight",
    ...className
  );

  return <p className={paragraphClass}>{children}</p>;
};

export default Paragraph;

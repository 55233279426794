export const UNAUTH_EVENTS = "UNAUTH_EVENTS";
export const STOP_UNAUTH_EVENTS = "STOP_UNAUTH_EVENTS";
export const RESET_UNAUTH_EVENTS = "RESET_UNAUTH_EVENTS";

export function unauthGameEvents(data) {
  return {
    type: UNAUTH_EVENTS,
    payload: data,
  };
}

export function resetUnauthGameEvents(data) {
  return {
    type: RESET_UNAUTH_EVENTS,
    payload: data,
  };
}

export function stopUnauthEvents(data) {
  return {
    type: STOP_UNAUTH_EVENTS,
    payload: data,
  };
}

import React from "react";
import { Flex, Span, Img, Button } from "components/atoms";
import TeamVSteamCore from "../general/teamVSteamCore";
import { texts } from "Text";

const TeamVSteamBanner = (props) => {
  const { data, onClick, key } = props;

  const teamOne = data?.sides[0]?.team;
  const teamTwo = data?.sides[1]?.team;
  const organiser = data?.sides[0]?.team?.name;

  return (
    <Flex className={["tw-w-full tw-overflow-hidden"]} id={key}>
      <TeamVSteamCore teamOne={teamOne} teamTwo={teamTwo} />
      <Flex
        direction="row"
        className={[
          "tw-w-full tw-h-12 tw-bg-grey tw-rounded-b-2xl tw-p-2 tw-justify-between tw-items-center",
        ]}
      >
        <Span
          size="xs"
          color={true}
          className={["tw-text-white tw-px-4 tw-py-1 tw-ml-1 tw-rounded-2xl"]}
        >
          {texts.organiser} {organiser}
        </Span>
        <Button
            onClick={onClick}
            size={"xsm"}
            className={[
              "tw-px-2 tw-border-2 tw-rounded-2xl tw-border-white tw-text-white tw-text-sm ",
            ]}
          >
          {texts.eventTeams}
        </Button>
      </Flex>
    </Flex>
  );
};

export default TeamVSteamBanner;

import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Button } from "components/atoms";
import TeamCard from "../../molecules/teams/teamCard";
import { texts } from "../../../Text/index";
import { teamUserStatus } from "../../../containers/constants";

const TeamsComponent = (props) => {
  const { teams, setCreateTeamsStep, userId, goToTeamDetails, onJoinTeam } = props;
  const history = useHistory();

  return (
    <Flex className={["tw-h-full tw-w-full"]}>
      {teams && teams.length > 0 ? (
        <Flex className={[" tw-h-[400px] tw-overflow-auto"]}>
          {teams.map((team) => {
            var existingTeamUser = team?.teamUsers?.find(
              (user) => user?.userDetails?.id === userId);
            return (
              <TeamCard
                onClick={() => existingTeamUser ? history.push(`/group/edit/${team?.id}`) : onJoinTeam(team?.id)}
                team={team}
                isTeamCreator={team?.createdBy === userId}
                goToTeamDetails={goToTeamDetails}
                isJoined = {existingTeamUser && existingTeamUser.status == teamUserStatus.joined}
                isRequested = {existingTeamUser && existingTeamUser.status == teamUserStatus.requestedToJoin}
              ></TeamCard>
            );
          })}
        </Flex>
      ) : (
        <Flex className={["tw-h-1/2 tw-p-4 tw-mt-36 tw-text-grey"]}>
          {texts.dontHaveTeams}
        </Flex>
      )}
      <Flex className={["tw-w-full tw-items-center tw-py-4"]}>
        <Button
          ninetenary
          onClick={setCreateTeamsStep}
          className={["tw-w-11/12 tw-mx-auto tw-bottom-4"]}
        >
          {texts.createNewTeam}
        </Button>
      </Flex>
    </Flex>
  );
};

export default TeamsComponent;

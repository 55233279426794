import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import moment from "moment";
import { inviteFriendApi } from "services/friends";
import { createGame } from "services/events";
import { friendListApi } from "services/users";
import { matchLocationsApi } from "services/locations";
import { myProfileApi } from "services/profile";
import { getTeams, getAllTeams } from "services/teams";
import { copyToClipboard, cancelFlow, validateEmail } from "../../utils/helper";
import OrganiseMatchSteps from "./organiseMatchSteps";
import { texts } from "../../Text/index";
import { toast } from "react-toastify";
import { flowSteps as createMatchSteps, matchTypeOptions } from "../constants";
import LookingFor from "./looking-for";
import { Flex } from "components/atoms";
import { getCookie } from "services/cookies";

const OrganiseMatch = (props) => {
  const {
    onGetAllTeams,
    suggestedUsers,
    onSuggestedUsers,
    organiserProfileId,
    tokenReducer,
  } = props;
  const history = useHistory();

  // Based on this integer we display one of the teams pages.
  // OrganiseMatchSteps component containes the pages regarding the step number.
  const [createMatchStep, setCreateMatchStep] = useState(1);
  const [gameName, setGameName] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [location, setLocation] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [maxAmountOfPlayers, setMaxAmountOfPlayers] = useState(1);
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(null); // This refers to teamId if the organiser will be a team. -1 is asigned if organised alone.
  const [opponentTeams, setOpponentTeams] = useState([]); // This refers to teamIds we are inviting to play against.
  const [playersNotInTeam, setPlayersNotInTeam] = useState([]);
  const [date, setDate] = useState(new Date());
  const [urlToCopy, setUrlToCopy] = useState("https://beta.leet.mt/");
  const [privateMatch, setPrivateMatch] = useState(false);
  const [email, setEmail] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [selectedMatchType, setSelectedMatchType] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [pageNumber , setPageNumber] = useState(1);

  const finalPage = 
    selectedMatchType === matchTypeOptions.findAPlayer
      ? '4' : '6';

  const goToNextPage = (stepNumber) => {
    setPageNumber(pageNumber+1);
    setCreateMatchStep(stepNumber);
  }

  const goToPreviousPage = (stepNumber) => {
    setPageNumber(pageNumber-1);
    setCreateMatchStep(stepNumber);
  }

  React.useEffect(() => {
    const cookie = getCookie("leetUserToken") || tokenReducer?.token;
    if (!cookie) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
  }, []);

  const onSubmit = () => {
    let submitData = {
      name: gameName,
      dateTime: moment.utc(date).format(),
      locationId: location,
      reservedPlayers: 0,
      maxPlayers: parseInt(maxAmountOfPlayers) || 0,
      isPlaying: teamMembers.includes(organiserProfileId),
      invitedPlayersIds: teamMembers || [],
      invitedTeamIds: selectedMatchType === matchTypeOptions.organiseAMatch ? [selected] : opponentTeams || [],
      description: gameDetails,
      private: privateMatch,
      playingAsTeamId: selectedMatchType === matchTypeOptions.challengeATeam ? selected : null,
    };
    //Add organiserId to data for now to make calls work!
    if (!submitData?.invitedPlayersIds.includes(organiserProfileId)) {
      submitData?.invitedPlayersIds.push(organiserProfileId);
    }

    props.onCreateEvent(submitData).then(({ message, data, success } = {}) => {
      console.log(data);
      if (success) {
        setUrlToCopy(
          `${process.env.REACT_APP_WEBURL}event/${data?.eventResponse?.id}`
        );
        goToNextPage(createMatchSteps.step7);
        toast.success(texts.newTeamCreated);
      } else {
        toast.error(message ? message : texts.gameCreateFailed);
        history.push("/");
      }
    });
  };

  const validateStep = () => {
    if (createMatchStep === createMatchSteps.step1) {
      gameName
        ? goToNextPage(createMatchSteps.step2)
        : setError(texts.gameNameIsRequired);
      return;
    }
    if (!location) {
      setError(texts.locationIsRequired);
      return;
    }
    if (date < new Date()) {
      setError(texts.setValidDate);
      return;
    }
    setError("");
    goToNextPage(createMatchSteps.step3);
  };

  const addOrRemoveTeamMember = (member) => {
    //Todo: Set teamMembers if added and remove teammembers from suggestedUsers.
    const memberId = member?.id;
    if (!teamMembers.includes(memberId)) {
      setTeamMembers((teamMembers) => [...teamMembers, memberId]);
      toast.success(texts.playerSucessfullyAddedToTeam);
    } else {
      const reducedMembers = teamMembers.filter((id) => id !== memberId);
      setTeamMembers(reducedMembers);
      toast.success(texts.playerSucessfullyRemovedFromTeam);
    }
  };

  const searchOnChange = React.useCallback(
    debounce((value) => {
      onSuggestedUsers({ name: value || "" }, true).then((response) => {});
    }, 800),
    []
  );

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!organiserProfileId) {
      props.fetchProfile();
    }
    if (playersNotInTeam.length === 0) {
      searchOnChange();
    }
    if (!props.matchLocations || props.matchLocations?.length === 0) {
      props.onLoadMatchLocations();
    }
    if (createMatchStep === createMatchSteps.step0) {
      setGameName("");
      setError("");
      setTeamMembers([]);
    }
    if (createMatchStep === createMatchSteps.step2) {
      setError("");
    }
  }, [createMatchStep]);

  const getStepString = () => {
    return [
      pageNumber.toString(),
      "/",
      finalPage,
    ].join("");
  };

  const inviteFriend = () => {
    if (!email || !validateEmail(email)) {
      setError(texts.emailIsRequired);
    } else {
      props.onInviteFrien(email).then((response) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          if (response?.status === 409) {
            toast.error(response.message);
          }
        }
      });
      setError("");
    }
  };

  useEffect(() => {
    const users = suggestedUsers?.map((value) => value.user);
    setPlayersNotInTeam(users);
  }, [suggestedUsers]);

  const createTeam = () => {
    history.push("/groups");
  };

  const validateMySelectedTeam = () => {
    if (!selected) {
      setError(texts.youNeedToSelectATeam);
      return;
    }
    setError("");
    if (selectedMatchType === matchTypeOptions.organiseAMatch) {
      goToNextPage(createMatchSteps.step5);
    }
    else if (selectedMatchType === matchTypeOptions.challengeATeam) {
      goToNextPage(createMatchSteps.step6);
    }
  };

  const addteam = (teamId) => {
    if (!teamId) {
      return;
    }
    if (opponentTeams.includes(teamId)) {
      setOpponentTeams(opponentTeams.filter((id) => teamId !== id));
    } else {
      setOpponentTeams([teamId, ...opponentTeams]);
    }
  };
  
  const searchTeam = React.useCallback(
    debounce((value) => {
      onGetAllTeams({ name: value }, true).then((response) => {});
    }, 800),
    []
  );

  return (
    <Flex className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}>
      {!selectedMatchType && (
        <LookingFor 
          setSelectedMatchType={setSelectedMatchType}/>
      )}
      {selectedMatchType && (
        <OrganiseMatchSteps
          createMatchStep={createMatchStep}
          getStepString={getStepString}
          cancelOrganiseMatch={() => cancelFlow(history)}
          history={history}
          setCreateMatchStep={setCreateMatchStep}
          gameName={gameName}
          setGameName={setGameName}
          gameDetails={gameDetails}
          setGameDetails={setGameDetails}
          validateStep={validateStep}
          date={date}
          setDate={setDate}
          setLocation={setLocation}
          setMaxAmountOfPlayers={setMaxAmountOfPlayers}
          addOrRemoveTeamMember={addOrRemoveTeamMember}
          profileData={props?.profile?.profile}
          selected={selected}
          setSelected={setSelected}
          onSubmit={onSubmit}
          playersNotInTeam={playersNotInTeam}
          teamMembers={teamMembers}
          searchOnChange={searchOnChange}
          urlToCopy={urlToCopy}
          copyToClipboard={copyToClipboard}
          locations={props.matchLocations}
          selectedLocation={location}
          privateMatch={privateMatch}
          setPrivateMatch={setPrivateMatch}
          inviteFriend={inviteFriend}
          setEmail={setEmail}
          selectedAmount={maxAmountOfPlayers}
          createTeam={createTeam}
          validateMySelectedTeam={validateMySelectedTeam}
          addTeam={addteam}
          opponentTeams={opponentTeams}
          searchTeam={searchTeam}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          selectedMatchType={selectedMatchType}
          error={error}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}/>
      )}
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.MyProfileReducer,
    matchLocations: state.LocationsReducer?.matchLocations,
    myTeams: state.MyTeamsReducer,
    allTeams: state.AllTeamsReducer,
    suggestedUsers: state.UserReducer?.usersList,
    organiserProfileId: state.MyProfileReducer?.profile?.id,
    tokenReducer: state.TokenReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoadMatchLocations: (data) => dispatch(matchLocationsApi(data)),
  onSuggestedUsers: (data, refreshdata) =>
    dispatch(friendListApi(data, refreshdata)),
  onCreateEvent: (data) => dispatch(createGame(data)),
  fetchProfile: () => dispatch(myProfileApi()),
  onInviteFrien: (data) => dispatch(inviteFriendApi(data)),
  onGetMyTeams: () => dispatch(getTeams()),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganiseMatch);

import React from "react";
import Modal from "deprecated/modal";
import { Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, ManageUsersCard } from "../../molecules";

const ManageUsers = (props) => {
  const {
    selectedEvent,
    fnCancel,
    cancelMatchClick,
    acceptRequest,
    joinMatchClick,
  } = props;
  // Remove organiser from the manage list. 
  const players = selectedEvent?.users.filter((user) => !user.isOrganizer);
  return (
    <Flex
      className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
      >
    <Modal open={true}>
      <Flex
        className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}
      >
        <BlackModalTopBar onClick={fnCancel} />
        <Flex className={["tw-px-12 xsm:tw-mt-10 md:tw-w-mmd md:tw-m-auto"]}>
          <Heading className={[" tw-items-center"]}>
            {texts.manageUsersInThisEvent}
          </Heading>

          <Flex className={["tw-h-64 tw-mb-4 tw-overflow-auto"]}>
            {players && players.length > 0 ? (
              players.map((player) => {
                return (
                  <ManageUsersCard
                    player={player}
                    cancelMatchClick={cancelMatchClick}
                    acceptRequest={acceptRequest}
                    joinMatchClick={joinMatchClick}
                    userStatus={player?.status}
                  ></ManageUsersCard>
                );
              })
            ) : (
              <Flex
                direction="row"
                className={[
                  "tw-py-4 tw-mt-2 tw-text-white tw-text-xs tw-text-center",
                ]}
              >
                {texts.noPlayersInThisEvent}
              </Flex>
            )}
          </Flex>
          <Button onClick={fnCancel} className={["tw-my-8"]}>
            {texts.finish}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  </Flex>
  );
};

export default ManageUsers;

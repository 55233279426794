import React from "react";
import { texts } from "../../../Text/index";
import RadioButtons from "../../molecules/general/radioButtons";
import { playerTypes } from "containers/constants";

const GoalerFutballerRadio = (props) => {
  const { onChange, currentSelected } = props;

  const playersRadioButtons = [
    { id: playerTypes.goalKeeper, name: texts.goaler, defaultChecked: currentSelected === playerTypes.goalKeeper},
    { id: playerTypes.player, name: texts.futballer, defaultChecked: !currentSelected || currentSelected === playerTypes.player},
    { id: playerTypes.both, name: texts.playerAndGoaler, defaultChecked: currentSelected === playerTypes.both}
  ]

  return (
    <RadioButtons 
      onChange={onChange}
      name={"GoalerFutballerRadio"}
      buttons={playersRadioButtons} />
  );
};

export default GoalerFutballerRadio;

import React from "react";
import { Flex, Button, Img } from "components/atoms";
import { texts } from "../../../Text/index";

const TeamProfileButtons = (props) => {
  const {
    deleteTeam,
    selectedTeamId,
    goToTeamMembers,
    startChat,
    copyTeamLink,
    joinToTeam,
    isInTeam,
    leaveTheTeam,
    isTeamAdmin,
    isRequestedToJoin,
    isTeamCreator,
    edit,
  } = props;

  return (
    <Flex className={["tw-w-full tw-py-2 tw-text-xxs tw-justify-between"]}>
      <Flex
        direction="row"
        className={["tw-w-full tw-py-2 tw-text-xxs tw-justify-between"]}
      >
        <Flex direction="row" className={["tw-w-80 tw-justify-between"]}>
          {isInTeam ? (
            <Button
              className={["tw-w-60"]}
              size="base"
              onClick={leaveTheTeam}
              secondary
            >
              {texts.leave}
            </Button>
          ) : (
            <Button className={["tw-w-60"]} size="base" onClick={joinToTeam}>
              {texts.addTeam}
            </Button>
          )}
          <Button
            size="base"
            onClick={startChat}
            tertiary={true}
            className={["tw-w-30"]}
            disabled={!isInTeam}
          >
            {texts.chat}
          </Button>
        </Flex>
        <Button
          size="base"
          onClick={() => copyTeamLink()}
          septenary={true}
          className={["tw-px-3"]}
        >
          <Img
            src={"/img/link.png"}
            title="Team Link"
            alt="Team Link"
            className={["tw-h-6"]}
          />
        </Button>
      </Flex>
      <Flex
        direction="row"
        className={["tw-w-full tw-py-2 tw-text-xxs tw-justify-between"]}
      >
      <Flex direction="row" className={["tw-w-80 tw-justify-between"]}>
        <Button
          className={["tw-w-60"]}
          size="base"
          onClick={goToTeamMembers}
          tertiary={true}
        >
          {texts.teamMembers}
        </Button>
        {isRequestedToJoin && isTeamAdmin && (
            <svg class = "button change-my-color tw-h-6 tw-relative tw-right-8 tw--top-[8px] tw-cursor-pointer tw-object-cover" expanded = "true" height = "5px" width = "25px">
            <circle cx = "50%" cy = "50%" r = "7px"></circle>
            <circle class = "pulse" cx = "50%" cy = "50%" r = "9px"></circle>
          </svg>
          )} 
          {isTeamCreator && (
            <Button
            size="base"
            onClick={edit}
            tertiary={true}
            className={["tw-w-30"]}
            >
             {texts.edit}
            </Button>
          )}
        </Flex>
        <Button
          size="base"
          className={["tw-px-3"]}
          onClick={() => deleteTeam(selectedTeamId)}
          secondary={isTeamAdmin}
          disabled={!isTeamAdmin}
        >
          <Img
            src={"/img/close-white.png"}
            title="Delete Team"
            alt="Delete"
            className={["tw-h-6"]}
          />
        </Button>
      </Flex>
    </Flex>
  );
};

export default TeamProfileButtons;

import React from "react";
import classNames from "classnames";

const Flex = (props) => {
  const {
    direction = "col",
    position = "relative",
    className,
    children,
    onClick,
    onDoubleClick,
    onScroll,
    key,
    style = {},
  } = props;
  const flexClass = classNames(
    `tw-${position}`,
    "tw-flex",
    `tw-flex-${direction}`,
    "tw-flex-shrink-0",
    className
  );

  return (
    <div
      onScroll={onScroll}
      className={flexClass}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      key={key}
      style={style}
    >
      {children}
    </div>
  )
};

export default Flex;

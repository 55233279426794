import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { myProfileApi, uploadPictureApi } from "services/profile";
import {
  getTeams,
  removeUserFromTeam,
  deleteTeam,
  getTeamById,
  addUserToTeam,
  requestToJoinTeam
} from "services/teams";
import { authGameEvents } from "services/events";

import { ConfirmComponent } from "../../components/molecules";
import Modal from "deprecated/modal";
import { Flex } from "components/atoms";
import TeamProfilePage from "components/pages/teams/teamProfile";
import Header from "containers/header/index";
import { headerVersions } from "../header/constants";
import { texts } from "../../Text/index";
import { actionButtons } from "../../components/pages/myProfile/constants";
import { teamUserStatus } from "../constants";

const TeamProfile = (props) => {
  const { myProfile, myTeams, userEvents, allTeams, onRequestToJoinTeam } = props;
  const { teamId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [team, setTeam] = useState(null);
  const [teamLinkCopied, setTeamLinkCopied] = useState(null);
  const [active, setActive] = useState(1);
  const [events, setEvents] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isTeamAdmin, setIsTeamAdmin] = useState(false);
  const [isRequestedToJoin, setIsRequestedToJoin] = useState(false);
  const [isInTeam, setIsInTeam] = useState(false);

  useEffect(() => {
    setEvents(userEvents);
    dispatch(getTeams());
    dispatch(getTeamById({ teamId }));
    dispatch(authGameEvents({
      includePastEvents: true,
      pageNum: "1",
      pageSize: "20",
      setUpdate: true,
      teamId: teamId
    }));
  }, []);

  useEffect(() => {
    setEvents(userEvents);
  }, [userEvents]);

  useEffect(() => {
    const selectedTeam =
      allTeams.find((team) => team.id === teamId) ||
      myTeams.find((team) => team.id === teamId);
    setTeam(selectedTeam);
    setIsTeamAdmin(selectedTeam?.createdBy === myProfile.id);
    const requestToJoin = selectedTeam?.teamUsers.find(
      (user) => user.status == teamUserStatus.requestedToJoin
    );
    setIsRequestedToJoin(requestToJoin)
    const userInTeam = selectedTeam?.teamUsers.find(
      (user) => user?.userDetails?.id === myProfile?.id
    );
    if (userInTeam) {
      setIsInTeam(true);
    } else {
      setIsInTeam(false);
    }
  }, [teamId, myTeams, allTeams]);

  const displayedEvents = () => {
    if (active === actionButtons.UPCOMING) {
      return events?.filter((event) => {
        return (
          new Date(event?.eventResponse?.dateTime).getTime() >
          new Date().getTime()
        );
      });
    }
    if (active === actionButtons.PAST) {
      return events?.filter((event) => {
        return (
          new Date(event?.eventResponse?.dateTime).getTime() <
          new Date().getTime()
        );
      });
    }
    if (active === actionButtons.ORGANISED_MATCHES) {
      return events?.filter((event) => event?.isOrganizer === true);
    }
  };

  const showEvent = (event) => {
    if (event && event.id) {
      history.push(`/event/${event.id}`);
    }
  };

  const copyTeamLink = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    if (!teamLinkCopied) {
      toast.success(texts.linkCopiedSuccessfully);
      setTeamLinkCopied(true);
    }
  };

  const confirmRemoveMyselfFromTeam = () => {
    isTeamAdmin &&
      dispatch(deleteTeam({ id: teamId })).then((response) => {
        if (response?.success) {
          toast.success(texts.teamDeleted);
          history.push("/groups");
        }
      });
  };

  const startChat = () => {
    history.push(`/chat/match/${teamId}`);
  };

  const goToTeamMembers = () => {
    history.push(`/group/members/${teamId}`);
  };

  const showEventById = (id) => {
    if (id) {
      history.push(`/event/${id}`);
    }
  };

  const goToEditTeamMembers = () => {
    history.push(`/group/edit/${teamId}/3`);
  };

  const goToEditPage = () => {
    history.push(`/group/edit/${team?.id}`);
  };

  const joinToTeam = () => {
    if (teamId && myProfile?.id) {
      const data = {
        teamId,
        userId: myProfile?.id,
      };
      onRequestToJoinTeam(data).then((response) => {
        if (response?.success) {
          toast.success(texts.requestToJoinTeamSuccess);
          dispatch(getTeamById({ teamId }));
        } else {
          toast.error(texts.genericError);
        }
      });
    }
  };

  const leaveTheTeam = () => {
    dispatch(
      removeUserFromTeam({
        teamId: teamId,
        userId: myProfile?.id,
      })
    ).then((response) => {
      if (response?.success) {
        toast.success(texts.succesFullyRemovedFromTeam);
        dispatch(getTeamById({ teamId }));
      } else {
        toast.error(response.error?.message);
      }
    });
  };

  return (
    <Flex className={["tw-h-full md:tw-w-mmd md:tw-m-auto"]}>
      <Header
        headerText={texts.teamProfile}
        headerVersion={headerVersions.version3}
      />
      <TeamProfilePage
        team={team}
        showEvent={showEvent}
        active={active}
        setActive={setActive}
        events={displayedEvents()}
        copyTeamLink={copyTeamLink}
        setOpenConfirm={() => setOpenConfirm(true)}
        startChat={() => startChat()}
        goToTeamMembers={isTeamAdmin ? goToEditTeamMembers : goToTeamMembers}
        showEventById={showEventById}
        joinToTeam={joinToTeam}
        isInTeam={isInTeam}
        leaveTheTeam={leaveTheTeam}
        isTeamAdmin={isTeamAdmin}
        isRequestedToJoin={isRequestedToJoin}
        edit={goToEditPage}
        isTeamCreator={isTeamAdmin}
      />
      <Modal open={openConfirm}>
        <ConfirmComponent
          text={
            isTeamAdmin
              ? texts.confirmDeleteTeam
              : texts.sureToDeleteYourselveFromTeam
          }
          fnYes={confirmRemoveMyselfFromTeam}
          fnNo={() => setOpenConfirm(false)}
          fnCancel={() => setOpenConfirm(false)}
        ></ConfirmComponent>
      </Modal>
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    suggestedUsers: state.UserReducer?.usersList,
    myTeams: state.MyTeamsReducer,
    allTeams: state.AllTeamsReducer,
    myProfile: state.MyProfileReducer.profile,
    userEvents: state.AuthEventsReducer.eventList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: () => dispatch(myProfileApi()),
  onUploadPictureApi: (data) => dispatch(uploadPictureApi(data)),
  onRequestToJoinTeam: (data) => dispatch(requestToJoinTeam(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeamProfile);

import React from "react";
import {
  Paragraph,
  Input,
  Heading,
  Flex,
  Button,
  Textarea,
} from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, ErrorInfo, SteppButtons } from "../../molecules";

const GiveYourGameAName = (props) => {
  const {
    fnCancel,
    next,
    setGameName,
    gameName,
    error,
    stepNumber,
    gameDetails,
    setGameDetails,
    isUpdateMatch,
    deleteEvent,
  } = props;
  return (
    <Flex className={["tw-h-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
      <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
      <Flex className={["tw-px-8 tw-py-4 md:tw-w-mmd md:tw-m-auto"]}>
        <Heading className={[" tw-items-center"]}>{texts.addGameName}</Heading>
        <Paragraph className={["tw-w-36"]} muted={false} size="xs">
          {texts.enterFriendlyName}
        </Paragraph>
        <Input
          placeholder={texts.gameName}
          className={["tw-mb-2"]}
          rounded="lg"
          size="xxs"
          maxLength={30}
          onChange={(event) => setGameName(event.target.value)}
          value={gameName ? gameName : ""}
        />
        {error && <ErrorInfo error={error} />}
        <Paragraph className={["tw-w-full tw-pt-4"]} muted={false} size="xxs">
          {texts.matchAdditionalInfo}
        </Paragraph>
        <Textarea
          className={["tw-h-28"]}
          rounded="lg"
          placeholder={texts.textAreaMatchAdditionalInfo}
          size="xxs"
          value={gameDetails || undefined}
          onChange={(event) => setGameDetails(event.target.value)}
        ></Textarea>

        {/* Currently we are not implememnting this functionality. Maybe at a later stage. */}
        {/* {isUpdateMatch && (
          <Button
            quinternary={true}
            className={["tw-mt-4 tw-text-sm tw-border-2 tw-border-white"]}
            onClick={() => {}}
          >
            {texts.closeRegistrations}
          </Button>
        )} */}
        <Flex className={["tw-pt-4"]}></Flex>
        <Button
          size="base"
          className={[""]}
          onClick={next}
        >
          {texts.next}
        </Button>
        {isUpdateMatch && (
          <Button
            quinternary={true}
            className={["tw-mt-8 tw-text-sm tw-colors-red"]}
            size="sm"
            textColor={"red"}
            onClick={deleteEvent}
          >
            {texts.cancelMatch}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default GiveYourGameAName;

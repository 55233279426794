import * as Action from "./actions";
import NewStoreState from "../newStoreState";

export function FriendListReducer(state = NewStoreState.friends, action) {
  let friends = { ...state };
  switch (action.type) {
    case Action.FRIEND_STATUS:
      friends.friend = action.payload.data;
      return friends;

    default:
      return state;
  }
}

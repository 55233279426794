import React from "react";
import classNames from "classnames";

const Nav = (props) => {
  const { className = [], children } = props;
  const navClass = classNames(...className);

  return <nav className={navClass}>{children}</nav>;
};

export default Nav;

import React from "react";
import { Flex, Img } from "../../atoms";

const HeaderV4Images = (props) => {
  const {
    navigate,
    toggleMenu,
    isLoggedIn,
    unreadMessages,
    goToHome,
  } = props;
  return (
    <Flex direction="row" className={["tw-justify-between"]}>
      <Img
        src={"/img/humburger.png"}
        title="mainHeaderLogo"
        alt="Logo"
        className={["tw-h-4 tw-mt-2", "tw-cursor-pointer", "menu-btn"]}
        onClick={toggleMenu}
      />
      <Img
        src={"/img/logo.png"}
        title="mainHeaderLogo"
        alt="Logo"
        className={[
          "tw-h-auto tw-w-14 tw-relative",
          "tw-cursor-pointer",
        ]}
        onClick={goToHome}
      />
      {isLoggedIn && (
        <Flex
          direction="row"
          className={[
            "tw-justify-between tw-relative ",
            "tw-cursor-pointer",
          ]}
        >
          <Img
            src={"/img/GlobalChat.png"}
            title="GlobalChat"
            alt="GlobalChat"
            className={["tw-h-6 tw-mt-2", "tw-cursor-pointer"]}
            onClick={(e) => navigate(e, "/chat")}
          />
          {unreadMessages && unreadMessages > 0 ? (
            <svg class="button change-my-color tw-h-3 tw-relative tw-right-4 tw-top-2 tw-cursor-pointer tw-object-cover" expanded="true" height="100px" width="15px">
              <circle cx="50%" cy="50%" r="3px"></circle>
              <circle class="pulse" cx="50%" cy="50%" r="5px"></circle>
            </svg>
          ) : (
            <></>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderV4Images;

import React, { useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { HeaderContext } from "contexts/headerContext";
import {
  setLoginModalState,
  setLoginModalErrorState,
} from "combineReducer/modal/actions";
import { getCookie } from "services/cookies";
import { fbOauth } from "services/auth";
import { authGameEvents, myEvents } from "services/events";
import { unauthGameEvents } from "services/events/unauth";
import {
  HEADER_SIZES,
  IOS_BOTTOM_NAVIGATOR_SIZE,
  DEFAULT_BOTTOM_NAVIGATOR_SIZE,
} from "../constants";
import { getMobileOS, isTeamVSteam } from "utils/helper";
import { MOBILE_OS } from "utils/const";
import {
  GameCard,
  OrganiseMatchButton,
  TeamVSteamCard,
  BookAPitchButton,
} from "components/molecules";
import HeaderComponent from "../header";
import Spinner from "spinner";
import { USER_EVENT_DEFAULT_PAGE_SIZE } from "services/constants";
import { headerVersions, headerGameTypes } from "../header/constants";
import { Flex, Button } from "../../components/atoms";
import { texts } from "Text";
import { toast } from "react-toastify";
import Header from "containers/header/index";

function LandingComponent(props) {
  const {
    history,
    fbAuth,
    onUnauthGameEvents,
    onAuthGameEvents,
    unauthGamesEvent,
    userEvents,
    authGamesEvent,
    selectedEvent,
    tokenReducer,
    profile,
  } = props;

  const dispatch = useDispatch();
  const setLoginModalOpen = (data) => dispatch(setLoginModalState(data));
  const setLoginModalErrorOpen = (data) =>
    dispatch(setLoginModalErrorState(data));

  const headerContext = useContext(HeaderContext);

  const myProfileData = profile;

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(true);
  const [pageNum, setPageNum] = React.useState(1);
  const [eventList, setEventList] = React.useState([]);
  const [myEventsList, setMyEventsList] = React.useState([]);

  React.useEffect(() => {
    if (headerContext.headerGameType == headerGameTypes.MYGAMES) {
      setEventList(myEventsList || []);
      return;
    }
    if (isLoggedIn) {
      onAuthGameEvents({
        includePastEvents: true,
        pageNum: 1,
        USER_EVENT_DEFAULT_PAGE_SIZE,
        type: headerContext.headerGameType,
        setUpdate: true,
      }).then(() => {
        setIsLoader(false);
        if (pageNum !== 1) {
          setPageNum(1);
        }
      });
    } else {
      onUnauthGameEvents({
        includePastEvents: true,
        pageNum: 1,
        USER_EVENT_DEFAULT_PAGE_SIZE,
        type: headerContext.headerGameType,
        setUpdate: true,
      }).then(() => setIsLoader(false));
    }
  }, [headerContext.headerGameType]);

  React.useEffect(() => {
    var flattenedEventsList = userEvents?.map((event) => {
      return {
        isOrganizer: event?.isOrganizer,
        status: event?.status,
        ...event?.eventResponse,
      };
    });
    setMyEventsList(flattenedEventsList);
  }, [userEvents]);

  // Removed requirement.
  const headerheight = () => {
    const baseHeight = () => {
      return HEADER_SIZES.MINIMISED_LOGGED_IN;
    };

    return getMobileOS() === MOBILE_OS.IOS_SAFARI
      ? `${baseHeight() + IOS_BOTTOM_NAVIGATOR_SIZE}px`
      : `${baseHeight() + DEFAULT_BOTTOM_NAVIGATOR_SIZE}px`;
  };

  const handleScroll = (e) => {
    const { stopLoadEvents } = props;
    if (
      e &&
      !isLoader &&
      !stopLoadEvents &&
      e.target.scrollTop + 1 + e.target.clientHeight >= e.target.scrollHeight
    ) {
      setPageNum(pageNum + 1);
    }
  };

  const gotToEvent = (value) => {
    let event = eventList.find((event) => event.id === value);

    history.push({
      pathname: `/event/${value}`,
      state: { data: event },
    });
  };

  React.useEffect(() => {
    //We can check if we are logged in with checking if we either have the token from FbAuth
    //or if we have leetUserToken cookie from email auth.
    const cookie = getCookie("leetUserToken") || tokenReducer?.token;
    if (!cookie) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);

    props.myEvents({ isJoined: "all" });
    headerContext.setHeaderGameType(headerGameTypes.OPENGAME);
  }, []);

  React.useEffect(() => {
    if (!isLoggedIn) {
      setEventList(unauthGamesEvent?.eventList || []);
    } else {
      if (headerContext.headerGameType == headerGameTypes.MYGAMES) {
        var flattenedEventsList = userEvents?.map((event) => {
          return {
            isOrganizer: event?.isOrganizer,
            status: event?.status,
            ...event?.eventResponse,
          };
        });
        setMyEventsList(flattenedEventsList);
      } else {
        const eventlist =
          authGamesEvent?.eventList?.map((event) => {
            return {
              isOrganizer: event?.isOrganizer,
              status: event?.status,
              ...event?.eventResponse,
            };
          }) || [];
        setEventList(eventlist);
      }
    }
  }, [authGamesEvent, unauthGamesEvent]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      onUnauthGameEvents({
        includePastEvents: true,
        pageNum,
        USER_EVENT_DEFAULT_PAGE_SIZE,
        type: headerContext.headerGameType,
        setUpdate: false,
      }).then(() => setIsLoader(false));
    } else {
      if (headerContext.headerGameType == headerGameTypes.MYGAMES) {
        props
          .myEvents({
            isJoined: "all",
            includePastEvents: true,
            pageNum,
            USER_EVENT_DEFAULT_PAGE_SIZE,
            type: headerContext.headerGameType,
            setUpdate: false,
          })
          .then(() => setIsLoader(false));
      } else {
        onAuthGameEvents({
          includePastEvents: true,
          pageNum,
          USER_EVENT_DEFAULT_PAGE_SIZE,
          type: headerContext.headerGameType,
          setUpdate: false,
        }).then(() => setIsLoader(false));
      }
    }
  }, [pageNum]);

  React.useEffect(() => {
    if (isLoggedIn) {
      let apiCalls = [
        onAuthGameEvents({
          includePastEvents: true,
          pageNum,
          USER_EVENT_DEFAULT_PAGE_SIZE,
          type: headerGameTypes.OPENGAME,
          setUpdate: pageNum == 1,
        }).then(() => setIsLoader(false)),
      ];
      Promise.all(apiCalls).then(() => setIsLoader(false));
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let code = params.get("code");
    if (code) {
      if (!isLoggedIn) {
        history.push("/");
        fbAuth(code).then((error) => {
          if (error?.status >= 400 && error?.status <= 499) {
            setLoginModalErrorOpen(true);
            setLoginModalOpen(true);
            return;
          } else {
            toast.error(texts.fbLoginError);
          }
          window.location.href = "/";
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedEvent]);

  return (
    <Flex className={["tw-h-full tw-w-full"]}>
      <Header
        headerVersion={headerVersions.version1}
        onTypeChange={headerContext.setHeaderGameType}
      />
      {/*  Todo: FixSpinner */}
      {isLoader ? (
        <Flex className={["tw-m-auto"]}>
          <Spinner />
        </Flex>
      ) : (
        <Flex
          className={[
            "fade show tw-px-4 tw-pt-2 tw-h-auto tw-bg-white",
            "tw-rounded-2xl tw-relative tw-bottom-4 md:tw-bottom-0 md:tw-w-mmd md:tw-m-auto",
          ]}
        >
          <Flex
            className={[`tw-overflow-scroll`]}
            style={{ height: `calc(100vh - ${headerheight()})` }}
            onScroll={handleScroll}
          >
            {eventList && eventList.length > 0
              ? eventList?.map((event, idx) => {
                  return isTeamVSteam(event) ? (
                    <TeamVSteamCard
                      showEvent={gotToEvent}
                      data={event}
                      key={idx}
                    />
                  ) : (
                    <GameCard showEvent={gotToEvent} data={event} key={idx} />
                  );
                })
              : texts.noEventsFound}
          </Flex>
          <Flex className={["tw-justify-center tw-pt-4"]}>
            <Button
              ninetenary
              onClick={() => {
                history.push("/organize-match");
              }}
              className={["tw-w-full im-looking-to"]}
            >
              {texts.imLookingTo}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

const mapStateToProps = (state) => {
  return {
    unauthGamesEvent: state.UnauthEventsReducer,
    authGamesEvent: state.AuthEventsReducer,
    tokenReducer: state.TokenReducer,
    selectedEvent: state.gameEvent,
    stopLoadEvents: state.AuthEventsReducer.stopLoadEvents,
    profile: state.MyProfileReducer.profile,
    userEvents: state.AuthEventsReducer.myEventList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUnauthGameEvents: (data) => dispatch(unauthGameEvents(data)),
  onAuthGameEvents: (data) => dispatch(authGameEvents(data)),
  fbAuth: (data) => dispatch(fbOauth(data)),
  setLoginModalOpen: (data) => dispatch(setLoginModalState(data)),
  setLoginModalErrorOpen: (data) => dispatch(setLoginModalErrorState(data)),
  myEvents: (data) => dispatch(myEvents(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LandingComponent);

import React from "react";
import { Button, Flex } from "components/atoms";
import { texts } from "Text";
import TeamCard from "../../molecules/teams/teamCard";

const ShowTeamsButtons = (props) => {
  const { data, onClick } = props;

  const teamOneName = data?.sides[0]?.team?.name;
  const TeamTwoName = data?.sides[1]?.team?.name;

  return (
    <Flex className={["tw-w-full tw-mt-6"]}>
      <TeamCard
        onClick={() => {}}
        team={data?.sides[0]?.team}
        isTeamCreator={false}
        goToTeamDetails={() => onClick(data?.sides[0]?.team?.id)}
        showNumPlayers={false}
      ></TeamCard>
       {data?.sides[1].team && 
        (<TeamCard
          onClick={() => {}}
          team={data?.sides[1]?.team}
          isTeamCreator={false}
          goToTeamDetails={() => onClick(data?.sides[1]?.team?.id)}
          showNumPlayers={false}
        ></TeamCard>)
      }
    </Flex>
  );
};

export default ShowTeamsButtons;

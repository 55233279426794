import React from "react";
import classNames from "classnames";
import { textSizes } from "../textSizes";
import { marginBottomList } from "../marginAndPadding";

const Input = React.forwardRef((props, ref) => {
  const {
    name,
    onBlur,
    onChange,
    type = "text",
    rounded = "2xl",
    placeholder,
    maxLength,
    min,
    max,
    className = [],
    value,
    size = "base",
    defaultValue,
    marginBottom = "mbsm",
    baseHeight = true,
  } = props;

  const inputClass = classNames(
    "tw-px-4",
    "tw-py-4",
    `tw-rounded-${rounded}`,
    `${textSizes[size]}`,
    `${marginBottomList[marginBottom]}`,
    { "tw-h-10": baseHeight },
    "focus:tw-outline-none",
    ...className
  );

  return (
    <input
      ref={ref}
      name={name}
      type={type}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={maxLength}
      min={min}
      max={max}
      className={inputClass}
      value={value}
      defaultValue={defaultValue}
    />
  );
});

export default Input;

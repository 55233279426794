import * as Action from "./actions";
import NewStoreState from "../newStoreState";

export function TokenReducer(state = NewStoreState.token, action) {
  let token = { ...state }; //Create new object for updating components.
  switch (action.type) {
    case Action.SET_TOKEN:
      token = action.payload;
      return token;
    default:
      return state;
  }
}

import React, { Suspense, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { HeaderContext } from "contexts/headerContext";

import "./index.css";

import ScrollIntoView from "./ScrollIntoView";
import Spinner from "./spinner";
import { useLocation } from "react-router-dom";
import RouteList from "./routeList";

import { myProfileApi } from "services/profile";
import { getCookie } from "services/cookies";
import { chatToken } from "services/chat";
import { initChat } from "services/chat";
import PrivateRoute from "deprecated/privateRoute";

import { setUnreadMessages } from "combineReducer/chat/actions";
import Notifications from "containers/notifications/toast";
import { headerGameTypes } from "containers/header/constants";

function App(props) {
  const { history, profile, userChat, fetchProfile, onInitChat } = props;
  const { token } = userChat;
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState("");
  const [chatClient, setChatClient] = useState(null);
  const [headerMinimised, setHeaderMinimised] = useState(false);
  const [headerSearchTerm, setHeaderSearchTerm] = useState("");
  const [headerPlayerType, setHeaderPlayerType] = useState("");
  const [headerGameType, setHeaderGameType] = useState(
    headerGameTypes.OPENGAME
  );

  const myProfileData = profile;
  const profileId = myProfileData.id;

  const initChat = (id) => {
    onInitChat(id, token).then((client) => {
      setChatClient(client);
    });
  };

  React.useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  });

  React.useEffect(() => {
    if (location && Object.keys(location).length > 0) {
      let search = location.pathname.search("/event/");

      if (search > -1) {
        setCurrentRoute("publicEventDetail");
      } else {
        setCurrentRoute(location.pathname);
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (!getCookie("leetUserProfile")) return;

    if (chatClient) {
      setChatClient();
      chatClient.disconnect();
    }
    if (!profileId) {
      fetchProfile().then(({ id }) => initChat(id));
    } else {
      initChat(profileId);
    }
  }, [profile]);

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <HeaderContext.Provider
          value={{
            headerMinimised,
            setHeaderMinimised,
            headerSearchTerm,
            setHeaderSearchTerm,
            headerPlayerType,
            setHeaderPlayerType,
            headerGameType,
            setHeaderGameType,
          }}
        >
          <ScrollIntoView />
          <Switch>
            {RouteList.map(({ isPrivate, exact, ...rest }, idx) =>
              isPrivate ? (
                <PrivateRoute
                  key={idx}
                  {...rest}
                  history={history}
                  chatClient={chatClient}
                  exact={exact}
                />
              ) : (
                <Route key={idx} exact={exact} {...rest} history={history} />
              )
            )}
          </Switch>
        </HeaderContext.Provider>
      </Suspense>
      <Notifications />
    </>
  );
}
const mapStateToProps = (state) => ({
  gameEvent: state.gameEvent,
  signUpModalOpen: state.modal,
  userChat: state.ChatReducer,
  profile: state.MyProfileReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  fetchProfile: () => dispatch(myProfileApi()),
  getChatToken: () => dispatch(chatToken()),
  setUnreadMessages: (data) => dispatch(setUnreadMessages(data)),
  onInitChat: (data) => dispatch(initChat(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";
import { texts } from "../../../Text/index";
import { Button, Flex } from "components/atoms";

const PostMatchButtons = (props) => {
  const {
    isOrganiser,
    checkFeedback,
    sendFeedback,
    sendResults,
    isEventPlayer,
  } = props;
  return (
    <Flex>
      {isOrganiser ? (
        <Flex
          direction="row"
          className={["tw-pt-4 tw-items-center tw-justify-between"]}
        >
          <Button
            ninetenary
            onClick={checkFeedback}
            className={["tw-w-60"]}
            size="base"
          >
            {texts.checkFeedback}
          </Button>

          <Button onClick={sendResults} size="base" className={["tw-w-30"]}>
            {texts.submit}
          </Button>
        </Flex>
      ) : (
        <Flex
          direction="row"
          className={["tw-pt-4 tw-items-center tw-justify-between"]}
        >
          <Button
            onClick={sendFeedback}
            className={["tw-w-full"]}
            disabled={!isEventPlayer}
          >
            {texts.submit}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default PostMatchButtons;

import React from "react";
import HeaderV4Images from "../../molecules/header/hedaerV4imges";
import RadioButtons from "../../molecules/general/radioButtons";
import { Flex, Input, Nav } from "../../atoms";

const ClosedHeader = (props) => {
  const {
    toggleMenu,
    goToHome,
    navigate,
    isLoggedIn,
    unreadMessages,
    onSearchChange,
    placeholder,
    radioButtons,
    radioButtonsName,
    onRadioButtonChange
  } = props;

  return (
    <Nav
      className={[
        "tw-relative tw-bottom-1 tw-bg-header-background-img md:bg-none",
      ]}
    >
      <Flex className={["tw-w-full tw-p-4 md:tw-w-mmd md:tw-m-auto"]}>
        <HeaderV4Images
          toggleMenu={toggleMenu}
          goToHome={goToHome}
          navigate={navigate}
          isLoggedIn={isLoggedIn}
          unreadMessages={unreadMessages && unreadMessages > 0}
        />

        {onSearchChange && (
          <Input
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder={placeholder}
            className={["tw-mt-4"]}
            rounded="xl"
          />
        )}

        {radioButtons && (
          <RadioButtons 
            onChange={onRadioButtonChange}
            name={radioButtonsName}
            buttons={radioButtons} />
        )}
      </Flex>
    </Nav>
  );
};

export default ClosedHeader;

export const SET_CHAT_TOKEN = "SET_CHAT_TOKEN";
export const SET_UNREAD_MESSAGE = "SET_UNREAD_MESSAGE";

export function setUserChatToken(data) {
  return {
    type: SET_CHAT_TOKEN,
    payload: data,
  };
}
export function setUnreadMessages(data) {
  return {
    type: SET_UNREAD_MESSAGE,
    payload: data,
  };
}

import { SET_CHAT_TOKEN, SET_UNREAD_MESSAGE } from "./actions";
import NewStoreState from "../newStoreState";

export function ChatReducer(state = NewStoreState.chat, action) {
  switch (action.type) {
    case SET_CHAT_TOKEN:
      return { ...state, token: action.payload };
    case SET_UNREAD_MESSAGE:
      return { ...state, unreadMessages: action.payload };
    default:
      return state;
  }
}

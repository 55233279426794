import React from "react";
import { Link, Img, Flex, CloseButton } from "../../atoms";
import Modal from "deprecated/modal";
import HeaderMenuButtons from "../../molecules/header/headerMenuButtons";
import MenuItems from "../../molecules/header/menuItems";

function OpenHeaderMenu(props) {
  const {
    navigate,
    friendListLength,
    unreadMessages,
    toggle,
    toggleMenu,
    isLoggedIn,
    fnSignupSettings,
    fnLogInOut,
    goToHome
  } = props;
  return (
    <Modal open={toggle} setOpen={true} fullscreen={true}>
      <Flex
        className={["tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50"]}
      >
        <CloseButton className={["tw-p-4 tw-self-end"]} onClick={toggleMenu} />
        <Link to="/">
          <Img src={"/img/logo.png"} title="mainHeaderLogo" alt="Logo" onClick={goToHome}/>
        </Link>
        <MenuItems
          unreadMessages={unreadMessages}
          friendListLength={friendListLength}
          navigate={navigate}
        />
        <HeaderMenuButtons
          fnLogInOut={fnLogInOut}
          isLoggedIn={isLoggedIn}
          fnSignupSettings={fnSignupSettings}
        />
      </Flex>
    </Modal>
  );
}

export default OpenHeaderMenu;

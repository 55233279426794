export const MY_PROFILE = "MY_PROFILE";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const SETTINGS = "SETTINGS"

export function myProfile(data) {
  return {
    type: MY_PROFILE,
    payload: data,
  };
}

export function uploadProfileImage(data) {
	return {
		type: PROFILE_IMAGE,
		payload: data,
	};
}

export function settings(data) {
  return {
    type: SETTINGS,
    payload: data,
  };
}
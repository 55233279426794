import React from "react";
import classNames from "classnames";

const Textarea = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    rows = 1,
    cols = 1,
    rounded = "2xl",
    onBlur,
    onChange,
    placeholder,
    maxLength,
    className = [],
    size = "sm",
    value,
    defaultheight = true,
  } = props;

  const textareaClass = classNames(
    "tw-px-4",
    "tw-py-4",
    `tw-rounded-${rounded}`,
    { "tw-h-10": defaultheight },
    "focus:tw-outline-none",
    `tw-text-${size}`,
    ...className
  );

  return (
    <textarea
      id={id}
      rows={rows}
      cols={cols}
      ref={ref}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={maxLength}
      className={textareaClass}
      value={value}
    />
  );
});

export default Textarea;

import React, { useState, useEffect } from "react";
import {
  BasePage,
  Flex,
  Heading,
  Button,
  Paragraph,
  Span,
  Img,
  Thumbnail,
} from "components/atoms";
import {
  Banner,
  Rating,
  Calendar,
  TeamVSteamBanner,
  ShowTeamsButtons,
} from "components/molecules";
import HeaderComponent from "../../../containers/header";
import { headerVersions } from "../../../containers/header/constants";
import { texts } from "Text";
import { userEventStatus } from "../../../containers/constants";
import moment from "moment";

const MatchdDetailsPage = ({
  matchDetails,
  joinMatchClick,
  userStatus,
  isOrganiser,
  isRequestedToJoinMatch,
  cancelMatchClick,
  checkFeedback,
  goToEventTeams,
  goToplayerProfile,
  addToCall,
  editMatch,
  organiserName,
  acceptInvite,
  isTeamVSteam,
  goToTeam,
  manageUsers,
}) => {
  const playerPlayingCount =
    matchDetails?.users?.filter((x) => x.status.toLowerCase() === "joined")
      .length || 0;
  const availableSpaces = matchDetails?.maxPlayers - playerPlayingCount || 0;
  const date = moment.utc(matchDetails?.dateTime).local();

  const pendingUsers = matchDetails?.users?.filter(
    (user) => user?.status.toLowerCase() === 'invited' || user?.status.toLowerCase() === "requestedtojoin"
  );

  const joinedUsers = matchDetails?.users?.filter(
    (user) => user?.status.toLowerCase() === 'joined'
  );

  return (
    <BasePage>
      <HeaderComponent
        headerText={texts.matchDetails}
        headerVersion={headerVersions.version3}
      />
      <Flex className={["tw-rounded-3xl", "tw-bg-blue"]}>
        {isTeamVSteam ? (
          <TeamVSteamBanner data={matchDetails} onClick={goToEventTeams} />
        ) : (
          <Banner
            title={matchDetails?.name}
            caption={`organised by ${organiserName}`}
            src={"/img/match-details-banner.png"}
          />
        )}
        {!isTeamVSteam && (
          <Flex
            direction="row"
            className={["tw-py-4 tw-px-6 tw-justify-between tw-items-center"]}
          >
            <Heading size={true} marginBottom={0} className={["tw-text-sm"]}>
              {`${availableSpaces} Spaces available`}
            </Heading>
            <Button
              onClick={goToEventTeams}
              size={"xsm"}
              className={[
                "tw-px-2 tw-border-2 tw-rounded-2xl tw-border-white tw-text-white tw-text-sm ",
              ]}
            >
              {texts.eventTeams}
            </Button>
          </Flex>
        )}
      </Flex>

      {!isOrganiser &&
        (userStatus === userEventStatus.REQUESTED_TO_JOIN ||
          userStatus === userEventStatus.JOINED) && (
          <Button
            secondary={true}
            onClick={cancelMatchClick}
            className={["tw-my-4"]}
          >
            {texts.cancelJoining}
          </Button>
        )}
      {!isOrganiser && userStatus === userEventStatus.NOT_PLAYING && (
        <Button onClick={joinMatchClick} className={["tw-my-4"]}>
          {texts.joinAndRsvp}
        </Button>
      )}
      {!isOrganiser && userStatus === userEventStatus.INVITED && (
        <Flex direction="row" className={["tw-justify-between tw-mt-4"]}>
          <Button onClick={acceptInvite} className={["tw-w-45"]} size="base">
            {texts.acceptInvite}
          </Button>
          <Button
            secondary
            onClick={cancelMatchClick}
            size="base"
            className={["tw-w-45"]}
          >
            {texts.cancel}
          </Button>
        </Flex>
      )}

      {isOrganiser && (
        <Flex className={[""]}>
          <Button onClick={editMatch} className={["tw-my-4"]}>
            {texts.editMatch}
          </Button>
          <Flex className={[""]}>
          <Button tertiary onClick={manageUsers} className={[""]}>
            {texts.manageUsers}
          </Button>
          {isRequestedToJoinMatch &&  (
            <svg class = "button change-my-color tw-h-6 tw-w-6 tw-absolute tw--right-[8px] tw--top-[8px] tw-cursor-pointer tw-object-cover" expanded = "true" height = "5px" width = "25px">
            <circle cx = "50%" cy = "50%" r = "7px"></circle>
            <circle class = "pulse" cx = "50%" cy = "50%" r = "9px"></circle>
          </svg>
          )} 
          </Flex>
        </Flex>
      )}
      <Flex direction="row" className={["tw-items-center", "tw-py-4"]}>
        <Heading
          color="black"
          size="lg"
          className={["tw-flex-grow"]}
          marginBottom={0}
        >
          {texts.aboutThisMatch}
        </Heading>
        <Rating value={3} />
      </Flex>
      <Paragraph size="xs" color="black">
        {matchDetails?.description}
      </Paragraph>
      <Calendar date={date} />
      <Flex className={["tw-my-4"]}>
        <Flex direction="row" className={["tw-items-center"]}>
          <Heading
            color="black"
            size="lg"
            className={["tw-flex-grow"]}
            marginBottom={0}
          >
            {texts.matchDetails}
          </Heading>
          <Button size="sm" onClick={() => addToCall(true)}>
            {texts.addToCall}
          </Button>
        </Flex>
        <Flex direction="row" className={["tw-flex-wrap"]}>
          <Flex className={["tw-w-2/4", "tw-py-4"]}>
            <Span color="black" size="sm">
              <Img
                src="/img/calendar-icon.svg"
                className={["tw-inline-flex", "tw-pr-2"]}
                width={24}
                height={24}
              />
              {date?.format("ll")}
            </Span>
          </Flex>
          <Flex className={["tw-w-2/4", "tw-py-4"]}>
            <Span color="black" size="sm">
              <Img
                src="/img/play-icon.svg"
                className={["tw-inline-flex", "tw-pr-2"]}
                width={24}
                height={24}
              />
              {`${playerPlayingCount} Players playing`}
            </Span>
          </Flex>
          <Flex className={["tw-w-2/4"]}>
            <Span color="black" size="sm">
              <Img
                src="/img/clock-icon.svg"
                className={["tw-inline-flex", "tw-pr-2"]}
                width={24}
                height={24}
              />
              {date?.format("h:mm a")}
            </Span>
          </Flex>
          <Flex className={["tw-w-2/4"]}>
            <Span color="black" size="sm">
              <Img
                src="/img/location-icon.svg"
                className={["tw-inline-flex", "tw-pr-2"]}
                width={24}
                height={24}
              />
              {matchDetails?.location?.name}
            </Span>
          </Flex>
        </Flex>
      </Flex>
      <Flex className={["tw-my-4"]}>
        <Flex direction="row" className={["tw-items-center"]}>
          <Heading
            color="black"
            size="lg"
            className={["tw-flex-grow"]}
            marginBottom={0}
          >
            {isTeamVSteam ? texts.groupsPlaying : texts.playersPlaying}
          </Heading>
        </Flex>

        {isTeamVSteam ? (
          <Flex direction="row" className={["tw-flex-wrap"]}>
            <ShowTeamsButtons data={matchDetails} onClick={goToTeam} />
          </Flex>
        ) : (
          <Flex direction="row" className={["tw-flex-wrap"]}>
            {joinedUsers?.map(({ profilePictureUrl, status, id }) => {
                return (
                <Thumbnail
                  src={profilePictureUrl || "/img/unselected-player.png"}
                  isIncognito={status.toLowerCase() === "invited" || status.toLowerCase() === "requestedtojoin"}
                  onClick={() => goToplayerProfile(id)}
                />
                )
            })}
            {[...Array(availableSpaces).keys()].map(() => (
              <Thumbnail isDisabled />
            ))}
          </Flex>
        )}
      </Flex>
      {isOrganiser && !isTeamVSteam && pendingUsers && pendingUsers.length>0 && (
      <Flex className={["tw-my-4"]}>
        <Flex direction="row" className={["tw-items-center"]}>
          <Heading
            color="black"
            size="lg"
            className={["tw-flex-grow tw-mb-2"]}
            marginBottom={0}
          >
            {"Pending Invited/Requested Players"}
          </Heading>
        </Flex>
          <Flex direction="row" className={["tw-flex-wrap"]}>
            {pendingUsers?.map(({ profilePictureUrl, status, id }) => {
                return (
                <Thumbnail
                  src={profilePictureUrl || "/img/unselected-player.png"}
                  onClick={() => goToplayerProfile(id)}
                />
                )
            })}
          </Flex>
        
      </Flex>
      )}
    </BasePage>
  );
};

export default MatchdDetailsPage;

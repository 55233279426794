import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { HeaderContext } from "contexts/headerContext";
import { playerStatus } from "../constants";
import {
  createTeam,
  getAllTeams,
  addUserToTeam,
  requestToJoinTeam,
  uploadTeamAvatar,
} from "services/teams";
import { uploadPictureApi, myProfileApi } from "services/profile";

import { friendListApi } from "services/users";
import { validateEmail } from "../../utils/helper";
import { getCookie } from "../../services/cookies";
import { Flex } from "components/atoms";
import { citiesApi } from "services/locations";
import RegistrationPage from "components/pages/registration";
import { registrationSteps } from "./constants";
import { texts } from "../../Text/index";
import { createAccount } from "../../services/auth";
import SearchYourTeam from "components/pages/registration/searchYourTeam";
import GiveYourTeamAvatarV2 from "components/pages/registration/giveYourTeamAvatarV2";
import PlayersInMyTeam from "components/pages/teams/playersInMyTeam";
import AddPlayerToTeam from "components/pages/teams/addplayerToTeam";
import SimilarTeamFound from "components/pages/registration/similarTeamFound";

const Registration = (props) => {
  const {
    onLoadCities,
    cities,
    onRegister,
    suggestedPlayerList,
    onSuggestedUsers,
    onGetAllTeams,
    allTeams,
    onJoinTeam,
    onFetchProfile,
    myProfile,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const headerContext = useContext(HeaderContext);

  const [registrationStep, setRegistrationStep] = useState(
    registrationSteps.stepZero
  );
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState({});
  const [teamName, setTeamName] = useState("");
  const [users, setUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [teams, setTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [playerType, setPlayerType] = useState("");

  const validateuserData = (data) => {
    const errors = {};

    if (!data?.userId) {
      errors.userId = texts.userIdIsMissing;
    }
    if (!data.firstName) {
      errors.firstName = texts.firstNameIsMissing;
    }
    if (!data.lastName) {
      errors.lastName = texts.lastNameIsMissing;
    }
    if (!data.dateOfBirth || data.dateOfBirth === "Invalid date") {
      errors.dateOfBirth = texts.dateOfBirthIsMissing;
    }
    if (!data.localityId || data.localityId === "0") {
      errors.localityId = texts.localityIdIsMissing;
    }
    if (!_.isEmpty(errors)) {
      setValidationError(errors);
      return false;
    }
    return true;
  };

  const joinTeam = (teamId) => {
    const data = {
      teamId: teamId,
      userId: myProfile?.id,
    };
    onJoinTeam(data).then((response) => {
      if (response.success) {
        toast.success(texts.requestToJoinTeamSuccess);
      } else {
        toast.error(texts.jointRequestFailed);
      }
    });
  };

  useEffect(() => {
    if (!cities?.length) {
      onLoadCities();
    }
    if (registrationStep !== registrationSteps.stepZero) {
      onSuggestedUsers();
      onGetAllTeams();
      onFetchProfile();
    }
  }, []);

  useEffect(() => {
    const users = suggestedPlayerList?.map((user) => user);
    setUsers(users);
    setTeams(allTeams);
  }, [suggestedPlayerList, allTeams]);

  const onSubmit = (data) => {
    if (!data.terms) {
      setError(texts.termsMustAccept);
      return;
    }
    setError("");

    const day = Number(data?.day) - 1;
    var url = new URL(window.location.href);
    const dateOfBirth = moment
      .utc(`${data?.month}-${day}-${data?.year}`, "MM-DD-YYYY HH:mm:ss")
      .local()
      .format();
    var userId = url.searchParams.get("uid");

    const payload = {
      userId,
      createAccountToken: getCookie("leetCreateAccountToken"),
      firstName: data?.name,
      lastName: data?.surname,
      gender: data?.gender || "Male",
      dateOfBirth: dateOfBirth,
      playerType: data?.playerType || "Player",
      localityId: data?.locality,
      phoneNumber: Number(data?.phone),
      profilePictureURL: data?.profilePictureURL,
      smsNotifications: data?.smsNotifications,
    };
    if (validateuserData(payload)) {
      onRegister(payload).then(() => {
        onSuggestedUsers();
        moveToPage(registrationSteps.stepFour);
      });
    }
  };

  const moveToPage = (registrationStep) => {
    setRegistrationStep(registrationStep);
  };

  const searchOnChange = (value) => {
    onSuggestedUsers({ name: value || "", type: playerType }, true);
    setSearchTerm(value || "");
  };

  const searchPlayerType = (type) => {
    onSuggestedUsers({ name: searchTerm || "", type }, true);
    setPlayerType(type);
  };

  const resetImg = () => {
    setFile(null);
    setImagePreview(null);
  };

  const uploadTeamAvatar = (teamId) => {
    const { onUploadTeamAvatar } = props;
    const data = {
      file,
      teamId,
    };
    onUploadTeamAvatar(data).then((response) => {
      if (response?.success === true) {
        cancel();
        toast.success(texts.newTeamCreated);
      } else {
        cancel();
        toast.error(texts.uploadAvatarFailed);
      }
      resetImg();
    });
  };

  const createTeam = (teamData) => {
    const { onCreateTeam } = props;
    return onCreateTeam(teamData).then((response) => {
      if (response?.success === true) {
        if (file) {
          uploadTeamAvatar(response.data.id);
        } else {
          cancel();
          toast.success(texts.newTeamCreated);
        }
      } else {
        toast.error(texts.createTeamFailed);
      }
    });
  };

  const createTeamSubmit = () => {
    let teamData = {
      name: teamName,
      userIds: teamMembers,
    };
    createTeam(teamData);
  };

  const searchTeam = React.useCallback(
    debounce((value) => {
      onGetAllTeams({ name: value }, true).then((response) => {});
    }, 800),
    []
  );

  const addOrRemoveTeamMember = (member) => {
    const memberId = member?.id;
    if (!teamMembers.includes(memberId)) {
      setTeamMembers((teamMembers) => [...teamMembers, memberId]);
      toast.success(texts.playerSucessfullyAddedToTeam);
    } else {
      const reducedMembers = teamMembers.filter((id) => id !== memberId);
      setTeamMembers(reducedMembers);
      toast.success(texts.playerSucessfullyRemovedFromTeam);
    }
  };

  const cancel = () => {
    history.push("/");
  };

  const validateTeam = () => {
    if (teamName) {
      setError("");
      if (teams && teams.length < 1) {
        // If we dont have teams by the search on given name then we
        // go and create it. Otherwise we suggest to join to an existing one
        // with a similar name.
        moveToPage(registrationSteps.stepSix);
      } else {
        moveToPage(registrationSteps.stepEight);
      }
    } else {
      setError(texts.teamNameIsRequired);
    }
  };

  const goToChat = (user) => {
    if (user && user.id) {
      props.history.push(`/chat/${user.id}`);
    } else {
      toast.error(texts.userNotFound);
    }
  };

  return (
    <Flex className={["tw-h-full tw-w-full"]}>
      <RegistrationPage
        onSubmit={onSubmit}
        cities={cities}
        termsOfUse={() => moveToPage(registrationSteps.stepFour)}
        open={registrationStep === registrationSteps?.stepZero}
        validationError={validationError}
        error={error}
      />
      {registrationStep === registrationSteps?.stepFour && (
        <SearchYourTeam
          teams={teams}
          joinTeam={joinTeam}
          createTeam={() => moveToPage(registrationSteps.stepFive)}
          fnNext={cancel}
          fnBack={() => moveToPage(registrationSteps.stepZero)}
          cancel={cancel}
          onSearchChange={searchTeam}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      )}
      {registrationStep === registrationSteps?.stepFive && (
        <GiveYourTeamAvatarV2
          fnForward={validateTeam}
          backToTeamPage={cancel}
          fnBack={() => moveToPage(registrationSteps.stepFour)}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          setFile={setFile}
          setTeamName={setTeamName}
          teamName={teamName}
          error={error}
          searchTeam={searchTeam}
        />
      )}
      {registrationStep === registrationSteps?.stepSix && (
        <PlayersInMyTeam
          displayDeleteButton={false}
          addOrRemoveTeamMember={addOrRemoveTeamMember}
          players={
            users
              ?.filter((user) => teamMembers.includes(user.user?.id))
              ?.map((user) => user.user) || []
          }
          teamMembers={teamMembers}
          submit={createTeamSubmit}
          backToTeamPage={cancel}
          goToAddAPlayer={() => moveToPage(registrationSteps.stepSeven)}
          fnBack={() => moveToPage(registrationSteps.stepFive)}
        ></PlayersInMyTeam>
      )}
      {registrationStep === registrationSteps?.stepSeven && (
        <AddPlayerToTeam
          backToTeamPage={cancel}
          players={users || []}
          teamMembers={teamMembers}
          addOrRemoveTeamMember={addOrRemoveTeamMember}
          submit={createTeamSubmit}
          fnBack={() => moveToPage(registrationSteps.stepSix)}
          onSearchChange={searchOnChange}
          onTypeChange={searchPlayerType}
        />
      )}
      {registrationStep === registrationSteps?.stepEight && (
        <SimilarTeamFound
          teams={teams}
          fnBack={() => moveToPage(registrationSteps.stepSix)}
          doneRegistration={cancel}
          joinTeam={joinTeam}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  cities: state.LocationsReducer.cities,
  suggestedPlayerList: state.UserReducer?.usersList,
  tokenReducer: state.TokenReducer,
  allTeams: state.AllTeamsReducer,
  myProfile: state.MyProfileReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadCities: () => dispatch(citiesApi()),
  onRegister: (data) => dispatch(createAccount(data)),
  onCreateTeam: (data) => dispatch(createTeam(data)),
  onUploadPictureApi: (data) => dispatch(uploadPictureApi(data)),
  onSuggestedUsers: (data, refreshdata) =>
    dispatch(friendListApi(data, refreshdata)),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
  onJoinTeam: (data) => dispatch(requestToJoinTeam(data)),
  onFetchProfile: () => dispatch(myProfileApi()),
  onUploadTeamAvatar: (data) => dispatch(uploadTeamAvatar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);

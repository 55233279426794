import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Input, Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, TeamCard } from "../../molecules";
import SteppButtons from "../../molecules/general/steppButtons";

const SearchYourTeam = (props) => {
  const {
    teams,
    cancel,
    fnBack,
    onSearchChange,
    createTeam,
    fnNext,
    joinTeam,
    setSearchTerm,
    searchTerm,
  } = props;
  return (
    <Modal open={true} fullscreen={true}>
      <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
        <Flex className={["tw-h-full tw-w-full tw-bg-black tw-z-50 md:tw-w-mmd md:tw-m-auto"]}>
          <BlackModalTopBar onClick={cancel} />
          <Flex className={["tw-h-48 tw-px-12"]}>
            <Heading className={[" tw-items-center"]}>
              {texts.searchYourTeam}
            </Heading>
            <Paragraph className={["tw-w-full"]} muted={false} size="xs">
              {texts.insertTeamNameText}
            </Paragraph>
            <Input
              placeholder={texts.insertTeamNamePlaceholder}
              value={searchTerm || ""}
              onChange={(e) => {
                onSearchChange(e.target.value);
                setSearchTerm(e.target.value);
              }}
            ></Input>

            <Flex className={["tw-h-48 tw-mb-4 tw-overflow-auto"]}>
              {teams && teams.length > 0 ? (
                teams.map((team) => {
                  return (
                    <TeamCard
                      onClick={() => joinTeam(team ?.id)}
                      team={team}
                      isJoin={true}
                    ></TeamCard>
                  );
                })
              ) : (
                  <Flex
                    direction="row"
                    className={[
                      "tw-py-4 tw-mt-2 tw-text-white tw-text-xxs tw-justify-center tw-align-center",
                    ]}
                  >
                    {texts.teamNotFoundV2}
                  </Flex>
                )}
            </Flex>
            <Button
              ninetenary
              textColor={"white"}
              rounded="xl"
              className={["tw-my-2 tw-text-sm"]}
              onClick={createTeam}
            >
              {texts.createNewTeam}
            </Button>
            <SteppButtons
              fwdText={texts.finish}
              fnBack={fnBack}
              fnForward={fnNext}
            ></SteppButtons>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SearchYourTeam;

import React from "react";
import { texts } from "../../../Text/index";
import { Button, Flex } from "components/atoms";

const SteppButtons = (props) => {
  const { fwdText, fnBack, fnForward } = props;
  return (
    <Flex
    direction="row"
    className={["tw-bg-black tw-w-full tw-justify-between tw-mt-6"]}
  >
    <Button
      tertiary={true}
      className={["tw-w-45"]}
      size="base"
      onClick={fnBack}
    >
      {texts.back}
    </Button>
    <Button
      size="base"
      className={["tw-w-45"]}
      onClick={fnForward}
    >
      {fwdText}
    </Button>
  </Flex>
  );
};

export default SteppButtons;

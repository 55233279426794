import React from "react";
import classNames from "classnames";

const Label = (props) => {
  const { required = false, className = [], children } = props;
  const labelClass = classNames(
    "tw-text-white",
    "tw-text-base",
    "tw-mb-4",
    ...className
  );

  return (
    <label className={labelClass}>
      {children} {required && <span className="tw-text-error">*</span>}
    </label>
  );
};

export default Label;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { eventDetailById } from "services/events";
import {
  eventJoin,
  eventRequestLeave,
  eventJoinWithTeam,
  eventAcceptInvite,
  eventAcceptInviteAsTeam,
  eventRequestAccept,
  eventRequestRemove,
} from "services/eventUser";
import { getCookie } from "services/cookies";
import { getTeams } from "services/teams";
import { setLoginModalState } from "combineReducer/modal/actions";
import { isPostMatch, isTeamVSteam } from "../../utils/helper";
import { texts } from "../../Text/index";
import { Flex } from "components/atoms";
import MatchdDetailsPage from "components/pages/match";
import ManageUsers from "components/pages/match/manageUsers";
import EventCalendar from "components/pages/match/calendar/eventCalendar";
import HowAreYouJoining from "components/pages//match/howAreYouJoining";
import { flowSteps as joinMatchSteps } from "../constants";
import { myProfileApi } from "services/profile";
import { userEventStatus } from "../../containers/constants";
import moment from "moment-timezone";

const Match = (props) => {
  const {
    myProfile,
    getEventDetailById,
    fetchProfile,
    eventJoin,
    onGetTeams,
    teams,
    onEventRequestLeave,
    eventTeamJoin,
    tokenReducer,
    setLoginModalOpen,
    selectedEvent,
    onEventAcceptInvite,
    onEventAcceptInviteAsTeam,
    userStatus,
    isOrganizer,
    onEventRequestAccept,
    onEventRequestRemove,
  } = props;

  let { eventId } = useParams();
  const history = useHistory();
  const [joinMatchStep, setJoinMatchStep] = useState(0);
  const [selected, setSelected] = useState(null);
  const [calendarToggle, setCalendarToggle] = useState(false);
  const [organiserName, setOrganiserName] = useState(false);
  const [isRequestedToJoinMatch, setisRequestedToJoinMatch] = useState(false);
  const [isAcceptingTeamInvite, setIsAcceptingTeamInvite] = useState(false);

  const isloggedIn = () => {
    const cookie = getCookie("leetUserToken") || tokenReducer?.token;
    if (!cookie) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    getEventDetailById(eventId);
    if (isloggedIn()) {
      onGetTeams();
    }
    if (!myProfile) {
      fetchProfile();
    }
  }, []);

  useEffect(() => {
    if (selectedEvent && selectedEvent.id === eventId) {
      if (isPostMatch(moment.utc(selectedEvent?.dateTime))) {
        history.push(`/post-match/${eventId}`);
        return;
      }

      const requestToJoin = selectedEvent?.users?.find(
        (user) => user.status == userEventStatus.REQUESTED_TO_JOIN
      );
      setisRequestedToJoinMatch(requestToJoin);

      const organiser = selectedEvent?.users?.find(
        (user) => user.isOrganizer === true
      );
      setOrganiserName(`${organiser?.firstName} ${organiser?.lastName}`);
    }
  }, [selectedEvent]);

  const joinMatchClick = () => {
    if (isloggedIn()) {
      if (!Number(myProfile.phoneNumber)) {
        toast.error(texts.missingPhoneNumberPlayer);
        history.push("/settings");
      } else {
        isTeamVSteam(selectedEvent)
        ? setJoinMatchStep(joinMatchSteps.step1)
        : joinAlone();
      }
    } else {
      setLoginModalOpen(true);
    }
  };

  const acceptInvite = () => {
    if (isTeamVSteam(selectedEvent)) {
      setJoinMatchStep(joinMatchSteps.step1); 
        setIsAcceptingTeamInvite(true)
      }
    else {
      onEventAcceptInvite({ eventId }).then((response) => {
        if (response.success) {
          toast.success(texts.inviteAccepted);
        } else {
          toast.error(texts.genericError);
        }
      });
    }
  };

  const cancelMatchClick = () => {
    onEventRequestLeave(eventId).then((response) => {
      if (response.success) {
        toast.success(response.message);
        getEventDetailById(eventId);
      }
    });
  };

  const joinAlone = () => {
    eventJoin({
      eventId,
    }).then((response) => {
      if (response.success) {
        setJoinMatchStep(joinMatchSteps.step0);
        toast.success(response.message);
      } else {
        toast.error(texts.jointRequestFailed);
      }
    });
  };

  const joinAsTeam = () => {
    if (!selected) {
      toast.error(texts.selectJoinMode);
      return;
    }
    if (isAcceptingTeamInvite) {
      onEventAcceptInviteAsTeam({ eventId, teamId: selected }).then((response) => {
        if (response.success) {
          toast.success(texts.inviteAccepted);
        } else {
          toast.error(texts.genericError);
        }
      });
      setIsAcceptingTeamInvite(false);
    }
    eventTeamJoin({ eventId, teamId: selected }).then((response) => {
      if (response.success) {
        setJoinMatchStep(joinMatchSteps.step0);
        toast.success(response.message);
      } else {
        toast.error(texts.jointRequestFailed);
      }
    });
  };

  const checkFeedback = () => {};

  const onClose = () => {
    setJoinMatchStep(joinMatchSteps.step0);
  };

  const goToEventTeams = () => {
    history.push(`/event/teams/${eventId}`);
  };

  const goToplayerProfile = (playerId) => {
    if (isloggedIn()) {
      if (playerId === myProfile.id) {
        history.push("/my-profile");
        return;
      }
      history.push(`/player-profile/${playerId}`);
    } else {
      setLoginModalOpen(true);
    }
  };

  const goToEditmatch = () => {
    history.push(`/edit-match/${eventId}`);
  };

  const goToTeam = (teamId) => {
    if (teamId) {
      history.push(`/group/${teamId}`);
    }
  };

  const goToMyTeams = () => {
    history.push(`/groups`);
  };

  const goToSearchTeams = () => {
    history.push(`/search-team`);
  };

  const manageUsers = () => {
    setJoinMatchStep(joinMatchSteps.step2);
  };

  const adminCancelMatchClick = (userId) => {
    if (userId) {
      onEventRequestRemove({ eventId, userId }).then((response) => {
        if (response.success) {
          toast.success(texts.userRemoved);
          getEventDetailById(eventId);
        } else {
          toast.error(response?.data?.message);
        }
      });
    }
  };
  const adminAcceptRequest = (userId) => {
    if (userId) {
      onEventRequestAccept({ eventId, userId }).then((response) => {
        if (response.success) {
          toast.success(response.message);
          getEventDetailById(eventId);
        } else {
          toast.error(texts.genericError);
        }
      });
    }
  };

  // Curently we are not displaying users that are not in relation with
  // this game so currently we don't need to implement this function.
  const adminJoinMatchClick = () => {};

  return (
    <Flex className={["tw-h-full tw-w-full"]}>
      {!calendarToggle && joinMatchStep === joinMatchSteps.step0 && (
        <Flex
          className={["tw-h-full tw-w-full tw-bg-white md:tw-w-mmd md:tw-m-auto"]}
        >
          <MatchdDetailsPage
            joinMatchClick={joinMatchClick}
            acceptInvite={acceptInvite}
            cancelMatchClick={cancelMatchClick}
            matchDetails={selectedEvent}
            userStatus={userStatus}
            isOrganiser={isOrganizer}
            isRequestedToJoinMatch={isRequestedToJoinMatch}
            checkFeedback={checkFeedback}
            goToEventTeams={goToEventTeams}
            goToplayerProfile={goToplayerProfile}
            addToCall={setCalendarToggle}
            editMatch={goToEditmatch}
            organiserName={organiserName}
            isTeamVSteam={isTeamVSteam(selectedEvent)}
            goToTeam={goToTeam}
            manageUsers={manageUsers}
          />
        </Flex>
      )}

      {joinMatchStep === joinMatchSteps.step1 && (
        <HowAreYouJoining
          teams={teams}
          onConfirm={joinAsTeam}
          selected={selected}
          setSelected={setSelected}
          fnCancel={onClose}
          onCreateTeam={goToMyTeams}
          onJoinTeam={goToSearchTeams}
          matchDetails={selectedEvent}
          userId={myProfile.id}
        />
      )}
      {joinMatchStep === joinMatchSteps.step2 && (
        <ManageUsers
          selectedEvent={selectedEvent}
          fnCancel={onClose}
          cancelMatchClick={adminCancelMatchClick}
          acceptRequest={adminAcceptRequest}
          joinMatchClick={adminJoinMatchClick}
        />
      )}

      {calendarToggle && (
        <EventCalendar
          eventName={selectedEvent?.name}
          startDate={selectedEvent?.dateTime}
          location={selectedEvent?.location?.name}
          details={selectedEvent?.description}
          backToMatchPage={() => setCalendarToggle(false)}
        />
      )}
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    myProfile: state.MyProfileReducer?.profile,
    teams: state.MyTeamsReducer,
    tokenReducer: state.TokenReducer,
    selectedEvent: state.AuthEventsReducer?.event?.eventResponse,
    userStatus: state.AuthEventsReducer?.event?.status,
    isOrganizer: state.AuthEventsReducer?.event?.isOrganizer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getEventDetailById: (data) => dispatch(eventDetailById(data)),
  eventJoin: (data) => dispatch(eventJoin(data)),
  fetchProfile: () => dispatch(myProfileApi()),
  onGetTeams: () => dispatch(getTeams()),
  onEventRequestLeave: (data) => dispatch(eventRequestLeave(data)),
  eventTeamJoin: (data) => dispatch(eventJoinWithTeam(data)),
  setLoginModalOpen: (data) => dispatch(setLoginModalState(data)),
  onEventAcceptInvite: (data) => dispatch(eventAcceptInvite(data)),
  onEventAcceptInviteAsTeam: (data) => dispatch(eventAcceptInviteAsTeam(data)),
  onEventRequestAccept: (data) => dispatch(eventRequestAccept(data)),
  onEventRequestRemove: (data) => dispatch(eventRequestRemove(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Match);

import React from "react";
import classNames from "classnames";

const Thumbnail = (props) => {
  const {
    className = [],
    src,
    isIncognito,
    isDisabled,
    onClick,
    isResize,
  } = props;
  const thumbnailWrapperClass = classNames(
    "tw-flex tw-overflow-hidden tw-rounded-3xl tw-mx-1 tw-mb-2 tw-items-center tw-justify-center",
    { "tw-w-10 tw-h-10": !isResize },
    isDisabled ? "tw-bg-grey-light" : "tw-bg-grey",
    ...className
  );

  if (isIncognito) {
    return (
      <div className={thumbnailWrapperClass} onClick={onClick}>
        <span className="tw-text-white tw-text-2xl">?</span>
      </div>
    );
  }

  return (
    <div className={thumbnailWrapperClass} onClick={onClick}>
      {!isDisabled && (
        <img src={src} className={"tw-object-cover tw-h-full tw-w-full"} />
      )}
    </div>
  );
};

export default Thumbnail;

import React from "react";
import { Span, Flex, Img } from "components/atoms";

const FaqComponent = (props) => {
  const { tile, description } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Flex className={["tw-p-2 tw-m-2"]}>
      <Flex
        direction="row"
        className={["tw-w-full tw-justify-between"]}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Span color="black" className={["tw-mr-4"]}>
          {tile}
        </Span>
        <Img
          src={isOpen ? "img/faq-arrow-grey.png" : "img/faq-arrow-black.png"}
          alt={isOpen ? "notification" : "btn_Back"}
          className={["tw-h-4"]}
        ></Img>
      </Flex>
      {isOpen && (
        <Span
          color="grey-dark"
          size="xs"
          className={["tw-w-3/4 tw-pt-4 tw-font-black"]}
        >
          {description}
        </Span>
      )}
    </Flex>
  );
};

export default FaqComponent;

import React from "react";
import moment from "moment";
import { Flex, Img, Span, Separator, Thumbnail } from "components/atoms";

const GameCard = (props) => {
  const { data, showEvent, key } = props;

  const organizer = data?.users?.find((x) => x.isOrganizer);

  const joinedPlayers = data?.users?.filter((u) => u.status === "Joined");
  const availableSpaces = data?.maxPlayers - joinedPlayers.length || 0;
  const date = moment.utc(data.dateTime).local();

  const opacity = date < Date.now() ? 'tw-opacity-50' : 'tw-opacity-100';

  return (
    <Flex
      direction="row"
      className={["tw-w-full", "tw-h-32", "tw-overflow-hidden", "tw-py-1", "tw-my-1", `${opacity}`]}
      id={key}
      onClick={() => showEvent(data?.id)}
    >
      <Flex className={["tw-w-1/4", "tw-rounded-2xl", "tw-overflow-hidden"]}>
        <Img
          className={["tw-absolute", "tw-w-full", "tw-h-full"]}
          src={data.eventImageUrl}
        />
        <div className="tw-absolute tw-bottom-0 tw-w-full tw-bg-grey tw-h-1/4 tw-text-center">
          <Flex
            direction="row"
            className={[
              "tw-h-full",
              "tw-w-full",
              "tw-items-center",
              "tw-justify-center",
              "tw-px-2",
            ]}
          >
            {/* <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-3xl tw-bg-grey-light tw-absolute tw-w-10 xsm:tw-w-14 tw-h-16 tw-left-1 tw-bottom-6 tw-p-1">
              <Span size="lg" color={true} className={["tw-text-black"]}>
                {date?.format("DD")}
              </Span>
              <Span
                size="xs"
                color={true}
                className={["tw-text-black", "tw-uppercase"]}
              >
                {date?.format("MMM")}
              </Span>
            </div> */}
            <Span
              className={["tw-text-center"]}
              size="xxs"
            >
              {availableSpaces > 0
                ? `Looking for ${availableSpaces}`
                : "All players found"}
            </Span>
          </Flex>
        </div>
      </Flex>
      <Flex className={["tw-w-3/5", "tw-pl-4", "flex-grow-1"]}>
        <Flex className={["tw-pb-2"]}>
          <Flex
            direction="row"
            className={["tw-justify-center", "tw-items-center"]}
          >
            <Span
              size="xxs"
              color={true}
              className={[
                "tw-pr-2",
                "tw-text-black",
                "tw-whitespace-nowrap",
                "tw-clear-both",
                "tw-overflow-ellipsis",
                "tw-overflow-hidden",
                "tw-uppercase",
              ]}
            >
              {organizer?.firstName} {organizer?.lastName}
            </Span>
            <Separator
              width={"tw-w-auto"}
              className={["tw-flex-grow tw-my-3"]}
              isOverrideMargin={true}
            />
          </Flex>
          <Flex direction="row" className={["tw-w-full", "tw-overflow-hidden", "tw-justify-between"]}>
            <Span size="lg" color="darkBlueDark" className={["tw-max-h-12"]}>
              {data.name}
            </Span>
            <Span size="sm" color="gray" className={["tw-max-h-12"]}>
              {date?.format("DD")} {date?.format("MMM")}
            </Span>
          </Flex>
          <Flex direction="row" className={["tw-items-end", "tw-pt-2"]}>
            <Img
              src="/img/location.svg"
              width={9}
              height={13}
              className={["tw-w-4", "tw-mr-2"]}
            />
            <Span size="xs" muted>
              {data?.location?.name}
            </Span>
          </Flex>
        </Flex>
        <Flex direction="row" className={["tw-flex-wrap flex-grow-1 tw-items-end"]}>
          {joinedPlayers?.map(({ profilePictureUrl, status}) => (
            <Thumbnail
              src={profilePictureUrl || "/img/unselected-player.png"}
              isIncognito={status.toLowerCase() === "invited"}
              className={["tw-w-6 tw-h-6"]}
              isResize={true}
            />
          ))}
          {[...Array(availableSpaces).keys()].map(() => (
            <Thumbnail
              isDisabled
              className={["tw-w-6 tw-h-6"]}
              isResize={true}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GameCard;

import React from "react";
import moment from "moment";
import { Flex, Img, Span } from "components/atoms";

const ProfileGameCard = (props) => {
  const { event, showEvent, key, status } = props;

  const organizer = event?.users?.find((x) => x.isOrganizer);

  const playersCount = Math.abs(
    event?.maxPlayers -
      event?.users?.filter((u) => u.status === "Joined").length
  );
  const date = moment.utc(event?.dateTime).local();

  return (
    <Flex
      direction="row"
      className={[
        "tw-bg-grey-light tw-pr-1 tw-mb-2 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center",
      ]}
      key={key}
      onClick={() => showEvent(event)}
    >
      <Img
        src={event?.eventImageUrl || "/img/unselected-player.png"}
        alt="player img"
        className={["tw-w-20 tw-h-20 tw-rounded-l-2xl"]}
      ></Img>
      <Flex className={["tw-px-4 tw-py-2 tw-flex-1 tw-justify-center"]}>
        <Flex
          direction="row"
          className={["tw-w-full tw-pt-1 tw-justify-between "]}
        >
          <Span size={"xxs"} color="grey" className={[""]}>
            {organizer?.firstName} {organizer?.lastName}
          </Span>
          <Span size={"xxs"} color="green-light" className={[""]}>
            {status || "Accepted"}
          </Span>
        </Flex>
        <Span size={"xs"} color="black" className={["tw-py-2"]}>
          {event?.name}
        </Span>
        <Span size={"xxs"} color="grey" className={["tw-pb-1"]}>
          {event?.location?.name}
        </Span>
      </Flex>
      <Flex
        className={["tw-p-2 tw-mt-1 tw-bg-white tw-self-start tw-rounded-2xl"]}
      >
        <Span size={"lg"} color="grey" className={["tw-w-6"]}>
          {date?.format("DD")}
        </Span>
        <Span size={"xxs"} color="grey" className={[""]}>
          {date?.format("MMM")}
        </Span>
      </Flex>
    </Flex>
  );
};

export default ProfileGameCard;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Modal from "deprecated/modal";
import LoginPage from "components/pages/login";
import {
  setLoginModalState,
  setSignUpModalState,
  setLoginModalErrorState,
} from "combineReducer/modal/actions";
import { magicLink } from "services/auth";

const Login = (props) => {
  const dispatch = useDispatch();
  const setLoginModalOpen = (data) => dispatch(setLoginModalState(data));
  const setSignUpModalOpen = (data) => dispatch(setSignUpModalState(data));
  const setLoginModalErrorOpen = (data) =>
    dispatch(setLoginModalErrorState(data));

  const onLogin = (data) =>
    dispatch(magicLink(data)).then((response) =>
      toast.success(response.message)
    );
  const loginModalOpen = useSelector((state) => {
    return state?.ModalReducer?.isLoginModalOpened;
  });

  React.useEffect(() => {
    if (!loginModalOpen) {
      setLoginModalErrorOpen(false);
    }
  }, [loginModalOpen]);

  const onSubmit = (data) => {
    const { email } = data;
    onLogin({ email }).then(({ success }) => {
      if (success) {
        setLoginModalOpen(false);
        setLoginModalErrorOpen(false);
      }
    });
  };

  const fbLogin = () => {
    window.location.href =
      process.env.REACT_APP_FB_AUTH_URL +
      process.env.REACT_APP_FB_APP_ID +
      "&redirect_uri=" +
      process.env.REACT_APP_WEBURL +
      "&scope=email,user_birthday,user_gender,user_friends";
  };

  return (
    <Modal fullscreen={true} open={loginModalOpen} setOpen={setLoginModalOpen}>
      <LoginPage
        onSubmit={onSubmit}
        openSignUpModal={() => setSignUpModalOpen(true)}
        fbLogin={fbLogin}
      />
    </Modal>
  );
};

export default Login;

import React from "react";
import moment from "moment";
import { generateArrayOfYears } from "utils/helper";
import { Portal, ErrorInfo } from "components/molecules";
import Modal from "deprecated/modal";
import TermsOfUse from "../../../containers/termsOfUse/termsOfUse";
import {
  Heading,
  Button,
  Separator,
  Input,
  Label,
  Select,
  Checkbox,
  Paragraph,
  Flex,
  Form,
  Span,
} from "components/atoms";
import { texts } from "Text";

const RegistrationPage = (props) => {
  const { onSubmit, cities, toggle, open, validationError, error } = props;
  const [termsOpen, setTermsOpen] = React.useState(false);

  return (
    <Modal open={open} fullscreen={true}>
      <Portal iscloseButtonVisible={false} toggle={toggle}>
        <Heading>Create Account</Heading>
        <Form onSubmit={onSubmit}>
          {({ register, watch, setValue }) => (
            <>
              <Input placeholder="Name" {...register("name")} />
              {validationError?.firstName && (
                <ErrorInfo error={validationError?.firstName} />
              )}
              <Input placeholder="Surname" {...register("surname")} />
              {validationError?.lastName && (
                <ErrorInfo error={validationError?.lastName} />
              )}
              <Label required>Date of Birth</Label>
              <div className="tw-flex tw-flex-wrap tw-justify-between tw-flex-shrink-0">
                <Select
                  className={["tw-flex-1 profile-select-wrapper"]}
                  rounded="2xl"
                  padding="tw-p-2"
                  options={[
                    ...Array(
                      new Date(
                        watch("year") || new Date().getFullYear(),
                        watch("month") || new Date().getMonth() + 1,
                        0
                      ).getDate()
                    ),
                  ].map((i, idx) => ({ name: ++idx, id: ++idx }))}
                  placeholder="Day"
                  {...register("day")}
                />
                <Select
                  className={["tw-flex-2", "tw-mx-2 profile-select-wrapper"]}
                  rounded="2xl"
                  padding="tw-p-2"
                  options={moment
                    .months()
                    .map((label, idx) => ({ name: label, id: ++idx }))}
                  placeholder="Month"
                  {...register("month")}
                />
                <Select
                  className={["tw-flex-1 profile-select-wrapper"]}
                  rounded="2xl"
                  padding="tw-p-2"
                  options={generateArrayOfYears().map((value) => ({
                    name: value,
                    id: value,
                  }))}
                  placeholder="Year"
                  {...register("year")}
                />
              </div>
              {validationError?.dateOfBirth && (
                <ErrorInfo error={validationError?.dateOfBirth} />
              )}
              <Label required>Locality</Label>
              <Select
                {...register("locality")}
                className={["profile-select-wrapper"]}
                placeholder="Select Locality"
                rounded="2xl"
                padding="tw-p-2"
                options={cities}
              />
              {validationError?.localityId && (
                <ErrorInfo error={validationError?.localityId} />
              )}
              <Separator />

              <Label required>Contact Phone Number</Label>
              <Input
                type="number"
                placeholder="Phone number with country code"
                {...register("phone")}
              />
              {validationError?.phoneNumber && (
                <ErrorInfo error={validationError?.phoneNumber} />
              )}
              <Checkbox
                {...register("smsNotifications")}
                label="Opt-in for Notifications"
              />
              <Paragraph size="sm" muted>
                {texts.recommendedPhoneNumber}
              </Paragraph>
              <Flex direction="row">
                <Checkbox
                  {...register("terms")}
                  name="terms"
                  label={
                    <Span size="sm">
                      {/* White space in React */}I accept the {"\u00A0"}
                    </Span>
                  }
                />
                <Flex className={["tw-p-1"]}>
                  <Span color="green" size="sm">
                    <Flex onClick={() => setTermsOpen(true)}>
                      Terms and Conditions{" "}
                    </Flex>
                  </Span>
                </Flex>
              </Flex>
              {error && <ErrorInfo error={error} />}
              <Button type="submit">Create Account</Button>
              <div className="flex tw-flex"></div>
              {/* Since we have to set the terms within the form and from the terms page as well,
            the easiest solution is to put the TermsOfUse modal in the form itself and control the value 
            with setValue() from inside the Form. */}
              <Modal open={termsOpen}>
                <TermsOfUse
                  setTermsAndCondition={(value) => {
                    setValue("terms", value);
                    setTermsOpen(false);
                  }}
                />
              </Modal>
            </>
          )}
        </Form>
      </Portal>
    </Modal>
  );
};

export default RegistrationPage;

import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import HeaderComponent from "containers/header";
import { headerVersions } from "containers/header/constants";
import { texts } from "Text";
import { myProfileApi } from "services/profile";
import SimplybookWidget from "utils/SimplybookWidget";

function BookPitchComponent(props) {
  const { profile = {}, fetchProfile } = props;
  const { firstName, lastName, phoneNumber, email } = profile;

  React.useEffect(() => {
    if (isEmpty(profile)) {
      fetchProfile();
    }
  }, []);

  React.useEffect(() => {
    if (!isEmpty(profile)) {
      let config = {
        widget_type: "iframe",
        url: "https://leet.simplybook.it",
        theme: "minimal",
        theme_settings: {
          timeline_show_end_time: "1",
          timeline_modern_display: "as_slots",
          hide_company_label: "0",
          timeline_hide_unavailable: "0",
          sb_base_color: "#525252",
          btn_color_1: "#c8d16e,#c8d16e,#c8d16e",
          link_color: "#c8d16e",
          display_item_mode: "block",
          body_bg_color: "#ffffff",
          sb_review_image: "9",
          sb_review_image_preview:
            "/uploads/leet/image_files/preview/cf7d984e2acd680fbc680656b27d3139.jpg",
          dark_font_color: "#2b212b",
          light_font_color: "#ffffff",
          sb_company_label_color: "#ffffff",
          hide_img_mode: "0",
          sb_busy: "#aaa6aa",
          sb_available: "#2b212b",
        },
        timeline: "modern",
        datepicker: "top_calendar",
        is_rtl: false,
        app_config: {
          allow_switch_to_ada: 0,
          predefined: {
            client: {
              name: `${firstName} ${lastName}`,
              email: email,
              phone: phoneNumber?.toString() || "",
            },
          },
        },
      };

      const widget = new SimplybookWidget(config);
    }
  }, [profile]);

  return (
    <div className="pb-200 pb-lg-0 bg-white md:tw-w-60 md:tw-m-auto">
      <HeaderComponent
        headerText={texts.bookingPitch}
        headerVersion={headerVersions.version3}
      />

      <div
        className="container container-booking-widget scroll-booking-page"
        id="simplyBookWidget"
      ></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.MyProfileReducer.profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: () => dispatch(myProfileApi()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BookPitchComponent);

import React from "react";
import { Span, Flex, Img } from "components/atoms";
import PlayerTypeIcons from "./playerTypeIcons";
import PlayerCradButtons from "./playerCradButtons";
import FriendCradButtons from "./friendCradButtons";

const GeneralPlayerCard = (props) => {
  const {
    player,
    goToProfile,
    goToChat,
  } = props;

  return (
    <Flex
      direction="row"
      className={[
        "tw-bg-grey-light tw-m-2 tw-mb-1 tw-border tw-rounded-2xl tw-border-borderGray tw-items-center",
      ]}
    >
      <Img
        src={player?.profilePictureUrl || "/img/unselected-player.png"}
        alt={player?.firstName || "unselected-player.png"}
        className={["tw-h-16 tw-w-16 tw-rounded-l-2xl"]}
        onClick={() => goToProfile(player)}
      ></Img>
      <Flex
        onClick={() => goToProfile(player)}
        className={["tw-p-1 tw-ml-2 tw-flex-1 tw-justify-center"]}
      >
        <Span size={"xs"} color={"black"}>
          {player?.firstName + " " + player?.lastName}
        </Span>
        <Span size={"xxs"} muted={true}>
          {player?.locality?.name}
        </Span>
      </Flex>
      <Flex className={["tw-p-1 tw-pr-2"]}>
          <PlayerCradButtons
            player={player}
            goToChat={goToChat}
          />
        {player?.playerType && <PlayerTypeIcons icon={player?.playerType} />}
      </Flex>
    </Flex>
  );
};

export default GeneralPlayerCard;

import React from "react";
import { Input, Flex, Img } from "components/atoms";

const FORMATS_SUPPORTED = ["image/jpeg", "image/jpg", "image/png"];
const widthAndHeight = {
  base: ["tw-h-28 tw-w-28"],
  small: ["tw-h-24 tw-w-24"],
};

const UploadImg = (props) => {
  const {
    name,
    setError,
    setFile,
    imagePreview,
    setImagePreview,
    className = [],
    size = "base",
  } = props;

  const handleUploadFile = (e) => {
    let file = e.target.files?.length && e.target.files[0];

    if (file) {
      if (!FORMATS_SUPPORTED.includes(file.type))
        return setError("You have selected an invalid image !");
      if (file.size > 1000000)
        return setError("Image file size is too large, must be less than 1MB!");
      
      setFile(file); //We use this file for uploading the img.
      var reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result); //We use this string to display the img.
      };

      reader.readAsDataURL(file);
    }
    if (!imagePreview && !file)
      return setError("You have selected an invalid image!");
  };
  return (
    <Flex
      className={[
        `tw-mr-4 tw-relative tw-overflow-hidden`,
        `${widthAndHeight[size]}`,
        ...className,
      ]}
    >
      <Input
        type="file"
        className={["tw-absolute tw-h-full tw-w-full tw-opacity-0 tw-z-10"]}
        onChange={(e) => handleUploadFile(e)}
        baseHeight={false}
      />

      <Img
        title={"TeamImage"}
        alt={imagePreview ? "Preview" : "Upload"}
        src={imagePreview || "/img/image-upload-preview.png"}
        className={["tw-relative", `${widthAndHeight[size]}`]}
      />

      <Img
        title={"upload"}
        alt={"Upload"}
        src={"/img/editImage.png"}
        className={["tw-absolute tw-z-5 tw-w-8 tw-bottom-2 tw-left-2"]}
      />
    </Flex>
  );
};

export default UploadImg;

import React from "react";
import { Flex, Input, Select, Button, Form, } from "components/atoms";
import { UploadImg, ErrorInfo } from "../../molecules";
import GoalerFutballerRadio from "components/molecules/general/goalerFutballerRadio";
import PlayerLevelRadio from "components/molecules/general/playerLevelRadio";
import { texts } from "../../../Text/index";
import moment from "moment";
import { generateArrayOfYears } from "utils/helper";

const EditProfileComponent = (props) => {
  const {
    setName,
    setPlayerType,
    setPlayerLevel,
    setSelectedLocation,
    setSelectedDay,
    setSelectedMonth,
    setSelectedYear,
    cities,
    defaultUserName,
    setFile,
    imagePreview,
    setImagePreview,
    submit,
    error,
    user,
  } = props;

  const dateTimeDOB = new Date(user?.dateOfBirth);

  const selectLocations = () => {
    const locationsArray = cities?.map((location) => {
      return {
        id: location.id,
        name: location.name,
        selected:  location.id == user.localityId
      };
    });
    if ( user.localityId) {
      const location = cities.find(
        (location) => location.id === user.localityId
      );
      return [ ...locationsArray];
    }
    return [{ id: "", name: texts.selectMatchPlace }, ...locationsArray];
  };

  return (
    <Flex className={[""]}>
      <Flex direction="row" className={["tw-mb-4"]}>
        <UploadImg
          name="profilePictureUrl"
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          setFile={setFile}
          className={["tw-rounded-3xl"]}
          size="small"
        />
        <Flex className={["tw-pl-4"]}>
          <Input
            className={[
              "tw-text-grey-dark tw-border-2 tw-border-borderGray tw-rounded-lg",
            ]}
            rounded="lg"
            size="xxs"
            maxLength={30}
            onChange={(event) => setName(event.target.value)}
            defaultValue={defaultUserName}
            marginBottom="mbxs"
          />
          {error && <ErrorInfo error={error} />}
          <Select
            className={[
              "profile-select-wrapper tw-text-grey-dark tw-text-sm tw-border-2 tw-border-borderGray tw-rounded-lg",
            ]}
            backgroundColor="white"
            padding="tw-p-2"
            wrapperDisabled={true}
            options={selectLocations()}
            onChange={(event) => {
              setSelectedLocation(event.target.value);
            }}
          ></Select>
          
        </Flex>
      </Flex>
      <div className="tw-flex tw-flex-wrap tw-justify-between tw-flex-shrink-0">
                <Select
                  className={["tw-flex-1 profile-select-wrapper tw-text-grey-dark tw-text-sm tw-border-2 tw-border-borderGray tw-rounded-lg"]}
                  backgroundColor="white"
                  padding="tw-p-2"
                  options={[
                    ...Array(
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                      ).getDate()
                    ),
                  ].map((i, idx) => ({ name: idx+1, id: idx+1, selected: idx+1 == dateTimeDOB.getDate() }))}
                  placeholder="Day"
                  onChange={(event) => {
                    setSelectedDay(event.target.value);
                  }}
                />
                <Select
                  className={["tw-flex-2 tw-mx-2 profile-select-wrapper tw-text-grey-dark tw-text-sm tw-border-2 tw-border-borderGray tw-rounded-lg"]}
                  backgroundColor="white"
                  padding="tw-p-2"
                  options={moment
                    .months()
                    .map((label, idx) => ({ name: label, id: idx+1, selected: idx+1 == dateTimeDOB.getMonth()+1 }))}
                  placeholder="Month"
                  onChange={(event) => {
                    setSelectedMonth(event.target.value);
                  }}
                />
                <Select
                  className={["tw-flex-1 profile-select-wrapper tw-text-grey-dark tw-text-sm tw-border-2 tw-border-borderGray tw-rounded-lg"]}
                  backgroundColor="white"
                  padding="tw-p-2"
                  options={generateArrayOfYears().map((value) => ({
                    name: value,
                    id: value,
                    selected: value == dateTimeDOB.getFullYear()
                  }))}
                  placeholder="Year"
                  onChange={(event) => {
                    setSelectedYear(event.target.value);
                  }}
                />
              </div>
      <Flex className={["tw-justify-center"]}>
        <Flex className={["tw-text-grey-dark tw-text-xxs tw-mb-2"]}>
        </Flex>
        <Flex className={["tw-bg-grey tw-rounded-2xl tw-mb-4 tw-pb-1"]}>
          <GoalerFutballerRadio onChange={setPlayerType} currentSelected={user.playerType} />
        </Flex>
        <Flex className={["tw-bg-grey tw-rounded-2xl tw-mb-4 tw-pb-1"]}>
          <PlayerLevelRadio onChange={setPlayerLevel} currentSelected={user.playerLevel.toLowerCase()}/>
        </Flex>
        <Button onClick={submit} className={["tw-w-full tw-p-4"]}>
          {texts.saveProfile}
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditProfileComponent;

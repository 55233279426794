import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "services/cookies";

export default function PrivateRoute({
  component: Component,
  chatClient,
  ...rest
}) {
  const isAuthenticated = !!getCookie("leetUserProfile");

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthenticated ? (
          <Component {...routeProps} chatClient={chatClient} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}

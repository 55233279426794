import React from "react";
import classNames from "classnames";

const Image = ({ width, height, src, title, alt, onClick, className = [], position="tw-object-cover" }) => {
  const imgClass = classNames(position, ...className);
  return (
    <img
      width={width}
      height={height}
      src={src}
      title={title}
      alt={alt}
      className={imgClass}
      onClick={onClick}
    />
  );
};

export default Image;

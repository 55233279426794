import { fetchGet } from "services";
import * as UserActions from "combineReducer/users/actions";

export const getUserById = (id) => (dispatch) =>
  fetchGet(`users/${id}`)
    .then((response) => {
      dispatch(
        UserActions.userProfile({
          success: true,
          data: response,
        })
      );
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        dispatch(
          UserActions.userProfile({
            success: false,
            data: {},
            message: error.message || "Error in get search user API",
          })
        );
        return {
          message: error.message || "Error in get search user API",
        };
      }
    });

// At the system refactor we decided to merge all user actions like search, suggested into one endpoint.
export const friendListApi =
  (data = { pageNum: "1", pageSize: "20" }, refreshdata) =>
  (dispatch) =>
    fetchGet("friends", { data })
      .then((response) => {
        var sortedData = response?.friends.sort((a, b) => a.user.firstName.toLowerCase().localeCompare(b.user.firstName.toLowerCase()));
        if (refreshdata) {
          dispatch(
            UserActions.replaceUserList({
              success: true,
              data: sortedData,
              message: response?.friends?.length
                ? "Successfully get friends"
                : "No friends found",
            })
          );
        } else {
          if (response.friends.length > 0) {
            dispatch(
              UserActions.suggestedUserList({
                success: true,
                data: sortedData,
                message: response?.friends?.length
                  ? "Successfully get friends"
                  : "No friends found",
              })
            );
          } else {
            dispatch(UserActions.stopLoadFriendList());
          }
        }
        return response;
      })
      .catch((error) => {
        if (error.status === 401) {
          return { message: "Unauthorized User" };
        } else {
          dispatch(
            UserActions.suggestedUserList({
              success: false,
              data: [],
              message: error.message || "Error in friends API",
            })
          );
          return {
            message: error.message || "Error in friends API",
          };
        }
      });

export const SUGGESTED_USER_LIST = "SUGGESTED_USER_LIST";
export const USER_PROFILE = "USER_PROFILE";
export const STOP_LOAD_FRIEND_LIST = "STOP_LOAD_FRIEND_LIST";
export const REPLACE_USER_LIST = "REPLACE_USER_LIST";
export const UPDATE_USER_IN_LIST = "UPDATE_USER_IN_LIST";

export function userProfile(data) {
  return {
    type: USER_PROFILE,
    payload: data,
  };
}

export function suggestedUserList(data) {
  return {
    type: SUGGESTED_USER_LIST,
    payload: data,
  };
}

export function replaceUserList(data) {
  return {
    type: REPLACE_USER_LIST,
    payload: data,
  };
}

export function stopLoadFriendList(data) {
  return {
    type: STOP_LOAD_FRIEND_LIST,
  };
}

export function updateUserInList(data) {
  return {
    type: UPDATE_USER_IN_LIST,
    payload: data,
  };
}

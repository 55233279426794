import React from "react";
import { connect } from "react-redux";
import { magicLinkConfirmEmail } from "services/auth";
import { myProfileApi } from "services/profile";
import { useHistory } from "react-router";
import Registration from "../containers/registration";

function ConfirmEmail(props) {
  const history = useHistory();
  const [createAccount, setCreateAccount] = React.useState(false);
  React.useEffect(() => {
    var url = new URL(window.location.href);
    var userId = url.searchParams.get("uid");
    var code = url.searchParams.get("code");
    let submitData = {
      userId: decodeURIComponent(userId),
      code: decodeURIComponent(code),
    };
    props.onConfirmEmail(submitData).then((response) => {
      if (response.createAccount) {
        setCreateAccount(true);
        return;
      }
      props.fetchProfile();
      history.push("/");
    });
  }, []);

  return <div>{createAccount && <Registration history={history} />}</div>;
}
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onConfirmEmail: (data) => dispatch(magicLinkConfirmEmail(data)),
  fetchProfile: () => dispatch(myProfileApi()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);

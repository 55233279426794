import React from "react";
import classNames from "classnames";

const Checkbox = React.forwardRef((props, ref) => {
  const {
    name,
    value,
    onBlur,
    onChange,
    label,
    size = "sm",
    className = [],
    defaultChecked,
  } = props;

  const checkboxClass = classNames(
    "tw-absolute",
    "tw-opacity-0",
    "tw-hidden",
    "tw-h-0",
    "tw-w-0"
  );
  const labelClass = classNames(
    "tw-relative",
    "tw-text-white",
    "tw-pl-10",
    `tw-text-${size}`,
    "tw-cursor-pointer",
    "tw-m-0",
    ...className
  );

  const paragraphClass = classNames("tw-mb-4");

  return (
    <p className={paragraphClass}>
      <input
        ref={ref}
        type="checkbox"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        id={name}
        className={checkboxClass}
        value={value}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </p>
  );
});

export default Checkbox;

export const SET_LOGIN_MODAL_STATE = "SET_LOGIN_MODAL_STATE";
export const SET_SIGNUP_MODAL_STATE = "SET_SIGNUP_MODAL_STATE";
export const SET_LOGIN_MODAL_ERROR_STATE = "SET_LOGIN_MODAL_ERROR_STATE";

export function setLoginModalState(data) {
  return {
    type: SET_LOGIN_MODAL_STATE,
    payload: data,
  };
}
export function setSignUpModalState(data) {
  return {
    type: SET_SIGNUP_MODAL_STATE,
    payload: data,
  };
}

export function setLoginModalErrorState(data) {
  return {
    type: SET_LOGIN_MODAL_ERROR_STATE,
    payload: data,
  };
}

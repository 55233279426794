import * as Actions from "combineReducer/friends/actions";
import * as UserActions from "combineReducer/users/actions";
import { toast } from "react-toastify";
import { fetchPost, fetchPut, fetchDelete } from "services";
import { texts } from "Text/index";

export const friendReqCancelRemove = (id) => (dispatch) =>
  fetchDelete("friends/" + id)
    .then((response) => {
      response = {
        success: true,
        data: response,
      };
      dispatch(Actions.friendStatus(response));
      dispatch(UserActions.updateUserInList(response));
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        Actions.friendStatus({
          success: false,
          data: {},
        });
        return {
          message: error.message || "Error in friend request removing/cancel.",
        };
      }
    });

export const friendReqAccept = (id) => (dispatch) =>
  fetchPut("friends/" + id)
    .then((response) => {
      response = {
        success: true,
        data: response,
      };
      dispatch(Actions.friendStatus(response));
      dispatch(UserActions.updateUserInList(response));
      return response;
    })
    .catch((error) => {
      if (error.status === 401) {
        return { message: "Unauthorized User" };
      } else {
        dispatch(
          Actions.friendStatus({
            success: false,
            data: {},
          })
        );
        return {
          message: error.message || "Error in friend request accepting API.",
        };
      }
    });

export const addFriendApi = (id) => (dispatch) =>
  fetchPost("friends/" + id, {
    data: "",
  })
    .then((response = {}) => {
      response = {
        success: true,
        data: response,
        message: "Friend request sent successfully!",
      };
      dispatch(Actions.friendStatus(response));
      dispatch(UserActions.updateUserInList(response));
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        toast.error(texts.errorUnauthorisedUser);
        return { message: texts.errorUnauthorisedUser };
      } else {
        Actions.friendStatus({
          success: false,
          data: {},
        });
        return {
          message: error.message || texts.errorAddFriendsApi,
        };
      }
    });

export const inviteFriendApi = (email) => (dispatch) =>
  fetchPost("friends/invite", {
    data: { email },
  })
    .then((response = {}) => {
      response = {
        success: true,
        message: "Friend has been invited!",
      };
      return response;
    })
    .catch((error) => {
      if (error && error.status === 401) {
        toast.error(texts.errorUnauthorisedUser);
        return { message: texts.errorUnauthorisedUser };
      } else if (error && error.status === 409) {
        const response = {
          success: false,
          status: 409,
          message:
            "User with this email address is already registered on LEET.",
        };
        return response;
      }
    });

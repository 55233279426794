import React from "react";
import Modal from "deprecated/modal";
import { Paragraph, Input, Heading, Flex, Button } from "components/atoms";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, TeamCard, SteppButtons } from "../../molecules";

const AddOtherTeams = (props) => {
  const {
    teams,
    submit,
    goBack,
    fnCancel,
    stepNumber,
    searchOnChange,
    searchWord,
    setSearchWord,
    addTeam,
    opponentTeams,
    selectedTeamId,
  } = props;

  const filteredTeams = teams.filter(team => team.id !== selectedTeamId);

  return (
    <Modal open={true}>
      <Flex
        className={["tw-h-full tw-w-full tw-bg-black tw-z-50 tw-overflow-auto"]}
      >
        <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
        <Flex className={["tw-px-12 xsm:tw-mt-10 md:tw-w-mmd md:tw-m-auto"]}>
          <Heading className={[" tw-items-center"]}>
            {texts.inviteATeamToPlayAgainst}
          </Heading>
          <Paragraph className={["tw-max-w-xxs"]} muted={false} size="xs">
            {texts.searchTeamsOnLeet}
          </Paragraph>
          <Input
            value={searchWord || ""}
            onChange={(e) => {
              searchOnChange(e.target.value);
              setSearchWord(e.target.value);
            }}
          ></Input>
          <Flex className={["tw-h-48 tw-mb-4 tw-overflow-auto"]}>
            {filteredTeams && filteredTeams.length > 0 ? (
              filteredTeams.map((team) => {
                return (
                  <TeamCard
                    onClick={() => addTeam(team?.id)}
                    team={team}
                    isJoined={true}
                    isOrganiseFlow={true}
                    isOnSelectedList={opponentTeams?.includes(team?.id)}
                  ></TeamCard>
                );
              })
            ) : (
              <Flex
                direction="row"
                className={[
                  "tw-py-4 tw-mt-2 tw-text-white tw-text-xxs tw-justify-between",
                ]}
              >
                {texts.teamNotFoundV3}
              </Flex>
            )}
          </Flex>
          <SteppButtons
            fwdText={texts.finish}
            fnBack={goBack}
            fnForward={submit}
          ></SteppButtons>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default AddOtherTeams;

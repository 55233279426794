import React from "react";
import { Paragraph, Heading, Flex, Button, Select } from "components/atoms";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { texts } from "../../../Text/index";
import { BlackModalTopBar, ErrorInfo, SteppButtons } from "../../molecules";

const WhereAndWhen = (props) => {
  const {
    fnCancel,
    next,
    goBack,
    bookingId,
    selectedLocation,
    locations,
    setLocation,
    error,
    privateMatch,
    setPrivateMatch,
    stepNumber,
    date,
    setDate,
  } = props;

  const selectLocations = () => {
    const locationsArray = locations?.map((location) => {
      return {
        id: location.id,
        name: location.name,
      };
    });
    if (selectedLocation) {
      const location = locations.find(
        (location) => location.id === selectedLocation
      );
      return [{ id: location?.id, name: location.name }, ...locationsArray];
    }
    return [{ id: "", name: texts.selectMatchPlace }, ...locationsArray];
  };

  return (
    <Flex className={["tw-h-full tw-bg-black tw-z-50 tw-overflow-auto"]}>
      <BlackModalTopBar onClick={fnCancel} stepNumber={stepNumber} />
      <Flex className={["tw-px-8 xsm:tw-mt-10 md:tw-w-mmd md:tw-m-auto"]}>
        <Heading className={["tw-items-center"]}>{texts.whereAndWhen}</Heading>
        <Paragraph className={["tw-w-48"]} muted={false} size="xs">
          {texts.selectDateAndTime}
        </Paragraph>
        <DatePicker
          selected={date}
          placeholderText={texts.datePicker}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy h:mm aa"
          minDate={new Date()}
          timeIntervals={15}
          readOnly={!!bookingId}
          disabled={!!bookingId}
          showTimeSelect
          withPortal
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
        />

        <Paragraph className={["tw-w-full tw-pt-4"]} muted={false} size="xxs">
          {texts.matchLocation}
        </Paragraph>
        <Select
          className={["tw-text-sm tw-mb-4"]}
          options={selectLocations()}
          onChange={(event) => {
            setLocation(event.target.value);
          }}
        ></Select>

        <Button
          quinternary={true}
          className={["tw-mt-6 tw-text-sm tw-border-2 tw-border-white"]}
          onClick={() => {
            setPrivateMatch(!privateMatch);
          }}
        >
          {texts.privateMatch}
          {privateMatch ? texts.privateMatchOn : texts.privateMatchOff}
        </Button>
        {error && <ErrorInfo error={error} />}
        <SteppButtons fwdText={texts.next} fnBack={goBack} fnForward={next} />
      </Flex>
    </Flex>
  );
};

export default WhereAndWhen;

import React from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

/* 
Color Scheme
tw-bg-error
tw-bg-success
*/

const Notifications = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
      bodyClassName={() =>
        "tw-flex tw-justify-center tw-items-center tw-w-full"
      }
      toastClassName={({ type }) =>
        `tw-min-h-16 tw-bg-${type} tw-flex tw-p-2 tw-h-6 tw-cursor-pointer`
      }
    />
  );
};
export default Notifications;

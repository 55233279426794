import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createTeam, getTeams, getAllTeams, getTeamById, uploadTeamAvatar, requestToJoinTeam, updateTeam } from "services/teams";
import { friendListApi } from "services/users";
import { uploadPictureApi } from "services/profile";

import { Flex } from "components/atoms";
import TeamsComponent from "../../components/pages/teams";
import CreateTeams1 from "../../components/pages/teams/createTeamsOne";
import GiveYourTeamAvatar from "../../components/pages/teams/giveYourTeamAvatar";
import AddPlayerToTeam from "../../components/pages/teams/addplayerToTeam";
import Header from "containers/header/index";
import { headerVersions } from "../header/constants";
import { texts } from "../../Text/index";
import { toast } from "react-toastify";
import { flowSteps as createTeamSteps, teamUserStatus } from "../constants";

const Teams = (props) => {
  const { myTeams, allTeams, onGetTeams, onGetAllTeams, onJoinTeam, teamId } =
    props;
  const dispatch = useDispatch();
  const history = useHistory();
  //Based on this integer we display one of the teams pages.
  const [createTeamsStep, setCreateTeamsStep] = useState(0);
  const [playingInThisTeam, setPlayingInThisTeam] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamMemberIds, setTeamMemberIds] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [playersNotInTeam, setPlayersNotInTeam] = useState([]);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [playerType, setPlayerType] = useState("");
  const [teams, setTeams] = useState({});
  const [teamSearchTerm, setTeamSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState("myteams");
  const [initialPlayers, setInitialPlayers] = useState([]);

  const resetImg = () => {
    setFile(null);
    setImagePreview(null);
  };

  const uploadTeamAvatar = (teamId) => {
    const { onUploadTeamAvatar } = props;
    const data = {
      file,
      teamId,
    };
    onUploadTeamAvatar(data).then((response) => {
      if (response?.success === true) {
        setCreateTeamsStep(createTeamSteps.step0);
        toast.success(texts.newTeamCreated);
      } else {
        setCreateTeamsStep(createTeamSteps.step0);
        toast.error(texts.uploadAvatarFailed);
      }
      resetImg();
    });
  };

  const createTeam = (teamData) => {
    const { onCreateTeam } = props;
    return onCreateTeam(teamData).then((response) => {
      if (response?.success === true) {
        if (file) {
          uploadTeamAvatar(response.data.id);
        } else {
          setCreateTeamsStep(createTeamSteps.step0);
          toast.success(texts.newTeamCreated);
        }
      } else {
        toast.error(texts.createTeamFailed);
      }
    });
  };

  const submit = () => {
    let teamData = {
      name: teamName,
      userIds: teamMemberIds,
    };
    createTeam(teamData);
  };

  const setCreateTeamsStepWithValidation = (step) => {
    if (teamName) {
      setCreateTeamsStep(step);
      const playerId = props.profile?.id || "";
      playingInThisTeam
        ? teamMembers.push(playerId)
        : teamMembers.filter((memberId) => memberId != playerId);
    } else {
      setError(texts.teamNameIsRequired);
    }
  };

  const addOrAcceptTeamMember = (member) => {
    const memberId = member?.id;
    var existingTeamMember = teamMembers.filter((teamMember) => teamMember.userDetails.id == member.id)[0];
    var updatedMemberIds = teamMemberIds;
    if (!existingTeamMember){
      updatedMemberIds = [...teamMemberIds, memberId];
      setTeamMemberIds(updatedMemberIds);
      setTeamMembers((teamMembers) => [...teamMembers, {status: teamUserStatus.joined, isAdmin: false, userDetails: member}]);
      setInitialPlayers([member, ...initialPlayers]);
      setPlayersNotInTeam(
        props.suggestedUsers.filter((suggstedUser) => !updatedMemberIds?.includes(suggstedUser.user.id))
      );
    }
    else{
      existingTeamMember.status = teamUserStatus.joined;
      setTeamMembers(teamMembers);
      setTeamMemberIds(updatedMemberIds);
      setInitialPlayers(teamMembers.map((teamMember) => teamMember.userDetails));
    }
  }

  const removeTeamMember = (member) => {
    const memberId = member?.id;
    const reducedMemberIds = teamMemberIds.filter((id) => id !== memberId);
    setTeamMemberIds(reducedMemberIds);
    setInitialPlayers(
      initialPlayers.filter((player) => player.id != memberId)
    );
    toast.success(texts.playerSucessfullyRemovedFromTeam);

    updateTeam({
      id: teamId,
      name: teamName,
      userIds: reducedMemberIds,
    });
  }

  useEffect(() => {
    setPlayersNotInTeam(
      props.suggestedUsers.filter((suggstedUser) => !teamMemberIds?.includes(suggstedUser.user.id))
    );
  }, [props?.suggestedUsers]);

  const backToTeamPage = () => {
    setCreateTeamsStep(createTeamSteps.step0);
  };

  const searchOnChange = (value) => {
    dispatch(friendListApi({ name: value || "", type: playerType }, true));
    setSearchTerm(value);
  };

  const searchPlayerType = (type) => {
    dispatch(friendListApi({ name: searchTerm || "", type }, true));
    setPlayerType(type);
  };

  const onTeamSearch = (value) => {
    setTeamSearchTerm(value);
    onGetAllTeams({ name: value }, true).then((response) => {
      setTeams(response.data);
    });
  };

  const typeOnChange = (value) => {
    if (value == "myteams"){
      setTeams(myTeams);
    }
    else if (value == "allteams"){
      setTeams(allTeams);
    }
    setSelectedType(value);
  };

  useEffect(() => {
    props.onGetTeams();
    if (playersNotInTeam.length === 0) {
      searchOnChange();
    }
  }, []);

  useEffect(() => {
    setPlayersNotInTeam(props?.suggestedUsers);
  }, [props?.suggestedUsers]);

  useEffect(() => {
    if (createTeamsStep === createTeamSteps.step0) {
      setTeamName("");
      setError("");
      setTeamMembers([]);
    }
    if (createTeamsStep === createTeamSteps.step2) {
      setError("");
    }
  }, [createTeamsStep]);

  const goToTeamDetails = (id) => {
    if (!id) {
      return;
    }
    history.push(`/group/${id}`);
  };

  const joinTeam = (teamId) => {
    const data = {
      teamId: teamId,
      userId: props.profile?.id,
    };
    onJoinTeam(data).then((response) => {
      if (response.success) {
        dispatch(getTeamById({ teamId }));
        dispatch(onGetTeams());
        toast.success(texts.requestToJoinTeamSuccess);
      } else {
        toast.error(texts.jointRequestFailed);
      }
    });
  };

  useEffect(() => {
    if (selectedType == "myteams" && (!teamSearchTerm || teamSearchTerm === "")) {
      setTeams(myTeams);
    }
  }, [myTeams]);

  useEffect(() => {
    if (selectedType == "allteams") {
      setTeams(allTeams);
    }
  }, [allTeams]);

  useEffect(() => {
    onGetAllTeams();
    onGetTeams().then((response) => { 
      setTeams(response.data);
    });
  }, []);

  useEffect(() => {
    if (error && error !== "") toast.error(error);
  }, [error]);

  return (
    <Flex className={[]}>
      <Header
        headerText={texts.myTeams}
        headerVersion={headerVersions.version5}
        isSearchPlayerType={true}
        placeholder={texts.searchTeams}
        onSearch={onTeamSearch}
        onTypeChange={typeOnChange}
      /> 
      {createTeamsStep === createTeamSteps.step0 && (
        <Flex className={["tw-h-full tw-bg-white md:tw-w-mmd md:tw-m-auto"]}>
        <TeamsComponent
          setCreateTeamsStep={() => setCreateTeamsStep(createTeamSteps.step1)}
          teams={teams}
          userId={props.profile?.id || ""}
          goToTeamDetails={goToTeamDetails}
          onJoinTeam={joinTeam}
        ></TeamsComponent>
        </Flex>
      )}
      {createTeamsStep === createTeamSteps.step1 && (
        <CreateTeams1
          backToTeamPage={backToTeamPage}
          setCreateTeamsStepWithValidation={setCreateTeamsStepWithValidation}
          setPlayingInThisTeam={setPlayingInThisTeam}
          teamName={teamName}
          setTeamName={setTeamName}
          error={error}
        ></CreateTeams1> 
      )}
      {createTeamsStep === createTeamSteps.step2 && (
        <GiveYourTeamAvatar
          backToTeamPage={backToTeamPage}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          setCreateTeamsStep={setCreateTeamsStep}
          setFile={setFile}
          setError={setError}
        ></GiveYourTeamAvatar>
      )}
      {createTeamsStep === createTeamSteps.step3 && (
        <AddPlayerToTeam
          backToTeamPage={backToTeamPage}
          setCreateTeamsStep={setCreateTeamsStep}
          players={playersNotInTeam}
          teamMembers={teamMembers}
          addOrAcceptTeamMember={addOrAcceptTeamMember}
          removeTeamMember={removeTeamMember}
          submit={submit}
          fnBack={() => setCreateTeamsStep(createTeamSteps.step2)}
          onSearchChange={searchOnChange}
          onTypeChange={searchPlayerType}
        ></AddPlayerToTeam>
      )}
      </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    suggestedUsers: state.UserReducer?.usersList,
    myTeams: state.MyTeamsReducer,
    allTeams: state.AllTeamsReducer,
    profile: state.MyProfileReducer.profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateTeam: (data) => dispatch(createTeam(data)),
  onGetTeams: (data) => dispatch(getTeams(data)),
  onGetAllTeams: (data) => dispatch(getAllTeams(data)),
  onUploadPictureApi: (data) => dispatch(uploadPictureApi(data)),
  onUploadTeamAvatar: (data) => dispatch(uploadTeamAvatar(data)),
  onJoinTeam: (data) => dispatch(requestToJoinTeam(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Teams);

import React from "react";
import { Flex, Img } from "components/atoms";
import { propertyWidth } from "components/atoms/widthAndHeight";

const PlayerTypeIcons = (props) => {
  const { icon, justify = "center", width = "xs" } = props;
  return (
    <Flex direction="row" className={[`tw-bg-transparent tw-items-${justify} tw-justify-center`]}>
      {(icon === "GoalKeeper" || icon === "Both") && (
        <Img
          src={"/img/GoalerIcon.png"}
          alt={"Goalkeeper"}
          className={[`${propertyWidth[width]} tw-mx-1`]}
        ></Img>)}
      {(icon === "Player" || icon === "Both") && (
        <Img
          src={"/img/footballer.png"}
          alt={"Player"}
          className={[`${propertyWidth[width]} tw-mx-1`]}
        ></Img>)}
    </Flex>
  );
};

export default PlayerTypeIcons;

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { userEventStatus } from "../constants";
import { eventDetailById } from "services/events";
import {
  eventRequestLeave,
  eventInviteTeam,
  eventUserSides,
  eventJoin,
} from "services/eventUser";
import { Flex } from "components/atoms";
import EventTeamsPage from "../../components/pages/eventTeams";
import { myProfileApi } from "services/profile";
import { teamSides } from "../constants";
import { texts } from "../../Text/index";

const EventTeams = (props) => {
  const { myProfile, eventDetailById, fetchProfile, onEventUserSides } = props;
  let event = useSelector((state) => {
    return state?.AuthEventsReducer?.event?.eventResponse || null;
  });
  let { eventId } = useParams();
  const myProfileData = myProfile;
  const [players, setPlayers] = useState([]);
  const [maxNumberOfPlayers, setMaxNumberOfPlayers] = useState([]);
  const [isOrganiser, setIsOrganiser] = useState(false);
  const [organiserName, setOrganiserName] = useState(false);

  const setTeams = (user, idx) => {
    if (idx % 2 === 0) {
      user.side = teamSides.sideOne;
    } else {
      user.side = teamSides.sideTwo;
    }
  };

  useEffect(() => {
    if (!event) {
      eventDetailById(eventId);
    }
    if (!myProfileData) {
      fetchProfile();
    }
    if (event) {
      setMaxNumberOfPlayers(event.maxPlayers);

      event?.users?.forEach((user, idx) => {
        if (user?.id === myProfileData?.id && user?.isOrganizer) {
          setIsOrganiser(true);
        }
        if (!user?.side) {
          setTeams(user, idx);
        }
      });
      setPlayers(
        event?.users?.filter((user) => user.status === userEventStatus.JOINED)
      );
    }
    const organiser = event?.users?.find((user) => user.isOrganizer === true);
    setOrganiserName(`${organiser?.firstName} ${organiser?.lastName}`);
  }, [event, myProfileData]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return [...array];
  };

  const shufflePlayers = () => {
    setPlayers((players) => shuffleArray(players));
    if (players && players.length > 0) {
      players.forEach((user, idx) => {
        setTeams(user, idx);
      });
    }
  };

  const saveTeam = () => {
    if (players && players.length > 0) {
      let teamData = {};

      const eventUsersSides = players.map((player) => {
        return {
          userId: player.id,
          side: player.side,
        };
      });
      teamData.eventId = event.id;
      teamData.sides = { eventUsersSides };
      onEventUserSides(teamData).then((response) => {
        if (response.success) {
          toast.success(texts.teamSavedSuccessfully);
        } else {
          toast.error(texts.genericError);
        }
      });
    }
  };

  const orderbyNames = (playerA, playerB) => {
    if (playerA.firstName < playerB.firstName) {
      return -1;
    }
    if (playerA.firstName > playerB.firstName) {
      return 1;
    }
    // names must be equal
    return 0;
  };

  const onDoubleClick = (player) => {
    if (player.side === teamSides.sideOne) {
      player.side = teamSides.sideTwo;
    } else {
      player.side = teamSides.sideOne;
    }
    setPlayers([player, ...players.filter(user => user.id != player.id)]);
    saveTeam();
  }

  return (
    <Flex
      className={["tw-h-full tw-w-full tw-bg-white md:tw-w-mmd md:tw-m-auto"]}
    >
      <EventTeamsPage
        eventName={event?.name || ""}
        organiserName={organiserName}
        maxNumberOfPlayers={maxNumberOfPlayers}
        isOrganiser={isOrganiser}
        teamOne={players
          .filter((player) => player.side === teamSides.sideOne)
          .sort(orderbyNames)}
        teamTwo={players
          .filter((player) => player.side === teamSides.sideTwo)
          .sort(orderbyNames)}
        saveTeam={saveTeam}
        shufflePlayers={shufflePlayers}
        onDoubleClick={onDoubleClick}
      />
    </Flex>
  );
};

const mapStateToProps = (state) => {
  return {
    myProfile: state.MyProfileReducer.profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  eventDetailById: (data) => dispatch(eventDetailById(data)),
  eventJoin: (data) => dispatch(eventJoin(data)),
  fetchProfile: () => dispatch(myProfileApi()),
  onEventRequestLeave: (data) => dispatch(eventRequestLeave(data)),
  eventTeamJoin: (data) => dispatch(eventInviteTeam(data)),
  onEventUserSides: (data) => dispatch(eventUserSides(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventTeams);

import React from "react";
import { Link, Flex, Paragraph, Button} from "../../atoms";
import { texts } from "../../../Text/index";

function HeaderMenuButtons(props) {
  const { fnLogInOut, fnSignupSettings, isLoggedIn } = props;
  const LogInOutBtnText = isLoggedIn ? texts.logout : texts.account;
  const SignupSettingsBtnText = isLoggedIn ? texts.settings : texts.signUp;

  return (
    <Flex>
      <Flex
        direction="row"
        className={["tw-bg-black tw-items-center tw-justify-center"]}
      >
        <Button
          secondary={isLoggedIn}
          className={["tw-mx-2 tw-my-4 tw-px-6 tw-py-3 tw-text-sm"]}
          size="sm"
          onClick={fnLogInOut}
        >
          {LogInOutBtnText}
        </Button>
        <Button
          size="sm"
          className={["tw-mx-2 tw-my-4 tw-px-6 tw-py-3 tw-text-sm tw-border-2 tw-border-white"]}
          onClick={fnSignupSettings}
          quinternary={true}
        >
          {SignupSettingsBtnText}
        </Button>
      </Flex>

      <Flex className={["tw-mt-3 tw-items-center"]} >
        <Paragraph size={"xs"} > 
          {texts.linkNeedSupport}
          <Link to="mailto:info@leet.mt" className={["tw-text-green"]} external>
            &nbsp;{texts.linkContactLeet}
          </Link>
        </Paragraph>
      </Flex>
    </Flex>
  );
}

export default HeaderMenuButtons;

import * as Action from "./actions";
import NewStoreState from "../newStoreState";

export function MyTeamsReducer(state = NewStoreState.teams.myTeams, action) {
  switch (action?.type) {
    case Action.GET_MY_TEAMS:
      return [...action?.payload];

    case Action.UPDATE_TEAM:
      state = state.map((team) => {
        return team.id === action?.payload?.id ? action?.payload : team;
      });
      return [...state];

    case Action.ADD_NEW_TEAM:
      const data = action?.payload;
      return [data, ...state];

    case Action.DELETE_TEAM:
      state = state.filter((team) => team?.id !== action?.payload);
      return [...state];

    case Action.ADD_TEAM_AVATAR:
      const teamToUpdate = state.find((team) => {
        return team.id === action?.payload?.id;
      });
      teamToUpdate.imageUrl = action?.payload?.imageUrl;
      state = [
        teamToUpdate,
        ...state.filter((team) => team?.id !== action?.payload?.id),
      ];
      return [...state];

    default:
      return [...state];
  }
}

export function AllTeamsReducer(state = NewStoreState.teams.allTeams, action) {
  switch (action?.type) {
    case Action.GET_ALL_TEAMS:
      return [...action?.payload];

    case Action.ADD_NEW_TEAM_BY_ID:
      const data = action?.payload;
      return [data, ...state?.filter((team) => team.id !== data?.id)];

    default:
      return [...state];
  }
}
